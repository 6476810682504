import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import ProductView          from "./ProductView";
import ProductEdit          from "./ProductEdit";
import Main                 from "dashboard/dist/Components/Main";
import Content              from "dashboard/dist/Components/Content";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";

// Actions
import {
    fetchProducts, deleteProduct,
} from "Actions/App/Product/ProductActions";



/**
 * The Product List
 */
class ProductList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=}  params
     * @param {Boolean=} withLoader
     * @returns {Void}
     */
    fetch = (params, withLoader) => {
        const { type, elemID, data } = this.props;
        this.props.fetchProducts(type, elemID, params || data.sort, withLoader);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        this.setState({ action, elemID });
    }

    /**
     * Ends an Action
     * @param {Boolean=} update
     * @returns {Void}
     */
    endAction = (update) => {
        this.startAction(Action.get(), 0);
        if (update) {
            this.fetch(null, false);
        }
    }



    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch(null, false);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const elemID = this.state.elemID;
        this.endAction();
        await this.props.deleteProduct(elemID);
        this.fetch(null, false);
    }


    
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                      } = this.state;
        const { data, params, route, withDetails    } = this.props;
        const { canEdit, list, total, sort, loading } = data;

        const elemName = Utils.getValue(list, "productID", elemID, "name");

        return <Main withDetails={withDetails}>
            <Header message="PRODUCTS_NAME" icon="product" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content>
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="PRODUCTS_NONE_AVAILABLE"
                    isLoading={loading}
                >
                    <TableHead>
                        <TableHeader field="name"          message="PRODUCTS_SINGULAR"      grow="2" />
                        <TableHeader field="categoryID"    message="CATEGORIES_SINGULAR"    />
                        <TableHeader field="subcategoryID" message="SUBCATEGORIES_SINGULAR" />
                        <TableHeader field="price"         message="GENERAL_PRICE"          maxWidth="100" align="right"  />
                        <TableHeader field="position"      message="GENERAL_POSITION"       maxWidth="100" align="center" />
                        <TableHeader field="createdTime"   message="GENERAL_CREATED"        maxWidth="100" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.productID} elemID={elem.productID}>
                            <TableCell message={elem.name}            circle={elem.statusColor} />
                            <TableCell message={elem.categoryName}    />
                            <TableCell message={elem.subcategoryName} />
                            <TableCell message={elem.priceText}       />
                            <TableCell message={elem.position}        />
                            <TableCell message={elem.createdDate}     />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"   message="PRODUCTS_VIEW_TITLE"   isHidden={canEdit} />
                        <TableAction action="EDIT"   message="PRODUCTS_EDIT_TITLE"   />
                        <TableAction action="DELETE" message="PRODUCTS_DELETE_TITLE" />
                    </TableActionList>
                </Table>
            </Content>

            <ProductView
                open={!canEdit && action.isView}
                elemID={elemID}
                onClose={this.endAction}
            />
            <ProductEdit
                open={canEdit && action.isVCE}
                elemID={elemID}
                clientID={params.clientID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="PRODUCTS_DELETE_TITLE"
                message="PRODUCTS_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchProducts : PropTypes.func.isRequired,
        deleteProduct : PropTypes.func.isRequired,
        data          : PropTypes.object.isRequired,
        type          : PropTypes.string.isRequired,
        params        : PropTypes.object.isRequired,
        route         : PropTypes.string.isRequired,
        withDetails   : PropTypes.bool.isRequired,
        elemID        : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.product,
        };
    }
}

export default connect(ProductList.mapStateToProps, {
    fetchProducts, deleteProduct,
})(ProductList);
