import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Table }            from "Utils/API";

// Types
import {
    TABLE, TABLE_LOADING,
    TABLE_LIST, TABLE_ELEM, TABLE_EDIT,
} from "Utils/Types";



/**
 * Fetches the Table List
 * @param {String}   type
 * @param {Number}   elemID
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTables(type, elemID, params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : TABLE_LOADING });
        }
        Params.unset(params);
        let data = {};

        switch (type) {
        case "CLIENT":
            params.clientID = elemID;
            data = await Table.getAllForClient(params);
            break;
        case "MANAGER":
            data = await Table.getAllForManager(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : TABLE_LIST, data });
        dispatch(setSection(TABLE));
    };
}

/**
 * Fetches a single Table
 * @param {Number} tableID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTable(tableID) {
    return async (dispatch) => {
        const data = await Table.getOne({ tableID });
        dispatch({ type : TABLE_ELEM, data });
    };
}

/**
 * Fetches the Table Edit data
 * @param {Number} clientID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTableEdit(clientID) {
    return async (dispatch) => {
        const data = await Table.getEditData({ clientID });
        dispatch({ type : TABLE_EDIT, data });
    };
}



/**
 * Edits/Creates a Table
 * @param {Object} data
 * @returns {() => Void}
 */
export function editTable(data) {
    return () => Table.edit(data);
}

/**
 * Deletes a Table
 * @param {Number} tableID
 * @returns {() => Void}
 */
export function deleteTable(tableID) {
    return () => Table.delete({ tableID });
}

/**
 * Generate Tables
 * @param {Object} data
 * @returns {() => Void}
 */
export function generateTables(data) {
    return () => Table.generate(data);
}
