import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchClient }      from "Actions/App/Client/ClientActions";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Content              from "dashboard/dist/Components/Content";
import Header               from "dashboard/dist/Components/Header";
import Columns              from "dashboard/dist/Components/Columns";
import ViewField            from "dashboard/dist/Components/ViewField";



/**
 * The Client View
 */
class ClientView extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchClient(this.props.clientID);
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.clientID !== prevProps.clientID) {
            this.props.fetchClient(this.props.clientID);
        }
    }


    
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, route } = this.props;
        
        return <Main>
            <Header message="GENERAL_INFO" icon="client" route={route} />
            <Content className="client-view">
                <p>{NLS.get("CLIENTS_INFO")}</p>
                <Columns>
                    <ViewField label="CLIENTS_ITS_NAME"      value={elem.name}         />
                    <ViewField label="CLIENTS_SOCIAL_REASON" value={elem.socialReason} />
                    <ViewField
                        label="GENERAL_IVA_CATEGORY"
                        value={elem.ivaCategoryName}
                    />
                    <ViewField
                        isHidden={!elem.requiresCUIT}
                        label="CLIENTS_CUIT"
                        value={elem.cuit}
                    />
                    <ViewField
                        isHidden={elem.requiresCUIT}
                        label="CLIENTS_DNI"
                        value={elem.dni}
                    />
                    <ViewField
                        label="CLIENTS_FIRST_NAME"
                        value={elem.firstName}
                    />
                    <ViewField
                        label="CLIENTS_LAST_NAME"
                        value={elem.lastName}
                    />
                    <ViewField
                        label="CLIENTS_EMAIL"
                        value={elem.email}
                    />
                    <ViewField
                        label="CLIENTS_PHONE"
                        value={elem.phone}
                    />
                    <ViewField
                        label="CLIENTS_ADDRESS"
                        value={elem.address}
                    />
                    <ViewField
                        label="CLIENTS_LOCALITY"
                        value={elem.locality}
                    />
                    <ViewField
                        label="CLIENTS_PROVINCE"
                        value={elem.provinceName}
                    />
                    <ViewField
                        label="CLIENTS_ZIP_CODE"
                        value={elem.zipCode}
                    />
                </Columns>
            </Content>
        </Main>;
    }

    

    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchClient : PropTypes.func.isRequired,
        clientID    : PropTypes.number.isRequired,
        elem        : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            clientID : state.auth.credential.clientID,
            elem     : state.client.elem,
        };
    }
}

export default connect(ClientView.mapStateToProps, {
    fetchClient,
})(ClientView);
