import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchPayments }    from "Actions/App/Client/PaymentActions";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Content              from "dashboard/dist/Components/Content";
import Header               from "dashboard/dist/Components/Header";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";



/**
 * The Payment List
 */
class PaymentList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { type, elemID, data } = this.props;
        this.props.fetchPayments(type, elemID, params || data.sort);
    }


    
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, route, withDetails   } = this.props;
        const { list, total, sort, loading } = data;

        return <Main withDetails={withDetails}>
            <Header message="PAYMENTS_NAME" icon="payment" route={route} />
            <Content>
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="PAYMENTS_NONE_AVAILABLE"
                    isLoading={loading}
                >
                    <TableHead>
                        <TableHeader field="time"        message="PAYMENTS_TIME"   maxWidth="100" />
                        <TableHeader field="price"       message="GENERAL_PRICE"   maxWidth="100" align="left" />
                        <TableHeader field="planID"      message="PLANS_SINGULAR"  />
                        <TableHeader field="createdUser" message="GENERAL_CREATOR" />
                        <TableHeader field="createdTime" message="GENERAL_CREATED" maxWidth="100" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.paymentID} elemID={elem.paymentID}>
                            <TableCell message={elem.timeDate}       />
                            <TableCell message={elem.amountText}     />
                            <TableCell message={elem.planName}       />
                            <TableCell message={elem.credentialName} />
                            <TableCell message={elem.createdDate}    />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                </Table>
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchPayments : PropTypes.func.isRequired,
        data          : PropTypes.object.isRequired,
        type          : PropTypes.string.isRequired,
        route         : PropTypes.string.isRequired,
        withDetails   : PropTypes.bool.isRequired,
        elemID        : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.payment,
        };
    }
}

export default connect(PaymentList.mapStateToProps, {
    fetchPayments,
})(PaymentList);
