import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { logout }           from "Actions/Core/AuthActions";
import { Search }           from "Utils/API";

// Routers
import AdminRouter          from "Components/Core/Admin/AdminRouter";
import SupportRouter        from "Components/Core/Support/SupportRouter";
import ManagerRouter        from "Components/Core/Manager/ManagerRouter";
import AssistantRouter      from "Components/Core/Assistant/AssistantRouter";

// Components
import NewDrawer            from "Components/Core/NewDrawer";
import Container            from "dashboard/dist/Components/Container";
import TopBar               from "dashboard/dist/Components/TopBar";
import SideBar              from "dashboard/dist/Components/SideBar";
import SearchDrawer         from "dashboard/dist/Components/SearchDrawer";

// Images
import Logo                 from "Styles/Images/Icon.png";
import LogoWhite            from "Styles/Images/IconWhite.png";

// Actions
import {
    openMenu, openDetails, closeMenu, closeDetails,
} from "Actions/Core/CoreActions";



/**
 * The App
 */
class App extends React.Component {
    // The Current State
    state = {
        showSearch : false,
        showCreate : false,
    }

    /**
     * Opens the Search Drawer
     * @returns {Void}
     */
    openSearch = () => {
        this.setState({ showSearch : true });
    }

    /**
     * Closes the Search Drawer
     * @returns {Void}
     */
    closeSearch = () => {
        this.setState({ showSearch : false });
    }

    /**
     * Opens the Create Drawer
     * @returns {Void}
     */
    openCreate = () => {
        this.setState({ showCreate : true });
    }

    /**
     * Closes the Create Drawer
     * @returns {Void}
     */
    closeCreate = () => {
        this.setState({ showCreate : false });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const {
            isAdministrator, isAdmin, credential, logout,
            showMenu, openMenu, closeMenu,
            hasDetails, showDetails, openDetails, closeDetails,
        } = this.props;

        const { showSearch, showCreate } = this.state;
        
        return <Container
            showMenu={showMenu}
            showDetails={showDetails}
            closeMenu={closeMenu}
            closeDetails={closeDetails}
        >
            <TopBar
                logo={LogoWhite}
                logoHeight={44}
                onMenu={openMenu}
                onDetails={openDetails}
                showDetails={hasDetails}
                avatarUrl="PROFILE"
                avatarData={credential}
            />
            <SideBar
                logo={LogoWhite}
                logoHeight={44}
                onSearch={this.openSearch}
                onCreate={this.openCreate}
                onClose={closeMenu}
                onLogout={logout}
                avatarUrl="PROFILE"
                avatarData={credential}
                hasSearch={isAdministrator}
                hasCreate={isAdmin}
            />
            
            <AdminRouter     />
            <SupportRouter   />
            <ManagerRouter   />
            <AssistantRouter />

            <NewDrawer
                isHidden={!isAdmin}
                open={showCreate}
                onClose={this.closeCreate}
            />
            <SearchDrawer
                isHidden={!isAdministrator}
                open={showSearch}
                logo={Logo}
                logoHeight={44}
                fetch={Search.search}
                onClose={this.closeSearch}
            />
        </Container>;
    }
    
    

    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        logout          : PropTypes.func.isRequired,
        openMenu        : PropTypes.func.isRequired,
        openDetails     : PropTypes.func.isRequired,
        closeMenu       : PropTypes.func.isRequired,
        closeDetails    : PropTypes.func.isRequired,
        showMenu        : PropTypes.bool.isRequired,
        showDetails     : PropTypes.bool.isRequired,
        hasDetails      : PropTypes.bool.isRequired,
        isAdministrator : PropTypes.bool.isRequired,
        isAdmin         : PropTypes.bool.isRequired,
        credential      : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            showMenu        : state.core.showMenu,
            showDetails     : state.core.showDetails,
            hasDetails      : state.core.hasDetails,
            isAdministrator : state.auth.isAdministrator,
            isAdmin         : state.auth.isAdmin,
            credential      : state.auth.credential,
        };
    }
}

export default connect(App.mapStateToProps, {
    logout, openMenu, openDetails, closeMenu, closeDetails,
})(App);
