import React                from "react";
import PropTypes            from "prop-types";

// Components
import Avatar               from "dashboard/dist/Components/Avatar";
import Html                 from "dashboard/dist/Components/Html";
import IconLink             from "dashboard/dist/Components/IconLink";
import Menu                 from "dashboard/dist/Components/Menu";
import MenuItem             from "dashboard/dist/Components/MenuItem";



/**
 * The Ticket Message
 */
class TicketMessage extends React.Component {
    // The Current State
    state = {
        showMenu : false,
    }

    /**
     * Handles the Menu Open
     * @returns {Boolean}
     */
    openMenu = (e) => {
        this.setState({ showMenu : true });
        e.preventDefault();
        return false;
    }

    /**
     * Handles the Menu Close
     * @returns {Void}
     */
    closeMenu = () => {
        this.setState({ showMenu : false });
    }

    /**
     * Handles the Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        const { elem, startAction } = this.props;
        startAction(action, elem);
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem     } = this.props;
        const { showMenu } = this.state;
        
        return <section className="ticket-message">
            <Avatar
                className="ticket-avatar"
                name={elem.credentialName}
                data={elem}
            />
            <div>
                <header className="ticket-header">
                    <h3>{elem.credentialName}</h3>
                    <h4>{elem.createdString}</h4>
                </header>
                <Html linkify>{elem.messageHtml}</Html>
            </div>
            
            {elem.canEdit && <>
                <IconLink
                    className="ticket-more"
                    variant="light"
                    icon="expand"
                    onClick={this.openMenu}
                    dontStop
                />
                <Menu
                    className="ticket-menu"
                    open={showMenu}
                    direction="left"
                    onAction={this.startAction}
                    onClose={this.closeMenu}
                >
                    <MenuItem action="EDIT"   message="TICKETS_EDIT_MESSAGE"   />
                    <MenuItem action="DELETE" message="TICKETS_DELETE_MESSAGE" />
                </Menu>
            </>}
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        startAction : PropTypes.func.isRequired,
        elem        : PropTypes.object.isRequired,
    }
}

export default TicketMessage;
