import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { requestReset }     from "Actions/Core/AuthActions";
import Href                 from "dashboard/dist/Core/Href";

// Components
import AuthForm             from "Components/Auth/AuthForm";
import IconField            from "dashboard/dist/Components/IconField";



/**
 * The Recover Pass Page Component
 */
class RecoverPassPage extends React.Component {
    // The Current State
    state = {
        data    : { email : "" },
        loading : false,
        errors  : {},
    }
    
    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }
    
    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.requestReset(data);
                Href.goto("PASS_CODE");
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }

    

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, loading, errors } = this.state;

        return <AuthForm
            onSubmit={this.handleSubmit}
            title="RECOVER_TITLE"
            help="RECOVER_HELP"
            error={errors.form}
            url="LOGIN"
            link="RECOVER_ACCOUNT"
            button="RECOVER_BUTTON"
            isLoading={loading}
        >
            <IconField
                className="auth-field"
                type="email"
                name="email"
                icon="email"
                placeholder="GENERAL_EMAIL"
                autoComplete="username"
                value={data.email}
                error={errors.email}
                onChange={this.handleChange}
                isRequired
            />
        </AuthForm>;
    }

    

    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        requestReset : PropTypes.func.isRequired,
    }
}

export default connect(null, {
    requestReset,
})(RecoverPassPage);
