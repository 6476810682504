import Access               from "dashboard/dist/Core/Access";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    AUTH_CURRENT_USER, AUTH_CHANGE_EMAIL,
} from "Utils/Types";



// The initial State
const initialState = {
    isAuthenticated : false,
    isAdministrator : false,
    isUser          : false,
    isOwner         : false,
    isAdmin         : false,
    isSupport       : false,
    isManager       : false,
    isAssistant     : false,
    loggedAsUser    : false,
    credential      : {},
    email           : "",
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    switch (action.type) {
    case AUTH_CURRENT_USER:
        if (Utils.isEmpty(action.credential)) {
            return initialState;
        }
        const level = action.credential.accessLevel;
        return {
            ...initialState,
            isAuthenticated : true,
            loggedAsUser    : action.credential.loggedAsUser,
            isAdministrator : Access.inGroup("ADMIN", level),
            isUser          : Access.inGroup("USER",  level),
            isAdmin         : Access.inGroup("OWNER", level),
            isOwner         : Access.is("OWNER",      level),
            isSupport       : Access.is("SUPPORT",    level),
            isManager       : Access.is("MANAGER",    level),
            isAssistant     : Access.is("ASSISTANT",  level),
            credential      : action.credential,
        };
    
    case AUTH_CHANGE_EMAIL:
        return {
            ...state,
            isAuthenticated : false,
            credential      : {},
            email           : action.email,
        };
    
    default:
        return state;
    }
};
