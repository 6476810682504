import React                from "react";
import PropTypes            from "prop-types";

// Sections
import LoginPage            from "Components/Auth/LoginPage";
import RecoverPassPage      from "Components/Auth/RecoverPassPage";
import CodePage             from "Components/Auth/CodePage";
import ResetPassPage        from "Components/Auth/ResetPassPage";
import ChangePassPage       from "Components/Auth/ChangePassPage";

// Components
import GuestRoute           from "Components/Utils/Route/GuestRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";


// Styles
import "Styles/Images/Wallpaper.jpg";
import "Styles/Components/Core/Auth.css";



/**
 * The Auth Page
 */
class Auth extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        return <div className="auth">
            <SwitchRoute>
                <GuestRoute url="LOGIN"        component={LoginPage}       exact />
                <GuestRoute url="PASS_RECOVER" component={RecoverPassPage} exact />
                <GuestRoute url="PASS_CODE"    component={CodePage}        exact />
                <GuestRoute url="PASS_RESET"   component={ResetPassPage}   exact />
                <GuestRoute url="PASS_CHANGE"  component={ChangePassPage}  exact />
            </SwitchRoute>
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        location : PropTypes.object.isRequired,
    }
}

export default Auth;
