import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Product }          from "Utils/API";

// Types
import {
    PRODUCT, PRODUCT_LOADING,
    PRODUCT_LIST, PRODUCT_ELEM, PRODUCT_EDIT,
} from "Utils/Types";



/**
 * Fetches the Product List
 * @param {String}   type
 * @param {Number}   elemID
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchProducts(type, elemID, params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : PRODUCT_LOADING });
        }
        Params.unset(params);
        let data = {};

        switch (type) {
        case "CLIENT":
            params.clientID = elemID;
            data = await Product.getAllForClient(params);
            break;
        case "MANAGER":
            data = await Product.getAllForManager(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : PRODUCT_LIST, data });
        dispatch(setSection(PRODUCT));
    };
}

/**
 * Fetches a single Product
 * @param {Number} productID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchProduct(productID) {
    return async (dispatch) => {
        const data = await Product.getOne({ productID });
        dispatch({ type : PRODUCT_ELEM, data });
    };
}

/**
 * Fetches the Product Edit data
 * @param {Number} clientID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchProductEdit(clientID) {
    return async (dispatch) => {
        const data = await Product.getEditData({ clientID });
        dispatch({ type : PRODUCT_EDIT, data });
    };
}



/**
 * Edits/Creates a Product
 * @param {Object} params
 * @returns {() => Void}
 */
export function editProduct(params) {
    return () => Product.edit(params);
}

/**
 * Partially edits a Product
 * @param {Object} params
 * @returns {() => Void}
 */
export function editPartialProduct(params) {
    return () => Product.editPartial(params);
}

/**
 * Deletes a Product
 * @param {Number} productID
 * @returns {() => Void}
 */
export function deleteProduct(productID) {
    return () => Product.delete({ productID });
}
