import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Sections
import ProfileContainer     from "Components/App/Profile/ProfileContainer";
import DashboardPage        from "Components/App/Client/Dashboard/DashboardPage";

// Components
import AssistantRoute       from "Components/Utils/Route/AssistantRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";



/**
 * The Assistant Router
 */
class AssistantRouter extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        if (!this.props.isAssistant) {
            return <React.Fragment />;
        }
        return <SwitchRoute>
            <AssistantRoute url="PROFILE" component={ProfileContainer} />
            <AssistantRoute url="NONE"    component={DashboardPage}    />
        </SwitchRoute>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isAssistant : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAssistant : state.auth.isAssistant,
        };
    }
}

export default connect(AssistantRouter.mapStateToProps)(AssistantRouter);
