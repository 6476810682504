import Ajax                         from "dashboard/dist/Core/Ajax";



// Auth Actions
export const Auth = {
    "login"             : (data) => Ajax.post("/session/login",                      data),
    "requestReset"      : (data) => Ajax.post("/session/requestReset",               data),
    "verifyReset"       : (data) => Ajax.post("/session/verifyReset",                data),
    "resetPass"         : (data) => Ajax.post("/session/resetPass",                  data),
    "changePass"        : (data) => Ajax.post("/session/changePass",                 data),
    "logout"            : (data) => Ajax.post("/session/logout",                     data),
    "loginAs"           : (data) => Ajax.post("/session/loginAs",                    data),
    "logoutAs"          : (data) => Ajax.post("/session/logoutAs",                   data),
};
export const Profile = {
    "search"            : (data) => Ajax.get("/profile/search",                      data),
    "get"               : (data) => Ajax.get("/profile/get",                         data),
    "edit"              : (data) => Ajax.post("/profile/edit",                       data),
    "upload"            : (data) => Ajax.post("/profile/upload",                     data),
};



// Core actions
export const Search = {
    "search"            : (data) => Ajax.get("/search/search",                       data, false),
};
export const Media = {
    "getAll"            : (data) => Ajax.get("/media/getAll",                        data),
    "create"            : (data) => Ajax.post("/media/create",                       data),
    "upload"            : (data) => Ajax.post("/media/upload",                       data),
    "rename"            : (data) => Ajax.post("/media/rename",                       data),
    "delete"            : (data) => Ajax.post("/media/delete",                       data),
};



// Client Actions
export const Client = {
    "getAll"            : (data) => Ajax.get("/client/getAll",                       data),
    "getOne"            : (data) => Ajax.get("/client/getOne",                       data),
    "getEditData"       : (data) => Ajax.get("/client/getEditData",                  data),
    "search"            : (data) => Ajax.get("/client/search",                       data),
    "export"            : (data) => Ajax.url("/client/export",                       data),
    "edit"              : (data) => Ajax.post("/client/edit",                        data),
    "delete"            : (data) => Ajax.post("/client/delete",                      data),
    "markAsPaid"        : (data) => Ajax.post("/client/markAsPaid",                  data),
};
export const User = {
    "getAllForManager"  : (data) => Ajax.get("/client/user/getAllForManager",        data),
    "getAllForClient"   : (data) => Ajax.get("/client/user/getAllForClient",         data),
    "getOne"            : (data) => Ajax.get("/client/user/getOne",                  data),
    "edit"              : (data) => Ajax.post("/client/user/edit",                   data),
    "delete"            : (data) => Ajax.post("/client/user/delete",                 data),
};
export const Table = {
    "getAllForClient"   : (data) => Ajax.get("/client/table/getAllForClient",        data),
    "getAllForManager"  : (data) => Ajax.get("/client/table/getAllForManager",       data),
    "getOne"            : (data) => Ajax.get("/client/table/getOne",                 data),
    "getEditData"       : (data) => Ajax.get("/client/table/getEditData",            data),
    "edit"              : (data) => Ajax.post("/client/table/edit",                  data),
    "delete"            : (data) => Ajax.post("/client/table/delete",                data),
    "generate"          : (data) => Ajax.post("/client/table/generate",              data),
};
export const Code = {
    "getAllForClient"   : (data) => Ajax.get("/client/code/getAllForClient",         data),
    "getOne"            : (data) => Ajax.get("/client/code/getOne",                  data),
    "print"             : (data) => Ajax.route("/client/code/print",                 data, false),
};
export const Options = {
    "getAllForClient"   : (data) => Ajax.get("/client/options/getAllForClient",      data),
    "edit"              : (data) => Ajax.post("/client/options/edit",                data),
};
export const Payment = {
    "getAllForClient"   : (data) => Ajax.get("/client/payment/getAllForClient",      data),
};



// Product Actions
export const Product = {
    "getAllForClient"   : (data) => Ajax.get("/product/getAllForClient",             data),
    "getAllForManager"  : (data) => Ajax.get("/product/getAllForManager",            data),
    "getOne"            : (data) => Ajax.get("/product/getOne",                      data),
    "getEditData"       : (data) => Ajax.get("/product/getEditData",                 data),
    "edit"              : (data) => Ajax.post("/product/edit",                       data),
    "delete"            : (data) => Ajax.post("/product/delete",                     data),
    "editPartial"       : (data) => Ajax.post("/product/editPartial",                data),
};
export const Category = {
    "getAllForClient"   : (data) => Ajax.get("/product/category/getAllForClient",    data),
    "getOne"            : (data) => Ajax.get("/product/category/getOne",             data),
    "getEditData"       : (data) => Ajax.get("/product/category/getEditData",        data),
    "edit"              : (data) => Ajax.post("/product/category/edit",              data),
    "delete"            : (data) => Ajax.post("/product/category/delete",            data),
};
export const Subcategory = {
    "getAllForClient"   : (data) => Ajax.get("/product/subcategory/getAllForClient", data),
    "getOne"            : (data) => Ajax.get("/product/subcategory/getOne",          data),
    "getEditData"       : (data) => Ajax.get("/product/subcategory/getEditData",     data),
    "edit"              : (data) => Ajax.post("/product/subcategory/edit",           data),
    "delete"            : (data) => Ajax.post("/product/subcategory/delete",         data),
};
export const Optional = {
    "getAllForClient"   : (data) => Ajax.get("/product/optional/getAllForClient",    data),
    "getAllForManager"  : (data) => Ajax.get("/product/optional/getAllForManager",   data),
    "getOne"            : (data) => Ajax.get("/product/optional/getOne",             data),
    "getEditData"       : (data) => Ajax.get("/product/optional/getEditData",        data),
    "edit"              : (data) => Ajax.post("/product/optional/edit",              data),
    "editPartial"       : (data) => Ajax.post("/product/optional/editPartial",       data),
    "delete"            : (data) => Ajax.post("/product/optional/delete",            data),
};
export const OptionalItem = {
    "getOne"            : (data) => Ajax.get("/product/optional/item/getOne",         data),
    "edit"              : (data) => Ajax.post("/product/optional/item/edit",          data),
    "editPartial"       : (data) => Ajax.post("/product/optional/item/editPartial",   data),
    "delete"            : (data) => Ajax.post("/product/optional/item/delete",        data),
};
export const Size = {
    "getAllForClient"   : (data) => Ajax.get("/product/size/getAllForClient",         data),
    "getOne"            : (data) => Ajax.get("/product/size/getOne",                  data),
    "getEditData"       : (data) => Ajax.get("/product/size/getEditData",             data),
    "edit"              : (data) => Ajax.post("/product/size/edit",                   data),
    "delete"            : (data) => Ajax.post("/product/size/delete",                 data),
};



// Dashboard Actions
export const Dashboard = {
    "getData"           : (data) => Ajax.get("/dashboard/getData",                   data),
    "getNewData"        : (data) => Ajax.get("/dashboard/getNewData",                data, false),
    "acceptAll"         : (data) => Ajax.post("/dashboard/acceptAll",                data, false, false),
    "acceptWaiter"      : (data) => Ajax.post("/dashboard/acceptWaiter",             data, false, false),
    "acceptBill"        : (data) => Ajax.post("/dashboard/acceptBill",               data, false, false),
    "completeServing"   : (data) => Ajax.post("/dashboard/completeServing",          data, false),
    "confirmOrder"      : (data) => Ajax.post("/dashboard/confirmOrder",             data, false),
    "rejectOrder"       : (data) => Ajax.post("/dashboard/rejectOrder",              data, false),
    "completeOrder"     : (data) => Ajax.post("/dashboard/completeOrder",            data, false),
    "cancelOrder"       : (data) => Ajax.post("/dashboard/cancelOrder",              data, false),
};



// Ticket Actions
export const Ticket = {
    "getAll"            : (data) => Ajax.get("/ticket/getAll",                       data),
    "getAllForClient"   : (data) => Ajax.get("/ticket/getAllForClient",              data),
    "getAllForManager"  : (data) => Ajax.get("/ticket/getAllForManager",             data),
    "getOne"            : (data) => Ajax.get("/ticket/getOne",                       data),
    "getEditData"       : (data) => Ajax.get("/ticket/getEditData",                  data),
    "getAssignData"     : (data) => Ajax.get("/ticket/getAssignData",                data),
    "create"            : (data) => Ajax.post("/ticket/create",                      data),
    "edit"              : (data) => Ajax.post("/ticket/edit",                        data),
    "delete"            : (data) => Ajax.post("/ticket/delete",                      data),
    "assign"            : (data) => Ajax.post("/ticket/assign",                      data),
    "open"              : (data) => Ajax.post("/ticket/open",                        data),
    "close"             : (data) => Ajax.post("/ticket/close",                       data),
    "mute"              : (data) => Ajax.post("/ticket/mute",                        data),
    "unmute"            : (data) => Ajax.post("/ticket/unmute",                      data),
};
export const TicketMessage = {
    "add"               : (data) => Ajax.post("/ticket/message/add",                 data),
    "edit"              : (data) => Ajax.post("/ticket/message/edit",                data),
    "delete"            : (data) => Ajax.post("/ticket/message/delete",              data),
};
export const TicketType = {
    "getAll"            : (data) => Ajax.get("/ticket/type/getAll",                  data),
    "getOne"            : (data) => Ajax.get("/ticket/type/getOne",                  data),
    "getEditData"       : (data) => Ajax.get("/ticket/type/getEditData",             data),
    "edit"              : (data) => Ajax.post("/ticket/type/edit",                   data),
    "delete"            : (data) => Ajax.post("/ticket/type/delete",                 data),
};



// Settings Actions
export const Admin = {
    "getAll"            : (data) => Ajax.get("/admin/getAll",                        data),
    "getOne"            : (data) => Ajax.get("/admin/getOne",                        data),
    "edit"              : (data) => Ajax.post("/admin/edit",                         data),
    "delete"            : (data) => Ajax.post("/admin/delete",                       data),
};
export const Plan = {
    "getAll"            : (data) => Ajax.post("/plan/getAll",                        data),
    "getOne"            : (data) => Ajax.post("/plan/getOne",                        data),
    "getEditData"       : (data) => Ajax.post("/plan/getEditData",                   data),
    "edit"              : (data) => Ajax.post("/plan/edit",                          data),
    "delete"            : (data) => Ajax.post("/plan/delete",                        data),
};
export const EmailTemplate = {
    "getAll"            : (data) => Ajax.get("/emailTemplate/getAll",                data),
    "getOne"            : (data) => Ajax.get("/emailTemplate/getOne",                data),
    "edit"              : (data) => Ajax.post("/emailTemplate/edit",                 data),
};
export const ActionLog = {
    "filter"            : (data) => Ajax.get("/actionLog/filter",                    data),
    "filterForClient"   : (data) => Ajax.get("/actionLog/filterForClient",           data),
    "filterForManager"  : (data) => Ajax.get("/actionLog/filterForManager",          data),
    "filterForUser"     : (data) => Ajax.get("/actionLog/filterForUser",             data),
};
export const ErrorLog = {
    "filter"            : (data) => Ajax.get("/errorLog/filter",                     data),
    "getOne"            : (data) => Ajax.get("/errorLog/getOne",                     data),
    "markResolved"      : (data) => Ajax.get("/errorLog/markResolved",               data),
};
