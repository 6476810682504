import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { loginAs }          from "Actions/Core/AuthActions";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";

// Dialogs
import UserView             from "./UserView";
import UserEdit             from "./UserEdit";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Content              from "dashboard/dist/Components/Content";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";

// Actions
import {
    fetchUsers, deleteUser,
} from "Actions/App/Client/UserActions";



/**
 * The User List
 */
class UserList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=}  params
     * @param {Boolean=} withLoader
     * @returns {Void}
     */
    fetch = (params, withLoader) => {
        const { type, elemID, data } = this.props;
        this.props.fetchUsers(type, elemID, params || data.sort, withLoader);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        if (action.isLogin) {
            this.props.loginAs(elemID);
        } else {
            this.setState({ action, elemID });
        }
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }



    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch(null, false);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const elemID = this.state.elemID;
        this.endAction();
        await this.props.deleteUser(elemID);
        this.fetch(null, false);
    }


    
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                            } = this.state;
        const { isAdmin, data, params, route, withDetails } = this.props;
        const { canEdit, list, total, sort, loading       } = data;

        const elemName = Utils.getValue(list, "credentialID", elemID, "credentialName");

        return <Main withDetails={withDetails}>
            <Header message="USERS_NAME" icon="user" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content>
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="USERS_NONE_AVAILABLE"
                    isLoading={loading}
                >
                    <TableHead>
                        <TableHeader field="firstName"   message="GENERAL_NAME"    />
                        <TableHeader field="level"       message="USERS_LEVEL"     />
                        <TableHeader field="createdTime" message="GENERAL_CREATED" maxWidth="100" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.credentialID} elemID={elem.credentialID}>
                            <TableCell message={elem.credentialName} circle={elem.statusColor} />
                            <TableCell message={elem.levelName}      />
                            <TableCell message={elem.createdDate}    />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"   message="USERS_VIEW_TITLE"   isHidden={canEdit} />
                        <TableAction action="EDIT"   message="USERS_EDIT_TITLE"   />
                        <TableAction action="DELETE" message="USERS_DELETE_TITLE" />
                        <TableAction action="LOGIN"  message="GENERAL_LOGIN_AS"   isHidden={!isAdmin} />
                    </TableActionList>
                </Table>
            </Content>

            <UserView
                open={!canEdit && action.isView}
                elemID={elemID}
                onClose={this.endAction}
            />
            <UserEdit
                open={canEdit && action.isVCE}
                elemID={elemID}
                clientID={params.clientID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="USERS_DELETE_TITLE"
                message="USERS_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchUsers  : PropTypes.func.isRequired,
        deleteUser  : PropTypes.func.isRequired,
        loginAs     : PropTypes.func.isRequired,
        isAdmin     : PropTypes.bool.isRequired,
        data        : PropTypes.object.isRequired,
        type        : PropTypes.string.isRequired,
        params      : PropTypes.object.isRequired,
        route       : PropTypes.string.isRequired,
        withDetails : PropTypes.bool.isRequired,
        elemID      : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAdmin : state.auth.isAdmin,
            data    : state.user,
        };
    }
}

export default connect(UserList.mapStateToProps, {
    fetchUsers, deleteUser, loginAs,
})(UserList);
