export default {

    NONE                : "",

    // Auth Urls
    LOGIN               : "ingresar",
    SIGNUP              : "registrarse",
    PASS_RECOVER        : "recuperar",
    PASS_CODE           : "codigo",
    PASS_RESET          : "resetear/:code",
    PASS_CHANGE         : "cambiar",
    INFORMATION         : "informacion",

    
    // Client Urls
    CLIENTS             : "clientes",
    CLIENT              : "clientes/:clientID",
    CLIENT_DASHBOARD    : "clientes/:clientID/tablero",

    USERS               : "usuarios",
    PRODUCTS            : "productos",
    CATEGORIES          : "categorias",
    SUBCATEGORIES       : "subcategorias",
    OPTIONALS           : "opcionales",
    SIZES               : "tamanos",
    TABLES              : "tablas",
    CODES               : "codigos",
    OPTIONS             : "opciones",
    DASHBOARD           : "tablero",
    PAYMENTS            : "pagos",


    // Ticket Urls
    TICKETS             : "tickets",
    TICKET_TYPES        : "tipos",

    // Settings
    SETTINGS            : "configuracion",
    ADMINS              : "admins",
    PLANS               : "planes",
    EMAIL_TEMPLATES     : "emails",
    ACTIONS             : "acciones",
    ERRORS              : "errores",
    PROFILE             : "perfil",
}
