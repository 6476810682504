import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import NLS                  from "dashboard/dist/Core/NLS";
import ClassList            from "dashboard/dist/Utils/ClassList";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import TableGrid            from "./TableGrid";
import ActionList           from "./ActionList";
import OrderList            from "./OrderList";
import OrderView            from "./OrderView";
import Main                 from "dashboard/dist/Components/Main";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";
import CircularLoader       from "dashboard/dist/Components/CircularLoader";
import IconLink             from "dashboard/dist/Components/IconLink";

// Actions
import {
    fetchDashboard, fetchNewData,
    acceptAll, acceptWaiter, acceptBill, completeServing,
} from "Actions/App/Client/DashboardActions";

// Styles
import "Styles/Components/App/Dashboard.css";



/**
 * The Dashboard Page
 */
class DashboardPage extends React.Component {
    // The current State
    state = {
        action     : Action.get(),
        servingID  : 0,
        actionType : "",
        loading    : false,
        isMounted  : true,
        timeout    : null,
    }

    /**
     * Set the Dashboard on Mount
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchDashboard(this.props.params.clientID);
        const timeout  = window.setTimeout(this.fetchNewData, 10000);
        this.setState({ isMounted : true, timeout });
    }

    /**
     * Unset the Dashboard on Mount
     * @returns {Void}
     */
    componentWillUnmount() {
        if (this.state.timeout) {
            window.clearTimeout(this.state.timeout);
        }
        this.setState({ isMounted : false, timeout : null });
    }



    /**
     * Starts an Action
     * @param {Object}  action
     * @param {Number=} servingID
     * @param {String=} actionType
     * @returns {Void}
     */
    startAction = (action, servingID = 0, actionType = "") => {
        this.setState({ action, servingID, actionType });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get());
    }



    /**
     * Fetches the New Data
     * @returns {Void}
     */
    fetchNewData = () => {
        const { fetchNewData, params, lastAction, lastTime } = this.props;
        const { isMounted, timeout                         } = this.state;

        if (timeout) {
            window.clearTimeout(timeout);
        }
        if (isMounted) {
            fetchNewData(params.clientID, lastAction, lastTime);
            const timeout = window.setTimeout(this.fetchNewData, 5000);
            this.setState({ timeout });
        }
    };

    /**
     * Handles the Click
     * @param {Object} elem
     * @returns {Promise}
     */
    handleClick = async (elem) => {
        const { hasPayment, servings } = this.props;

        if (elem.actionType) {
            if (hasPayment) {
                const payment = Utils.getValue(servings, "servingID", elem.servingID, "billPayment");
                if (payment && (elem.actionType === "ALL" || elem.actionType === "BILL")) {
                    this.startAction(Action.get("COMPLETE"), elem.servingID, elem.actionType);
                    return;
                }
            }
            this.handleTable(elem.servingID, elem.actionType);
        }
    }

    /**
     * Handles the Table change
     * @param {Number} servingID
     * @param {String} actionType
     * @returns {Promise}
     */
    handleTable = async (servingID, actionType) => {
        const { acceptAll, acceptBill, acceptWaiter } = this.props;
        this.endAction();
        try {
            let result;
            switch (actionType) {
            case "ALL":
                result = await acceptAll(servingID);
                break;
            case "WAITER":
                result = await acceptWaiter(servingID);
                break;
            case "BILL":
                result = await acceptBill(servingID);
                break;
            default:
            }
            if (result.success) {
                this.fetchNewData();
            }
            this.setState({ loading : false });
        } catch (errors) {
            this.setState({ loading : false, errors });
        }
    }

    /**
     * Handles the View
     * @param {Number} servingID
     * @returns {Void}
     */
    handleView = (servingID) => {
        this.startAction(Action.get("VIEW"), servingID);
    }

    /**
     * Handles the Complete
     * @param {Number} servingID
     * @returns {Void}
     */
    handleComplete = (servingID) => {
        this.startAction(Action.get("DELETE"), servingID);
    }

    /**
     * Updates a Serving
     * @returns {Void}
     */
    updateServing = () => {
        this.fetchNewData();
    }

    /**
     * Completes a Bill
     * @returns {Promise}
     */
    completeBill = async () => {
        const { servingID, actionType } = this.state;
        this.endAction();
        this.handleTable(servingID, actionType);
    }

    /**
     * Completes a Serving
     * @returns {Promise}
     */
    completeServing = async () => {
        const servingID = this.state.servingID;
        this.endAction();
        const result = await this.props.completeServing(servingID);
        if (result.success) {
            this.fetchNewData();
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { parent, loading, client, hasTables, hasOrders, servings, tables } = this.props;
        const { action, servingID                                               } = this.state;
        
        if (loading) {
            return <Main className="dashboard dashboard-loading">
                <CircularLoader variant="white" />
            </Main>;
        }

        const tableName   = Utils.getValue(tables, "servingID", servingID, "name");
        const payment     = Utils.getValue(servings, "servingID", servingID, "billPayment");
        const paymentName = payment ? NLS.get(`DASHBOARD_PAYMENT_${payment.toUpperCase()}`) : "";
        const hasBoth     = hasOrders  && hasTables;
        const tablesOnly  = !hasOrders && hasTables;
        const ordersOnly  = hasOrders  && !hasTables;
        
        const classList   = new ClassList("dashboard-content");
        classList.addIf("dashboard-tables-only", tablesOnly);
        classList.addIf("dashboard-orders-only", ordersOnly);

        return <Main className="dashboard">
            <div className={classList.get()}>
                <h2 className="dashboard-title">
                    <IconLink
                        variant="darker"
                        icon="back"
                        href={parent}
                    />
                    {client.name}
                </h2>
                {hasTables && <TableGrid
                    onClick={this.handleClick}
                    onView={this.handleView}
                    onComplete={this.handleComplete}
                />}
                {ordersOnly && <>
                    <ActionList onView={this.handleView} />
                    <OrderList onView={this.handleView} />
                    <OrderList onView={this.handleView} isCompleted />
                </>}
                {tablesOnly && <ActionList onView={this.handleView} />}
                {hasBoth && <div className="dashboard-sidebar">
                    <OrderList onView={this.handleView} />
                    <ActionList onView={this.handleView} />
                </div>}
            </div>

            <OrderView
                open={action.isView}
                servingID={servingID}
                onUpdate={this.updateServing}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isComplete}
                icon="check"
                title="DASHBOARD_BILL_TITLE"
                message="DASHBOARD_BILL_TEXT"
                content={paymentName}
                onSubmit={this.completeBill}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isDelete}
                icon="delete"
                title="DASHBOARD_COMPLETE_TITLE"
                message="DASHBOARD_COMPLETE_TEXT"
                primary="DASHBOARD_COMPLETE"
                content={tableName}
                onSubmit={this.completeServing}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchDashboard  : PropTypes.func.isRequired,
        fetchNewData    : PropTypes.func.isRequired,
        acceptAll       : PropTypes.func.isRequired,
        acceptWaiter    : PropTypes.func.isRequired,
        acceptBill      : PropTypes.func.isRequired,
        completeServing : PropTypes.func.isRequired,
        loading         : PropTypes.bool.isRequired,
        client          : PropTypes.object.isRequired,
        hasTables       : PropTypes.bool.isRequired,
        hasOrders       : PropTypes.bool.isRequired,
        hasPayment      : PropTypes.bool.isRequired,
        options         : PropTypes.object.isRequired,
        servings        : PropTypes.array.isRequired,
        tables          : PropTypes.array.isRequired,
        lastAction      : PropTypes.number.isRequired,
        params          : PropTypes.object.isRequired,
        parent          : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            loading    : state.dashboard.loading,
            client     : state.dashboard.client,
            hasTables  : state.dashboard.hasTables,
            hasOrders  : state.dashboard.hasOrders,
            hasPayment : state.dashboard.hasPayment,
            options    : state.dashboard.options,
            servings   : state.dashboard.servings,
            tables     : state.dashboard.tables,
            lastAction : state.dashboard.lastAction,
            lastTime   : state.dashboard.lastTime,
        };
    }
}

export default connect(DashboardPage.mapStateToProps, {
    fetchDashboard, fetchNewData,
    acceptAll, acceptWaiter, acceptBill, completeServing,
})(DashboardPage);
