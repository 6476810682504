import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    PRODUCT_LOADING,
    PRODUCT_LIST, PRODUCT_ELEM, PRODUCT_EDIT,
} from "Utils/Types";



// The initial State
const initialState = {
    loading       : false,
    error         : false,
    edition       : 0,
    canCreate     : false,
    canEdit       : false,
    canImport     : false,
    canExport     : false,
    list          : [],
    total         : 0,
    elem          : {},
    position      : 0,
    categories    : [],
    subcategories : {},
    optionals     : [],
    sizes         : [],
    sort          : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]}  list
 * @param {Object[]=} optionals
 * @returns {Object[]}
 */
function parseList(list, optionals) {
    return Utils.parseList(list, (elem) => parseElem(elem, optionals));
}

/**
 * Parses a single Element
 * @param {Object}    elem
 * @param {Object[]=} optionals
 * @returns {Object}
 */
function parseElem(elem, optionals) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.priceText       = `$ ${elem.priceFormat}`;
    elem.statusName      = Status.getName(elem.status);
    elem.statusColor     = Status.getColor(elem.status);
    elem.statusClass     = Status.getTextClass(elem.status);
    elem.activeSizes     = [];

    if (optionals) {
        elem.optionalsNames = [];
        for (const optional of elem.optionalsParts) {
            const name = Utils.getValue(optionals, "key", optional, "value");
            elem.optionalsNames.push(name);
        }
        elem.optionalsName = elem.optionalsNames.join(", ");
    }

    if (elem.hasSizes) {
        elem.priceText = "$ 0";
        if (elem.sizes && elem.sizes.length > 0) {
            let firtActive = false;
            for (const size of elem.sizes) {
                size.priceText   = `$ ${size.priceFormat}`;
                size.statusName  = Status.getName(size.status);
                size.statusClass = Status.getTextClass(size.status);
                if (!firtActive && size.isActive && Status.is("ACTIVE", size.status)) {
                    elem.priceText = size.priceText;
                    firtActive = true;
                }
                if (size.isActive) {
                    elem.activeSizes.push(size);
                }
            }
        }
    }
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, PRODUCT_LIST, PRODUCT_ELEM)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case PRODUCT_LOADING:
        return {
            ...state,
            loading   : true,
        };
    
    case PRODUCT_LIST:
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : parseList(action.data.list),
            total     : action.data.total,
            sort      : action.data.sort,
        };
    
    case PRODUCT_ELEM:
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            canEdit       : action.data.canEdit,
            elem          : parseElem(action.data.elem, action.data.optionals),
            categories    : action.data.categories,
            subcategories : action.data.subcategories,
            optionals     : action.data.optionals,
            sizes         : action.data.sizes,
            position      : action.data.position,
        };

    case PRODUCT_EDIT:
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            categories    : action.data.categories,
            subcategories : action.data.subcategories,
            optionals     : action.data.optionals,
            sizes         : action.data.sizes,
            position      : action.data.position,
        };

    default:
        return state;
    }
};
