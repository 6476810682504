import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchTable }       from "Actions/App/Client/TableActions";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import Columns              from "dashboard/dist/Components/Columns";
import ViewField            from "dashboard/dist/Components/ViewField";



/**
 * The Table View Dialog
 */
class TableView extends React.Component {
    // The Current State
    state = {
        loading : false,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchTable } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchTable(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open, onClose, usesGrid } = this.props;
        const { loading                       } = this.state;
        
        return <ViewDialog
            open={open}
            icon="table"
            title="TABLES_VIEW_TITLE"
            onClose={onClose}
            isLoading={loading}
            withSpacing
        >
            <ViewField label="TABLES_ITS_NAME" value={elem.name} />
            <Columns>
                <ViewField
                    isHidden={!usesGrid}
                    label="TABLES_ROW"
                    value={elem.row}
                />
                <ViewField
                    isHidden={!usesGrid}
                    label="TABLES_COLUMN"
                    value={elem.column}
                />
                <ViewField
                    label="GENERAL_POSITION"
                    value={elem.position}
                />
                <ViewField
                    label="GENERAL_STATUS"
                    value={elem.statusName}
                />
            </Columns>
        </ViewDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchTable : PropTypes.func.isRequired,
        open       : PropTypes.bool.isRequired,
        onClose    : PropTypes.func.isRequired,
        edition    : PropTypes.number.isRequired,
        usesGrid   : PropTypes.bool.isRequired,
        elem       : PropTypes.object.isRequired,
        elemID     : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition  : state.table.edition,
            usesGrid : state.table.usesGrid,
            elem     : state.table.elem,
        };
    }
}

export default connect(TableView.mapStateToProps, {
    fetchTable,
})(TableView);
