import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Components
import GuestRouter          from "dashboard/dist/Components/GuestRoute";



/**
 * Only Unauthenticated Users can access the given Component
 */
class GuestRoute extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isAuthenticated, ...rest } = this.props;
        
        return <GuestRouter {...rest} isAuthenticated={isAuthenticated} />;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isAuthenticated : PropTypes.bool.isRequired,
        component       : PropTypes.oneOfType([ PropTypes.func, PropTypes.object ]).isRequired,
        url             : PropTypes.string,
        path            : PropTypes.string,
        isHidden        : PropTypes.bool,
        exact           : PropTypes.bool,
        location        : PropTypes.object,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default connect(GuestRoute.mapStateToProps)(GuestRoute);
