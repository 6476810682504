import React                        from "react";
import PropTypes                    from "prop-types";
import { connect }                  from "react-redux";
import { Switch, Route }            from "react-router-dom";
import { hideResult }               from "Actions/Core/CoreActions";

// Sections
import App                          from "Components/Core/App";
import Auth                         from "Components/Core/Auth";

// Components
import LinearLoader                 from "dashboard/dist/Components/LinearLoader";
import Result                       from "dashboard/dist/Components/Result";

// Styles
import "Styles/Components/Core/Properties.css";
import "Styles/Components/Core/Icons.css";
import "Styles/Components/Core/Main.css";



/**
 * The Main
 */
class Main extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isAuthenticated, loading, result, hideResult } = this.props;
        const { open, variant, message                       } = result;

        return <>
            <LinearLoader open={loading} />
            <Result
                open={open}
                variant={variant}
                message={message}
                onClose={hideResult}
            />
            
            <Switch>
                <Route path="/" render={(props) => (
                    !isAuthenticated ? <Auth {...props} /> : <App {...props} />
                )} />
            </Switch>
        </>;
    }
    


    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        hideResult      : PropTypes.func.isRequired,
        isAuthenticated : PropTypes.bool.isRequired,
        loading         : PropTypes.bool.isRequired,
        result          : PropTypes.object.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAuthenticated : state.auth.isAuthenticated,
            loading         : state.core.loading,
            result          : state.core.result,
        };
    }
}

export default connect(Main.mapStateToProps, {
    hideResult,
})(Main);
