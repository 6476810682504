import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchUser }        from "Actions/App/Client/UserActions";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import Columns              from "dashboard/dist/Components/Columns";
import ViewField            from "dashboard/dist/Components/ViewField";



/**
 * The User View Dialog
 */
class UserView extends React.Component {
    // The Current State
    state = {
        loading : false,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchUser } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchUser(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false });
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open, onClose } = this.props;
        const { loading             } = this.state;
        
        return <ViewDialog
            open={open}
            icon="user"
            title="USERS_VIEW_TITLE"
            onClose={onClose}
            isLoading={loading}
            withSpacing
        >
            <Columns>
                <ViewField label="USERS_FIRST_NAME" value={elem.firstName}  showEmpty />
                <ViewField label="USERS_LAST_NAME"  value={elem.lastName}   showEmpty />
                <ViewField label="GENERAL_EMAIL"    value={elem.email}      showEmpty />
                <ViewField label="USERS_PHONE"      value={elem.phone}      showEmpty />
                <ViewField label="USERS_DNI"        value={elem.dni}        />
                <ViewField label="USERS_CUIT"       value={elem.cuit}       />
                <ViewField label="USERS_ADRRESS"    value={elem.address}    />
                <ViewField label="USERS_BIRTHDATE"  value={elem.birthDate}  />
                <ViewField label="USERS_LEVEL"      value={elem.levelName}  />
                <ViewField label="GENERAL_STATUS"   value={elem.statusName} />
            </Columns>
            <ViewField label="GENERAL_OBSERVATIONS" value={elem.observations} />
        </ViewDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchUser : PropTypes.func.isRequired,
        open      : PropTypes.bool.isRequired,
        onClose   : PropTypes.func.isRequired,
        edition   : PropTypes.number.isRequired,
        elem      : PropTypes.object.isRequired,
        elemID    : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.user.edition,
            elem    : state.user.elem,
        };
    }
}

export default connect(UserView.mapStateToProps, {
    fetchUser,
})(UserView);
