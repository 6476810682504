import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchClient, fetchClientEdit, editClient,
} from "Actions/App/Client/ClientActions";



/**
 * The Client Edit Dialog
 */
class ClientEdit extends React.Component {
    // The Initial Data
    initialData = {
        clientID         : 0,
        name             : "",
        socialReason     : "",
        ivaCategory      : "",
        cuit             : "",
        dni              : "",
        firstName        : "",
        lastName         : "",
        email            : "",
        phone            : "",
        address          : "",
        locality         : "",
        province         : "",
        zipCode          : "",
        language         : "es",
        planID           : 0,
        promoPlanID      : 0,
        promoEndDate     : "",
        serviceStartDate : "",
        nextPaymentDate  : "",
        status           : Status.getID("ACTIVE"),
        observations     : "",
    }

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : false,
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, fetchClient, fetchClientEdit } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchClient(elemID);
                loading = true;
            // Load edit data
            } else {
                fetchClientEdit();
                loading = true;
            }
        
        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
            } else {
                data = { ...this.initialData };
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editClient(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose, withCUIT, provinces, plans } = this.props;
        const { data, loading, errors                             } = this.state;
        
        const reqCUIT = withCUIT.indexOf(Number(data.ivaCategory)) > -1 || !data.ivaCategory;

        return <EditDialog
            open={open}
            icon="client"
            title={!!elemID ? "CLIENTS_EDIT_TITLE" : "CLIENTS_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <Columns>
                <InputField
                    name="name"
                    label="CLIENTS_ITS_NAME"
                    value={data.name}
                    error={errors.name}
                    onChange={this.handleChange}
                    isRequired
                />
                <InputField
                    name="socialReason"
                    label="CLIENTS_SOCIAL_REASON"
                    value={data.socialReason}
                    error={errors.socialReason}
                    onChange={this.handleChange}
                />
            
                <InputField
                    type="select"
                    name="ivaCategory"
                    label="GENERAL_IVA_CATEGORY"
                    options={NLS.select("GENERAL_IVA_CATEGORIES")}
                    value={data.ivaCategory}
                    error={errors.ivaCategory}
                    onChange={this.handleChange}
                    withNone
                />
                <InputField
                    isHidden={!reqCUIT}
                    name="cuit"
                    label="CLIENTS_CUIT"
                    value={data.cuit}
                    error={errors.cuit}
                    onChange={this.handleChange}
                />
                <InputField
                    isHidden={reqCUIT}
                    name="dni"
                    label="CLIENTS_DNI"
                    value={data.dni}
                    error={errors.dni}
                    onChange={this.handleChange}
                />

                <InputField
                    name="firstName"
                    label="CLIENTS_FIRST_NAME"
                    value={data.firstName}
                    error={errors.firstName}
                    onChange={this.handleChange}
                />
                <InputField
                    name="lastName"
                    label="CLIENTS_LAST_NAME"
                    value={data.lastName}
                    error={errors.lastName}
                    onChange={this.handleChange}
                />

                <InputField
                    type="email"
                    name="email"
                    label="CLIENTS_EMAIL"
                    value={data.email}
                    error={errors.email}
                    onChange={this.handleChange}
                    isRequired
                />
                <InputField
                    name="phone"
                    label="CLIENTS_PHONE"
                    value={data.phone}
                    error={errors.phone}
                    onChange={this.handleChange}
                />

                <InputField
                    name="address"
                    label="CLIENTS_ADDRESS"
                    value={data.address}
                    error={errors.address}
                    onChange={this.handleChange}
                />
                <InputField
                    name="locality"
                    label="CLIENTS_LOCALITY"
                    value={data.locality}
                    error={errors.locality}
                    onChange={this.handleChange}
                />
                <InputField
                    type="select"
                    name="province"
                    label="CLIENTS_PROVINCE"
                    options={provinces}
                    value={data.province}
                    error={errors.province}
                    onChange={this.handleChange}
                    withNone
                />
                <InputField
                    name="zipCode"
                    label="CLIENTS_ZIP_CODE"
                    value={data.zipCode}
                    error={errors.zipCode}
                    onChange={this.handleChange}
                />
            </Columns>
            <Columns amount="3">
                <InputField
                    type="select"
                    name="planID"
                    label="CLIENTS_MAIN_PLAN"
                    options={plans}
                    value={data.planID}
                    error={errors.planID}
                    onChange={this.handleChange}
                    withNone
                    isRequired
                />
                <InputField
                    type="select"
                    name="promoPlanID"
                    label="CLIENTS_PROMO_PLAN"
                    options={plans}
                    value={data.promoPlanID}
                    error={errors.promoPlanID}
                    onChange={this.handleChange}
                    withNone
                />
                <InputField
                    type="date"
                    name="promoEndDate"
                    label="CLIENTS_PROMO_END"
                    value={data.promoEndDate}
                    error={errors.promoEndDate}
                    onChange={this.handleChange}
                />
            </Columns>
            <Columns>
                <InputField
                    type="date"
                    name="serviceStartDate"
                    label="CLIENTS_SERVICE_START"
                    value={data.serviceStartDate}
                    error={errors.serviceStartDate}
                    onChange={this.handleChange}
                />
                <InputField
                    type="date"
                    name="nextPaymentDate"
                    label="CLIENTS_NEXT_PAYMENT"
                    value={data.nextPaymentDate}
                    error={errors.nextPaymentDate}
                    onChange={this.handleChange}
                />

                <InputField
                    type="select"
                    name="language"
                    label="CLIENTS_LANGUAGE"
                    options="LANGUAGES"
                    value={data.language}
                    error={errors.language}
                    onChange={this.handleChange}
                    withNone
                    isRequired
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    value={data.status}
                    error={errors.status}
                    onChange={this.handleChange}
                    options={Status.getSelect()}
                    withNone
                    isRequired
                    shrink
                />
            </Columns>
            <InputField
                type="textarea"
                name="observations"
                label="GENERAL_OBSERVATIONS"
                value={data.observations}
                onChange={this.handleChange}
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchClient     : PropTypes.func.isRequired,
        fetchClientEdit : PropTypes.func.isRequired,
        editClient      : PropTypes.func.isRequired,
        open            : PropTypes.bool.isRequired,
        onClose         : PropTypes.func.isRequired,
        onSubmit        : PropTypes.func.isRequired,
        edition         : PropTypes.number.isRequired,
        withCUIT        : PropTypes.array.isRequired,
        provinces       : PropTypes.array.isRequired,
        plans           : PropTypes.array.isRequired,
        elem            : PropTypes.object.isRequired,
        elemID          : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition   : state.client.edition,
            withCUIT  : state.client.withCUIT,
            provinces : state.client.provinces,
            plans     : state.client.plans,
            elem      : state.client.elem,
        };
    }
}

export default connect(ClientEdit.mapStateToProps, {
    fetchClient, fetchClientEdit, editClient,
})(ClientEdit);
