import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Route, Redirect }  from "react-router-dom";
import Params               from "dashboard/dist/Core/Params";
import NLS                  from "dashboard/dist/Core/NLS";



/**
 * Only Authenticated Users can access the given Component
 */
class UserRoute extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isAuthenticated, type, isValid, component : Component, ...rest } = this.props;

        return <Route {...rest} render={(props) => {
            const route  = props.location.pathname;
            const from   = Params.getFrom(route);
            const parent = Params.getParent(route);
            const params = Params.getAll(props.match.params);
            const elemID = Params.getOne(params, type);

            if (!isAuthenticated || !isValid) {
                return <Redirect to={{
                    pathname : NLS.url("LOGIN"),
                    state    : { from : props.location },
                }} />;
            }

            return <Component
                {...props}
                type={type}
                route={route}
                from={from}
                parent={parent}
                params={params}
                elemID={elemID}
            />;
        }} />;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isAuthenticated : PropTypes.bool.isRequired,
        isValid         : PropTypes.bool,
        isHidden        : PropTypes.bool,
        url             : PropTypes.string,
        component       : PropTypes.oneOfType([ PropTypes.func, PropTypes.object ]).isRequired,
        exact           : PropTypes.bool,
        type            : PropTypes.string,
        path            : PropTypes.string,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        isValid : true,
    }
    
    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAuthenticated : state.auth.isAuthenticated,
        };
    }
}

export default connect(UserRoute.mapStateToProps)(UserRoute);
