// The Access IDs
const GENERAL   = 0;
const ASSISTANT = 1;
const MANAGER   = 2;

const SUPPORT   = 11;
const ADMIN     = 12;
const OWNER     = 13;



// The App Access
export default {
    "values" : [
        {
            "id"      : GENERAL,
            "name"    : "GENERAL",
            "message" : "ACCESS_GENERAL",
        },
        {
            "id"      : ASSISTANT,
            "name"    : "ASSISTANT",
            "message" : "ACCESS_ASSISTANT",
        },
        {
            "id"      : MANAGER,
            "name"    : "MANAGER",
            "message" : "ACCESS_MANAGER",
        },

        {
            "id"      : SUPPORT,
            "name"    : "SUPPORT",
            "message" : "ACCESS_SUPPORT",
        },
        {
            "id"      : ADMIN,
            "name"    : "ADMIN",
            "message" : "ACCESS_ADMIN",
        },
        {
            "id"      : OWNER,
            "name"    : "OWNER",
            "message" : "ACCESS_OWNER",
        },
    ],

    // The Access per Type
    "groups" : {
        "USER"    : [ ASSISTANT, MANAGER ],
        "SUPPORT" : [ SUPPORT, ADMIN ],
        "ADMIN"   : [ SUPPORT, ADMIN, OWNER ],
        "OWNER"   : [ ADMIN, OWNER ],
    },

    // The Access IDs
    GENERAL,
    ASSISTANT,
    MANAGER,

    SUPPORT,
    ADMIN,
    OWNER,
};
