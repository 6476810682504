import React                from "react";
import PropTypes            from "prop-types";

// Components
import SettingsForm         from "./SettingsForm";
import SettingsAvatar       from "./SettingsAvatar";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import Content              from "dashboard/dist/Components/Content";

// Styles
import "Styles/Components/App/Settings.css";



/**
 * The Settings Page
 */
class SettingsPage extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { route } = this.props;

        return <Main>
            <Header message="SETTINGS_NAME" icon="settings" route={route} />
            <Content className="settings-container">
                <SettingsForm />
                <SettingsAvatar />
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        route : PropTypes.string.isRequired,
    }
}

export default SettingsPage;
