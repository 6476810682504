import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import Circle               from "dashboard/dist/Components/Circle";
import Icon                 from "dashboard/dist/Components/Icon";



/**
 * The Table Grid
 */
class TableGrid extends React.Component {
    /**
     * Counts the Orders of a Serving
     * @param {Number} servingID
     * @return {Number}
     */
    countOrders(servingID) {
        return this.props.orders.reduce((accumulator, currentValue) => {
            return currentValue.servingID === servingID ? accumulator + 1 : accumulator;
        }, 0);
    }

    /**
     * Returns a class depending on the state of the Orders
     * @param {Number} servingID
     * @return {String}
     */
    getOrdersClass(servingID) {
        const verifying = this.props.orders.filter((elem) => elem.servingID === servingID && elem.isVerifying);
        const confiemed = this.props.orders.filter((elem) => elem.servingID === servingID && elem.isConfirmed);
        if (verifying.length > 0) {
            return "dashboard-order-verifying";
        }
        if (confiemed.length > 0) {
            return "dashboard-order-confiemed";
        }
        return "";
    }



    /**
     * Returns the Tables
     * @returns {React.ReactElement[]}
     */
    getChildren() {
        const { tables, options, hasWaiter, hasOrders, onClick, onView, onComplete } = this.props;
        const result = [];

        for (const table of tables) {
            const withWaiter = hasWaiter && table.isActive;
            const withOrders = hasOrders && table.isActive;
            const style      = {};
            if (options.usesGrid) {
                style.gridRowStart    = table.row;
                style.gridColumnStart = table.column;
            }

            result.push(<div key={table.tableID} className="dashboard-table" style={style}>
                <section
                    className={`dashboard-table${table.tableState}`}
                    onClick={() => onClick(table)}
                >
                    <div>{table.name}</div>
                    {!!table.actionName && <div className="dashboard-current">
                        {table.actionName}
                    </div>}
                </section>
                {withWaiter && <footer>
                    <div className="dashboard-waiter">
                        <span className="dashboard-name">
                            {NLS.get("DASHBOARD_WAITER_SHORT")}
                        </span>
                        <Circle variant={table.waiterColor} />
                    </div>
                    <div className="dashboard-bill">
                        <Circle variant={table.billColor} />
                        <span className="dashboard-name">
                            {NLS.get("DASHBOARD_BILL_SHORT")}
                        </span>
                    </div>
                </footer>}
                {withOrders && <div
                    className={"dashboard-order-count " + this.getOrdersClass(table.servingID)}
                    onClick={() => onView(table.servingID)}
                >
                    {NLS.pluralize("ORDERS_AMOUNT", this.countOrders(table.servingID))}
                </div>}
                {table.isActive && <Icon
                    icon="close"
                    onClick={() => onComplete(table.servingID)}
                />}
            </div>);
        }
        return result;
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { options } = this.props;
        
        const tableClass = options.smallGrid ? "dashboard-small" : "";
        
        return <section className="dashboard-tables dark-scrollbars">
            <div className={`dashboard-grid ${tableClass}`}>
                {this.getChildren()}
            </div>
        </section>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        hasWaiter  : PropTypes.bool.isRequired,
        hasOrders  : PropTypes.bool.isRequired,
        options    : PropTypes.object.isRequired,
        tables     : PropTypes.array.isRequired,
        orders     : PropTypes.array.isRequired,
        onClick    : PropTypes.func.isRequired,
        onView     : PropTypes.func.isRequired,
        onComplete : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            hasWaiter : state.dashboard.hasWaiter,
            hasOrders : state.dashboard.hasOrders,
            options   : state.dashboard.options,
            tables    : state.dashboard.tables,
            orders    : state.dashboard.orders,
        };
    }
}

export default connect(TableGrid.mapStateToProps)(TableGrid);
