import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    TICKET_TYPE_LOADING,
    TICKET_TYPE_LIST, TICKET_TYPE_ELEM, TICKET_TYPE_EDIT,
} from "Utils/Types";



// The initial State
const initialState = {
    loading     : false,
    error       : false,
    edition     : 0,
    canCreate   : false,
    canEdit     : false,
    canImport   : false,
    canExport   : false,
    list        : [],
    total       : 0,
    elem        : {},
    position    : 0,
    credentials : [],
    sort        : {
        orderBy  : "position",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.credentialName  = elem.credentialName || NLS.get("GENERAL_NOBODY");
    elem.statusName      = Status.getName(elem.status);
    elem.statusColor     = Status.getColor(elem.status);
    elem.statusClass     = Status.getTextClass(elem.status);
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, TICKET_TYPE_LIST, TICKET_TYPE_ELEM, TICKET_TYPE_EDIT)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case TICKET_TYPE_LOADING:
        return {
            ...state,
            loading     : true,
        };

    case TICKET_TYPE_LIST:
        return {
            ...state,
            loading     : false,
            error       : false,
            canCreate   : action.data.canCreate,
            canEdit     : action.data.canEdit,
            canImport   : action.data.canImport,
            canExport   : action.data.canExport,
            list        : parseList(action.data.list),
            total       : action.data.total,
            sort        : action.data.sort,
        };
    
    case TICKET_TYPE_ELEM:
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            canEdit     : action.data.canEdit,
            elem        : parseElem(action.data.elem),
            position    : action.data.position,
            credentials : action.data.credentials,
        };
    
    case TICKET_TYPE_EDIT:
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            position    : action.data.position,
            credentials : action.data.credentials,
        };
    
    default:
        return state;
    }
};
