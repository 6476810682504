import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";

// APIs
import {
    Optional, OptionalItem,
} from "Utils/API";

// Types
import {
    OPTIONAL, OPTIONAL_LOADING,
    OPTIONAL_LIST, OPTIONAL_ELEM, OPTIONAL_EDIT, OPTIONAL_ITEM,
} from "Utils/Types";



/**
 * Fetches the Optional List
 * @param {String}   type
 * @param {Number}   elemID
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchOptionals(type, elemID, params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : OPTIONAL_LOADING });
        }
        Params.unset(params);
        let data = {};

        switch (type) {
        case "CLIENT":
            params.clientID = elemID;
            data = await Optional.getAllForClient(params);
            break;
        case "MANAGER":
            data = await Optional.getAllForManager(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : OPTIONAL_LIST, data });
        dispatch(setSection(OPTIONAL));
    };
}

/**
 * Fetches a single Optional
 * @param {Number} optionalID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchOptional(optionalID) {
    return async (dispatch) => {
        const data = await Optional.getOne({ optionalID });
        dispatch({ type : OPTIONAL_ELEM, data });
    };
}

/**
 * Fetches the Optional Edit data
 * @param {Number} clientID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchOptionalEdit(clientID) {
    return async (dispatch) => {
        const data = await Optional.getEditData({ clientID });
        dispatch({ type : OPTIONAL_EDIT, data });
    };
}



/**
 * Edits/Creates a Optional
 * @param {Object} params
 * @returns {() => Void}
 */
export function editOptional(params) {
    return () => Optional.edit(params);
}

/**
 * Partially edits an Optional
 * @param {Object} params
 * @returns {() => Void}
 */
export function editOptionalPartial(params) {
    return () => Optional.editPartial(params);
}

/**
 * Deletes a Optional
 * @param {Number} optionalID
 * @returns {() => Void}
 */
export function deleteOptional(optionalID) {
    return () => Optional.delete({ optionalID });
}




/**
 * Fetches a single Optional
 * @param {Number} optionalItemID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchOptionalItem(optionalItemID) {
    return async (dispatch) => {
        const data = await OptionalItem.getOne({ optionalItemID });
        dispatch({ type : OPTIONAL_ITEM, data });
    };
}

/**
 * Edits a Item in the Optional
 * @param {Object} params
 * @returns {() => Void}
 */
export function editOptionalItem(params) {
    return () => OptionalItem.edit(params);
}

/**
 * Edits a Item in the Optional
 * @param {Object} params
 * @returns {() => Void}
 */
export function editPartialOptionalItem(params) {
    return () => OptionalItem.editPartial(params);
}

/**
 * Deletes a Item from the Optional
 * @param {Number} optionalID
 * @param {Number} optionalItemID
 * @returns {() => Void}
 */
export function deleteOptionalItem(optionalID, optionalItemID) {
    return () => OptionalItem.delete({ optionalID, optionalItemID });
}
