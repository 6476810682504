import React                from "react";
import PropTypes            from "prop-types";

// Sections
import ClientList           from "Components/App/Client/Client/ClientList";
import TicketList           from "Components/App/Ticket/Ticket/TicketList";

// Components
import PrimaryNav           from "Components/Utils/Side/PrimaryNav";
import AdminRoute           from "Components/Utils/Route/AdminRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationList       from "dashboard/dist/Components/NavigationList";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";




/**
 * The Admin Container
 */
class AdminContainer extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { route } = this.props;
        
        return <>
            <PrimaryNav path={route}>
                <NavigationList>
                    <NavigationItem message="CLIENTS_NAME"  url="CLIENTS"  icon="client"   />
                    <NavigationItem message="TICKETS_NAME"  url="TICKETS"  icon="ticket"   />
                </NavigationList>
                <NavigationList>
                    <NavigationItem message="SETTINGS_NAME" url="SETTINGS" icon="settings" />
                </NavigationList>
            </PrimaryNav>
            <SwitchRoute type="ADMIN">
                <AdminRoute url="CLIENTS" component={ClientList} exact />
                <AdminRoute url="TICKETS" component={TicketList} exact />
            </SwitchRoute>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        route : PropTypes.string.isRequired,
    }
}

export default AdminContainer;
