import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "dashboard/dist/Core/NLS";
import MediaType            from "dashboard/dist/Core/MediaType";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import MediaField           from "Components/Utils/Media/MediaField";
import Main                 from "dashboard/dist/Components/Main";
import Header               from "dashboard/dist/Components/Header";
import CircularLoader       from "dashboard/dist/Components/CircularLoader";
import Form                 from "dashboard/dist/Components/Form";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";
import Button               from "dashboard/dist/Components/Button";

// Actions
import {
    fetchOptions, editOptions,
} from "Actions/App/Client/OptionsActions";

// Styles
import "Styles/Components/App/Options.css";



/**
 * The Options Page
 */
class OptionsPage extends React.Component {
    // The Initial Data
    initialData = {
        clientID        : 0,
        slug            : "",
        whatsapp        : 0,
        logo            : "",
        background      : "",
        codeImage       : "",
        fontColor       : "",
        buttonColor     : "",
        buttonFont      : "",
        showCents       : false,
        showSizes       : false,

        actionInterval  : 0,
        requestInterval : 0,
        requestTimeout  : 0,
        requestGoBack   : false,
        usesSemaphore   : false,
        smallGrid       : false,
        usesGrid        : false,

        resetOrderNum   : "",
        cancelOrders    : false,

        cashPayment     : false,
        debitPayment    : false,
        creditPayment   : false,
        mpPayment       : false,
    }

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : true,
        errors  : {},
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchOptions(this.props.params.clientID);
    }

    /**
     * Get the Admin when the Element ID changes
     * @returns {Void}
     */
    componentDidUpdate() {
        let data = null;
        if (this.props.elem.clientID !== this.state.data.clientID && !Utils.isEmpty(this.props.elem)) {
            data = Utils.extend(this.initialData, this.props.elem);
        }
        if (data) {
            this.setState({ data, loading : false, errors : {} });
        }
    }



    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editOptions(data);
                await this.props.fetchOptions(this.props.params.clientID);
                this.setState({ loading : false });
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { route, withDetails    } = this.props;
        const { data, loading, errors } = this.state;

        return <Main withDetails={withDetails}>
            <Header message="OPTIONS_NAME" icon="options" route={route} />
            {loading && <CircularLoader className="options-loading" />}
            {!loading && <Form className="options-container white-scrollbars" onSubmit={this.handleSubmit}>
                <div className="options-form">
                    <h3>{NLS.get("OPTIONS_GENERAL")}</h3>
                    <Columns>
                        <InputField
                            type="text"
                            name="slug"
                            label="OPTIONS_SLUG"
                            value={data.slug}
                            error={errors.slug}
                            onChange={this.handleChange}
                        />
                        <InputField
                            type="number"
                            name="whatsapp"
                            label="OPTIONS_WHATSAPP"
                            value={data.whatsapp}
                            error={errors.whatsapp}
                            onChange={this.handleChange}
                        />
                        <MediaField
                            name="logo"
                            label="OPTIONS_LOGO"
                            mediaType={MediaType.IMAGE}
                            mediaPath="clientes"
                            value={data.logo}
                            error={errors.logo}
                            onChange={this.handleChange}
                        />
                        <MediaField
                            name="background"
                            label="OPTIONS_BACKGROUND"
                            mediaType={MediaType.IMAGE}
                            mediaPath="clientes"
                            value={data.background}
                            error={errors.background}
                            onChange={this.handleChange}
                        />
                        <MediaField
                            name="codeImage"
                            label="OPTIONS_CODE_IMAGE"
                            mediaType={MediaType.IMAGE}
                            mediaPath="clientes"
                            value={data.codeImage}
                            error={errors.codeImage}
                            onChange={this.handleChange}
                        />
                        <InputField
                            type="color"
                            name="fontColor"
                            label="OPTIONS_FONT_COLOR"
                            value={data.fontColor}
                            error={errors.fontColor}
                            onChange={this.handleChange}
                        />
                        <InputField
                            type="color"
                            name="buttonColor"
                            label="OPTIONS_BUTTON_COLOR"
                            value={data.buttonColor}
                            error={errors.buttonColor}
                            onChange={this.handleChange}
                        />
                        <InputField
                            type="color"
                            name="buttonFont"
                            label="OPTIONS_BUTTON_COLOR"
                            value={data.buttonFont}
                            error={errors.buttonFont}
                            onChange={this.handleChange}
                        />
                    </Columns>
                    <Columns>
                        <InputField
                            type="toggle"
                            name="showCents"
                            label="OPTIONS_SHOW_CENTS"
                            value={data.showCents}
                            error={errors.showCents}
                            onChange={this.handleChange}
                        />
                        <InputField
                            type="toggle"
                            name="showSizes"
                            label="OPTIONS_SHOW_SIZES"
                            value={data.showSizes}
                            error={errors.showSizes}
                            onChange={this.handleChange}
                        />
                    </Columns>

                    <h3>{NLS.get("OPTIONS_TABLES")}</h3>
                    <Columns>
                        <InputField
                            type="number"
                            name="actionInterval"
                            label="OPTIONS_ACTION_INTERVAL"
                            value={data.actionInterval}
                            error={errors.actionInterval}
                            onChange={this.handleChange}
                        />
                        <InputField
                            type="number"
                            name="requestInterval"
                            label="OPTIONS_REQUEST_INTERVAL"
                            value={data.requestInterval}
                            error={errors.requestInterval}
                            onChange={this.handleChange}
                        />
                        <InputField
                            type="number"
                            name="requestTimeout"
                            label="OPTIONS_REQUEST_TIMEOUT"
                            value={data.requestTimeout}
                            error={errors.requestTimeout}
                            onChange={this.handleChange}
                        />
                    </Columns>
                    <Columns>
                        <InputField
                            type="toggle"
                            name="requestGoBack"
                            label="OPTIONS_REQUEST_GOBACK"
                            value={data.requestGoBack}
                            error={errors.requestGoBack}
                            onChange={this.handleChange}
                        />
                        <InputField
                            type="toggle"
                            name="usesSemaphore"
                            label="OPTIONS_USES_SEMAPHORE"
                            value={data.usesSemaphore}
                            error={errors.usesSemaphore}
                            onChange={this.handleChange}
                        />
                        <InputField
                            type="toggle"
                            name="smallGrid"
                            label="OPTIONS_SMALL_GRID"
                            value={data.smallGrid}
                            error={errors.smallGrid}
                            onChange={this.handleChange}
                        />
                        <InputField
                            type="toggle"
                            name="usesGrid"
                            label="OPTIONS_USES_GRID"
                            value={data.usesGrid}
                            error={errors.usesGrid}
                            onChange={this.handleChange}
                        />
                    </Columns>

                    <h3>{NLS.get("OPTIONS_ORDERS")}</h3>
                    <Columns>
                        <InputField
                            type="time"
                            name="resetOrderNum"
                            label="OPTIONS_RESET_ORDER_NUM"
                            value={data.resetOrderNum}
                            error={errors.resetOrderNum}
                            onChange={this.handleChange}
                        />
                        <InputField
                            type="toggle"
                            name="cancelOrders"
                            label="OPTIONS_CANCEL_ORDERS"
                            value={data.cancelOrders}
                            error={errors.cancelOrders}
                            onChange={this.handleChange}
                        />
                    </Columns>

                    <h3>{NLS.get("OPTIONS_PAYMENTS")}</h3>
                    <Columns>
                        <InputField
                            type="toggle"
                            name="cashPayment"
                            label="OPTIONS_CASH_PAYMENT"
                            value={data.cashPayment}
                            error={errors.cashPayment}
                            onChange={this.handleChange}
                        />
                        <InputField
                            type="toggle"
                            name="debitPayment"
                            label="OPTIONS_DEBIT_PAYMENT"
                            value={data.debitPayment}
                            error={errors.debitPayment}
                            onChange={this.handleChange}
                        />
                        <InputField
                            type="toggle"
                            name="creditPayment"
                            label="OPTIONS_CREDIT_PAYMENT"
                            value={data.creditPayment}
                            error={errors.creditPayment}
                            onChange={this.handleChange}
                        />
                        <InputField
                            type="toggle"
                            name="mpPayment"
                            label="OPTIONS_MP_PAYMENT"
                            value={data.mpPayment}
                            error={errors.mpPayment}
                            onChange={this.handleChange}
                        />
                    </Columns>
                </div>
                <div className="options-bottom">
                    <Button
                        variant="primary"
                        className="options-button"
                        message="GENERAL_SAVE"
                        onClick={this.handleSubmit}
                        isDisabled={loading}
                    />
                </div>
            </Form>}
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchOptions : PropTypes.func.isRequired,
        editOptions  : PropTypes.func.isRequired,
        elem         : PropTypes.object.isRequired,
        params       : PropTypes.object.isRequired,
        route        : PropTypes.string.isRequired,
        withDetails  : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            elem : state.options.elem,
        };
    }
}

export default connect(OptionsPage.mapStateToProps, {
    fetchOptions, editOptions,
})(OptionsPage);
