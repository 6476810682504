import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Ticket }           from "Utils/API";

// Types
import {
    TICKET, TICKET_LOADING,
    TICKET_LIST, TICKET_ELEM,
    TICKET_EDIT, TICKET_ASSIGN,
} from "Utils/Types";



/**
 * Fetches the Ticket List
 * @param {String=}  type
 * @param {Number=}  elemID
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTickets(type = "all", elemID = 0, params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : TICKET_LOADING });
        }
        Params.unset(params);
        let data = {};

        switch (type) {
        case "CLIENT":
            params.clientID = elemID;
            data = await Ticket.getAllForClient(params);
            break;
        case "MANAGER":
            data = await Ticket.getAllForManager(params);
            break;
        default:
            data = await Ticket.getAll(params);
        }
        data.sort = params;
        dispatch({ type : TICKET_LIST, data });
        dispatch(setSection(TICKET));
    };
}

/**
 * Fetches a single Ticket
 * @param {Number} ticketID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTicket(ticketID) {
    return async (dispatch) => {
        const data = await Ticket.getOne({ ticketID });
        dispatch({ type : TICKET_ELEM, data });
    };
}

/**
 * Fetches the Ticket Edit data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTicketEdit() {
    return async (dispatch) => {
        const data = await Ticket.getEditData();
        dispatch({ type : TICKET_EDIT, data });
    };
}

/**
 * Fetches the Ticket Edit data
 * @param {Number} ticketID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTicketAssign(ticketID) {
    return async (dispatch) => {
        const data = await Ticket.getAssignData({ ticketID });
        dispatch({ type : TICKET_ASSIGN, data });
    };
}



/**
 * Creates a Ticket
 * @param {Object} data
 * @returns {() => Void}
 */
export function createTicket(data) {
    return () => Ticket.create(data);
}

/**
 * Edits a Ticket
 * @param {Object} data
 * @returns {() => Void}
 */
export function editTicket(data) {
    return () => Ticket.edit(data);
}

/**
 * Deletes a Ticket
 * @param {Number} ticketID
 * @returns {() => Void}
 */
export function deleteTicket(ticketID) {
    return () => Ticket.delete({ ticketID });
}



/**
 * Assigns a Ticket
 * @param {Object} data
 * @returns {() => Void}
 */
export function assignTicket(data) {
    return () => Ticket.assign(data);
}

/**
 * Opens a Ticket
 * @param {Number} ticketID
 * @returns {() => Void}
 */
export function openTicket(ticketID) {
    return () => Ticket.open({ ticketID });
}

/**
 * Closes a Ticket
 * @param {Number} ticketID
 * @returns {() => Void}
 */
export function closeTicket(ticketID) {
    return () => Ticket.close({ ticketID });
}

/**
 * Mutes a Ticket
 * @param {Number} ticketID
 * @returns {() => Void}
 */
export function muteTicket(ticketID) {
    return () => Ticket.mute({ ticketID });
}

/**
 * Unmutes a Ticket
 * @param {Number} ticketID
 * @returns {() => Void}
 */
export function unmuteTicket(ticketID) {
    return () => Ticket.unmute({ ticketID });
}
