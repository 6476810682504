import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "dashboard/dist/Core/NLS";



/**
 * The Actions List
 */
class ActionList extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { actions, onView } = this.props;
        
        return <div className="dashboard-actions dashboard-list darker-scrollbars">
            <h3>{NLS.get("DASHBOARD_ACTIVITY")}</h3>
            {actions.map((elem, index) => <div
                key={index}
                className="dashboard-action"
                onClick={() => onView(elem.servingID)}
            >
                <span className="dashboard-action-name">{elem.actionName}</span>
                <span className="dashboard-action-desc">{elem.actionDesc}</span>
                <span className="dashboard-action-time">{elem.time}</span>
            </div>)}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        actions : PropTypes.array.isRequired,
        onView  : PropTypes.func.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            actions : state.dashboard.actions,
        };
    }
}

export default connect(ActionList.mapStateToProps)(ActionList);
