import React                from "react";
import PropTypes            from "prop-types";

// Sections
import TicketTypeList       from "Components/App/Ticket/TicketType/TicketTypeList";
import AdminList            from "Components/App/Setup/Admin/AdminList";
import PlanList             from "Components/App/Setup/Plan/PlanList";
import EmailTemplateList    from "Components/App/Setup/EmailTemplate/EmailTemplateList";
import ErrorLogList         from "Components/App/Setup/ErrorLog/ErrorLogList";
import ActionLogList        from "Components/App/Setup/ActionLogList";

// Components
import SecondaryNav         from "Components/Utils/Side/SecondaryNav";
import AdminRoute           from "Components/Utils/Route/AdminRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationList       from "dashboard/dist/Components/NavigationList";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";



/**
 * The Setup Container
 */
class SetupContainer extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { match, route } = this.props;
        const { path, url    } = match;

        return <>
            <SecondaryNav message="SETTINGS_NAME" path={route} baseUrl={url}>
                <NavigationList>
                    <NavigationItem message="ADMINS_NAME"          url="ADMINS"          icon="admin"   />
                    <NavigationItem message="PLANS_NAME"           url="PLANS"           icon="plan"    />
                    <NavigationItem message="TICKET_TYPES_NAME"    url="TICKET_TYPES"    icon="type"    />
                </NavigationList>
                <NavigationList>
                    <NavigationItem message="EMAIL_TEMPLATES_NAME" url="EMAIL_TEMPLATES" icon="email"   />
                    <NavigationItem message="ACTIONS_LOG_NAME"     url="ACTIONS"         icon="actions" />
                    <NavigationItem message="ERRORS_LOG_NAME"      url="ERRORS"          icon="error"   />
                </NavigationList>
            </SecondaryNav>
            <SwitchRoute type="SETUP" path={path} baseUrl={url}>
                <AdminRoute url="ADMINS"          component={AdminList}         />
                <AdminRoute url="PLANS"           component={PlanList}          />
                <AdminRoute url="TICKET_TYPES"    component={TicketTypeList}    />
                <AdminRoute url="EMAIL_TEMPLATES" component={EmailTemplateList} />
                <AdminRoute url="ACTIONS"         component={ActionLogList}     />
                <AdminRoute url="ERRORS"          component={ErrorLogList}      />
            </SwitchRoute>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        match : PropTypes.object.isRequired,
        route : PropTypes.string.isRequired,
    }
}

export default SetupContainer;
