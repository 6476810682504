export default {
    
    // ID to Section
    SECTIONS : {
        "1"  : "Sesión",
        "2"  : "Media",
        "3"  : "Perfil",

        "4"  : "Cliente",
        "5"  : "Usuario",
        "14" : "Tabla",
        "15" : "Código",
        "17" : "Opciones",
        
        "13" : "Producto",
        "11" : "Categoría",
        "12" : "Subcategoría",
        "20" : "Opcional",
        "22" : "Opción",
        "23" : "Tamaños",

        "25" : "Pedidos",

        "16" : "Ticket",
        "19" : "Mensaje de Ticket",
        "18" : "Tipo de Ticket",

        "8"  : "Administrador",
        "24" : "Plan",
        "10" : "Template de Email",
        "21" : "Error",
    },
    
    
    
    // ID to Action
    ACTIONS : {
        "11"  : "Inicio de sesión",
        "17"  : "Inicio como usuario",
        "13"  : "Cierre de sesión",
        "18"  : "Cierre como usuario",
        "14"  : "Recupero de contraseña",
        "15"  : "Contraseña reseteada",
        "16"  : "Contraseña cambiada",

        "21"  : "Directorio creado",
        "22"  : "Archivo subido",
        "23"  : "Archivo renombrado",
        "24"  : "Archivo eliminado",

        "31"  : "Cuenta editada",
        "32"  : "Avatar subido",


        "41"  : "Cliente creado",
        "42"  : "Cliente editado",
        "43"  : "Cliente eliminado",
        "44"  : "Cliente marcado como pagado",

        "51"  : "Usuario creado",
        "52"  : "Usuario editado",
        "53"  : "Usuario eliminado",

        "141" : "Tabla creada",
        "142" : "Tabla editada",
        "143" : "Tabla eliminada",
        "144" : "Tablas generadas",

        "151" : "Código creado",
        "152" : "Código editado",
        "153" : "Código eliminado",

        "172" : "Opciones editadas",


        "131" : "Producto creado",
        "132" : "Producto editado",
        "134" : "Producto precio editado",
        "133" : "Producto eliminado",

        "111" : "Categoría creada",
        "112" : "Categoría editada",
        "113" : "Categoría eliminada",

        "121" : "Subcategoría creada",
        "122" : "Subcategoría editada",
        "123" : "Subcategoría eliminada",

        "201" : "Opcional creado",
        "202" : "Opcional editado",
        "203" : "Opcional eliminado",

        "221" : "Opción agregada",
        "222" : "Opción editada",
        "223" : "Opción eliminada",

        "231" : "Tamaño creado",
        "232" : "Tamaño editado",
        "233" : "Tamaño eliminado",


        "251" : "Pedido confirmado",
        "252" : "Pedido rechazado",
        "253" : "Pedido completado",


        "161" : "Ticket creado",
        "162" : "Ticket editado",
        "163" : "Ticket eliminado",
        "168" : "Ticket asignado",
        "164" : "Ticket abierto",
        "165" : "Ticket cerrado",
        "166" : "Ticket sileciado",
        "167" : "Ticket activado",

        "191" : "Mensaje agregado",
        "192" : "Mensaje editado",
        "193" : "Mensaje eliminado",

        "181" : "Tipo de ticket agregado",
        "182" : "Tipo de ticket editado",
        "183" : "Tipo de ticket eliminado",


        "81"  : "Administrador creado",
        "82"  : "Administrador editado",
        "83"  : "Administrador eliminado",

        "241" : "Plan creado",
        "242" : "Plan editado",
        "243" : "Plan eliminado",

        "101" : "Template de email editado",
        
        "211" : "Error resuelto",
    },
};
