import CoreAjax             from "dashboard/dist/Core/Ajax";
import CoreAuth             from "dashboard/dist/Core/Auth";
import { Auth }             from "Utils/API";

// Types
import {
    AUTH_CURRENT_USER, AUTH_CHANGE_EMAIL,
} from "Utils/Types";



/**
 * Sets the Current Credential
 * @param {Object} credential
 * @returns {{type: String, credential: Object}}
 */
export function setCurrentUser(credential) {
    return { type : AUTH_CURRENT_USER, credential };
}



/**
 * Logs In the User
 * @param {Object} data
 * @returns {(dispatch: Function) => Void}
 */
export function login(data) {
    return async (dispatch) => {
        const result = await Auth.login(data);
        if (result && result.reqPassChange) {
            dispatch({ type : AUTH_CHANGE_EMAIL, email : result.email });
        }
        return result;
    };
}

/**
 * Signs Up the User
 * @param {Object} data
 * @returns {() => Void}
 */
export function signup(data) {
    return () => Auth.signup(data);
}



/**
 * Requests a Password Reset
 * @param {Object} data
 * @returns {() => Void}
 */
export function requestReset(data) {
    return () => Auth.requestReset(data);
}

/**
 * Verifies a Password Reset
 * @param {Object} data
 * @returns {() => Void}
 */
export function verifyReset(data) {
    return () => Auth.verifyReset(data);
}

/**
 * Resets a Password
 * @param {Object} data
 * @returns {() => Void}
 */
export function resetPass(data) {
    return () => Auth.resetPass(data);
}

/**
 * Changes the User's Password
 * @param {Object} data
 * @returns {() => Void}
 */
export function changePass(data) {
    return () => Auth.changePass(data);
}



/**
 * Logouts the User
 * @returns {() => Void}
 */
export function logout() {
    return () => {
        const user = CoreAuth.getUser();
        CoreAjax.abort();
        if (user.loggedAsUser) {
            Auth.logoutAs();
        } else {
            Auth.logout();
            CoreAuth.unsetToken();
        }
    };
}

/**
 * Logins as an User
 * @param {Number} credentialID
 * @returns {() => Void}
 */
export function loginAs(credentialID) {
    return () => Auth.loginAs({ credentialID });
}
