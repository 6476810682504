import { setSection }       from "Actions/Core/CoreActions";
import { Plan }             from "Utils/API";

// Types
import {
    PLAN, PLAN_LOADING,
    PLAN_LIST, PLAN_ELEM, PLAN_EDIT,
} from "Utils/Types";



/**
 * Fetches the Plan List
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchPlans(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : PLAN_LOADING });
        }
        const data = await Plan.getAll(params);
        data.sort = params;
        dispatch({ type : PLAN_LIST, data });
        dispatch(setSection(PLAN));
    };
}

/**
 * Fetches a single Plan
 * @param {Number} planID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchPlan(planID) {
    return async (dispatch) => {
        const data = await Plan.getOne({ planID });
        dispatch({ type : PLAN_ELEM, data });
    };
}

/**
 * Fetches the Plan Edit data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchPlanEdit() {
    return async (dispatch) => {
        const data = await Plan.getEditData();
        dispatch({ type : PLAN_EDIT, data });
    };
}



/**
 * Edits/Creates a Plan
 * @param {Object} data
 * @returns {() => Void}
 */
export function editPlan(data) {
    return () => Plan.edit(data);
}

/**
 * Deletes a Plan
 * @param {Number} planID
 * @returns {() => Void}
 */
export function deletePlan(planID) {
    return () => Plan.delete({ planID });
}
