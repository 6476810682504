import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { login }            from "Actions/Core/AuthActions";
import Href                 from "dashboard/dist/Core/Href";

// Components
import AuthForm             from "Components/Auth/AuthForm";
import IconField            from "dashboard/dist/Components/IconField";



/**
 * The Login Page
 */
class LoginPage extends React.Component {
    // The Current State
    state = {
        data : {
            email    : "",
            password : "",
        },
        loading : false,
        errors  : {},
    }
    
    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { redirect, login } = this.props;
        const { data, loading   } = this.state;
        
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const result = await login(data);
                if (result.success && result.reqPassChange) {
                    Href.goto("PASS_CHANGE");
                } else {
                    Href.goto(redirect);
                }
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, loading, errors } = this.state;
        
        return <AuthForm
            onSubmit={this.handleSubmit}
            title="LOGIN_TITLE"
            error={errors.form}
            url="PASS_RECOVER"
            link="LOGIN_RECOVER"
            button="LOGIN_BUTTON"
            isLoading={loading}
        >
            <IconField
                className="auth-field"
                type="email"
                name="email"
                icon="email"
                placeholder="GENERAL_EMAIL"
                autoComplete="username"
                value={data.email}
                error={errors.email}
                onChange={this.handleChange}
                isRequired
            />
            <IconField
                className="auth-field"
                type="password"
                name="password"
                icon="password"
                placeholder="USERS_PASSWORD"
                autoComplete="new-password"
                value={data.password}
                error={errors.password}
                onChange={this.handleChange}
                isRequired
            />
        </AuthForm>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        login    : PropTypes.func.isRequired,
        redirect : PropTypes.string.isRequired,
    }
    
    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            redirect : state.core.redirect,
        };
    }
}

export default connect(LoginPage.mapStateToProps, {
    login,
})(LoginPage);
