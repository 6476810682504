import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchAdmin, editAdmin,
} from "Actions/App/Setup/AdminActions";
import Access from "dashboard/dist/Core/Access";



/**
 * The Admin Edit Dialog
 */
class AdminEdit extends React.Component {
    // The Initial Data
    initialData = {
        credentialID    : 0,
        level           : 0,
        firstName       : "",
        lastName        : "",
        phone           : "",
        email           : "",
        dni             : "",
        cuit            : "",
        address         : "",
        birthDate       : "",
        password        : "",
        confirmPassword : "",
        language        : "es",
        status          : Status.getID("ACTIVE"),
        sendEmails      : 1,
        sendTickets     : 1,
    }

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : false,
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, fetchAdmin } = this.props;
        let loading = false;
        let data    = null;
        
        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchAdmin(elemID);
                loading = true;
            }
    
        // Data Updated
        } else if (prevProps.edition !== edition && elemID) {
            data = Utils.extend(this.initialData, elem);
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editAdmin(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose, isOwner } = this.props;
        const { data, loading, errors          } = this.state;

        return <EditDialog
            open={open}
            icon="admin"
            title={!!elemID ? "ADMINS_EDIT_TITLE" : "ADMINS_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                type="select"
                name="level"
                label="USERS_LEVEL"
                value={data.level}
                error={errors.level}
                options={Access.getSelect(isOwner ? "ADMIN" : "SUPPORT")}
                onChange={this.handleChange}
                withNone
                isRequired
            />
            <Columns>
                <InputField
                    name="firstName"
                    label="USERS_FIRST_NAME"
                    value={data.firstName}
                    error={errors.firstName}
                    onChange={this.handleChange}
                    isRequired
                />
                <InputField
                    name="lastName"
                    label="USERS_LAST_NAME"
                    value={data.lastName}
                    error={errors.lastName}
                    onChange={this.handleChange}
                    isRequired
                />
                
                <InputField
                    type="email"
                    name="email"
                    label="GENERAL_EMAIL"
                    value={data.email}
                    error={errors.email}
                    onChange={this.handleChange}
                    autoComplete="new-password"
                    isRequired
                />
                <InputField
                    name="phone"
                    label="USERS_PHONE"
                    value={data.phone}
                    error={errors.phone}
                    onChange={this.handleChange}
                />
            
                <InputField
                    name="dni"
                    label="USERS_DNI"
                    value={data.dni}
                    error={errors.dni}
                    onChange={this.handleChange}
                />
                <InputField
                    name="cuit"
                    label="USERS_CUIT"
                    value={data.cuit}
                    error={errors.cuit}
                    onChange={this.handleChange}
                />

                <InputField
                    name="address"
                    label="USERS_ADRRESS"
                    value={data.address}
                    error={errors.address}
                    onChange={this.handleChange}
                />
                <InputField
                    type="date"
                    name="birthDate"
                    label="USERS_BIRTHDATE"
                    value={data.birthDate}
                    error={errors.birthDate}
                    onChange={this.handleChange}
                />
                
                <InputField
                    type="password"
                    name="password"
                    label={!!elemID ? "USERS_NEW_PASSWORD" : "USERS_PASSWORD"}
                    value={data.password}
                    error={errors.password}
                    onChange={this.handleChange}
                    autoComplete="new-password"
                    isRequired={!elemID}
                />
                <InputField
                    type="password"
                    name="confirmPassword"
                    label="USERS_PASSWORD_CONFIRM"
                    value={data.confirmPassword}
                    error={errors.confirmPassword}
                    onChange={this.handleChange}
                    autoComplete="new-password"
                    isRequired={!elemID}
                />

                <InputField
                    type="select"
                    name="language"
                    label="USERS_LANGUAGE"
                    options="LANGUAGES"
                    value={data.language}
                    error={errors.language}
                    onChange={this.handleChange}
                    withNone
                    isRequired
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    value={data.status}
                    error={errors.status}
                    onChange={this.handleChange}
                    options={Status.getSelect()}
                    withNone
                    isRequired
                    shrink
                />

                <InputField
                    type="toggle"
                    name="sendEmails"
                    label="USERS_SEND_EMAILS"
                    value={data.sendEmails}
                    onChange={this.handleChange}
                />
                <InputField
                    type="toggle"
                    name="sendTickets"
                    label="USERS_SEND_TICKETS"
                    value={data.sendTickets}
                    onChange={this.handleChange}
                />
            </Columns>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchAdmin : PropTypes.func.isRequired,
        editAdmin  : PropTypes.func.isRequired,
        open       : PropTypes.bool.isRequired,
        onClose    : PropTypes.func.isRequired,
        onSubmit   : PropTypes.func.isRequired,
        isOwner    : PropTypes.bool.isRequired,
        edition    : PropTypes.number.isRequired,
        elem       : PropTypes.object.isRequired,
        elemID     : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isOwner : state.auth.isOwner,
            edition : state.admin.edition,
            elem    : state.admin.elem,
        };
    }
}

export default connect(AdminEdit.mapStateToProps, {
    fetchAdmin, editAdmin,
})(AdminEdit);
