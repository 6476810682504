export default {

    TITLE                           : "QaRta",
    LANGUAGES                       : { "es" : "Español", "en" : "English" },



    // General
    GENERAL_ADD                     : "Agregar",
    GENERAL_ADD_FIELD               : "Agregar campo",
    GENERAL_ACCEPT                  : "Aceptar",
    GENERAL_ACCESS                  : "Acceder",
    GENERAL_BIRTHDATE               : "Cumpleaños",
    GENERAL_CANCEL                  : "Cancelar",
    GENERAL_CLOSE                   : "Cerrar",
    GENERAL_CODE                    : "Código",
    GENERAL_COLUMN                  : "Columna",
    GENERAL_CREATE                  : "Crear",
    GENERAL_CREATED                 : "Creado el",
    GENERAL_CREATED_FEM             : "Creada el",
    GENERAL_CREATED_TIME            : "Fecha de creación",
    GENERAL_CREATOR                 : "Creado por",
    GENERAL_CREATOR_FEM             : "Creada por",
    GENERAL_CUIT                    : "CUIT",
    GENERAL_DAY                     : "Día",
    GENERAL_DELETE                  : "Eliminar",
    GENERAL_DESCRIPTION             : "Descripción",
    GENERAL_DNI                     : "DNI",
    GENERAL_EDIT                    : "Editar",
    GENERAL_EMAIL                   : "Email",
    GENERAL_END                     : "Cierre",
    GENERAL_EXPORT                  : "Exportar",
    GENERAL_GENERATE                : "Generar",
    GENERAL_FILE                    : "Archivo",
    GENERAL_FILTER                  : "Filtrar",
    GENERAL_FROM                    : "Desde",
    GENERAL_FROM_DATE               : "Fecha desde",
    GENERAL_FROM_HOUR               : "Hora de inicio",
    GENERAL_HASH                    : "Hash",
    GENERAL_ID                      : "ID",
    GENERAL_IMPORT                  : "Importar",
    GENERAL_INFO                    : "Información",
    GENERAL_IVA_CATEGORY            : "Categoría de IVA",
    GENERAL_IVA_CATEGORIES          : { 1 : "Responsable Monotributo", 2 : "Responsable Inscripto", 3 : "Consumidor Final", 4 : "Exento" },
    GENERAL_LANGUAGE                : "Idioma",
    GENERAL_LEVEL                   : "Nivel",
    GENERAL_LOADING                 : "Cargando",
    GENERAL_LOGIN_AS                : "Ingresar como usuario",
    GENERAL_LOGIN_TIME              : "Último ingreso",
    GENERAL_LOGOUT                  : "Salir",
    GENERAL_LOGGED_OUT              : "La sesión ha terminado",
    GENERAL_NAME                    : "Nombre",
    GENERAL_NO                      : "No",
    GENERAL_NOBODY                  : "Nadie",
    GENERAL_OBSERVATIONS            : "Observaciones",
    GENERAL_PAGE_OF                 : "{0}-{1} de {2}",
    GENERAL_PAYMENT                 : "Pago",
    GENERAL_POSITION                : "Posición",
    GENERAL_PRICE                   : "Precio",
    GENERAL_ROW                     : "Fila",
    GENERAL_ROWS_PER_PAGE           : "Filas a mostrar: ",
    GENERAL_SAVE                    : "Guardar",
    GENERAL_SEARCH                  : "Buscar",
    GENERAL_SEARCH_ONE              : "Buscar ...",
    GENERAL_SEARCH_RESULTS          : "Resultados",
    GENERAL_SELECT                  : "Seleccionar",
    GENERAL_SELECT_FILE             : "Seleccionar archivo ...",
    GENERAL_SHORT_NAME              : "Nombre corto",
    GENERAL_START                   : "Inicio",
    GENERAL_STATUS                  : "Estado",
    GENERAL_SUBJECT                 : "Asunto",
    GENERAL_TIME                    : "Tiempo",
    GENERAL_TO                      : "Hasta",
    GENERAL_TO_DATE                 : "Fecha hasta",
    GENERAL_TO_HOUR                 : "Hora de cierre",
    GENERAL_TYPE                    : "Tipo",
    GENERAL_VIEW                    : "Ver",
    GENERAL_YES                     : "Si",

    GENERAL_ERROR                   : "Ocurrió un error inesperado. Estamos trabajando en resolverlo.",
    GENERAL_ERROR_PATH              : "Ruta incorrecta.",
    GENERAL_ERROR_DATE              : "La fecha es incorrecta.",
    GENERAL_ERROR_FUTURE            : "La fecha debe ser posterior al día actual.",
    GENERAL_ERROR_FILE              : "El archivo indicado no existe.",
    GENERAL_ERROR_HOUR              : "La hora indicada es incorrecta.",
    GENERAL_ERROR_WEEKDAY           : "El día de la semana es incorrecto.",
    GENERAL_ERROR_PROVINCE          : "La provincia indicada es incorrecta.",
    GENERAL_ERROR_POSITION          : "La posición debe ser un número mayor a 0.",
    GENERAL_ERROR_PRICE             : "El precio debe ser un número mayor o igual a 0.",
    GENERAL_ERROR_LANGUAGE          : "El idioma indicado es incorrecto.",
    GENERAL_ERROR_STATUS            : "El estado indicado es incorrecto.",



    // Auth: Login
    LOGIN_TITLE                     : "Ingresar",
    LOGIN_RECOVER                   : "¿Recuperar contraseña?",
    LOGIN_BUTTON                    : "Ingresar",
    LOGIN_ERROR_SPAM                : "Ha intentado demasiadas veces.",
    LOGIN_ERROR_CREDENTIALS         : "El email o la contraseña es incorrecto.",
    LOGIN_ERROR_NOT_ALLOWED         : "No puede ingresar al sistema.",
    LOGIN_ERROR_DISABLED            : "El ingreso esta deshabilitado.",
    LOGIN_SUCCESS                   : "Bienvenido a QaRta",

    // Auth: Recover Password
    RECOVER_TITLE                   : "Recuperar contraseña",
    RECOVER_HELP                    : "Use el siguiente formulario para recuperar su contraseña. Un mail será enviado a la dirección indicada con instrucciones acerca de como completar el proceso.",
    RECOVER_ACCOUNT                 : "Ingresar con su cuenta",
    RECOVER_BUTTON                  : "Enviar Email",
    RECOVER_SUCCESS                 : "Se ha enviado el email correctamente.",

    // Auth: Code
    CODE_TITLE                      : "Completar código",
    CODE_HELP                       : "Se ha enviado un mail a la dirección de mail indicada con un vínculo que debe hacer click para poder generar una nueva contraseña para su cuenta, o copiar y pegar el código a continuación.",
    CODE_ACCOUNT                    : "Ingresar con su cuenta",
    CODE_BUTTON                     : "Verificar código",
    CODE_ERROR_EMPTY                : "Debe indicar el código.",
    CODE_ERROR_INVALID              : "El código indicado es incorrecto o ha expirado.",
    CODE_SUCCESS                    : "El código se ha verificado correctamente.",

    // Auth: Reset Password
    RESET_TITLE                     : "Resetear contraseña",
    RESET_HELP                      : "Ingrese la contraseña que desea utilizar para acceder a su cuenta.",
    RESET_ACCOUNT                   : "Ingresar con su cuenta",
    RESET_BUTTON                    : "Resetear",
    RESET_ERROR_CODE                : "Debe proveer un código.",
    RESET_ERROR_INVALID             : "El código indicado es incorrecto o ha expirado.",
    RESET_ERROR_PASSWORD            : "Debe indicar la nueva cntraseña.",
    RESET_ERROR_CONFIRM             : "Debe confirmar la nueva cntraseña.",
    RESET_ERROR_MATCH               : "Las contraseñas no coinciden.",
    RESET_SUCCESS                   : "Su contraseña fue reseteada correctamente.",

    // Auth: Change Password
    CHANGE_TITLE                    : "Cambiar contraseña",
    CHANGE_HELP                     : "Debe cambiar su contraseña por una nueva.",
    CHANGE_OLD_PASSWORD             : "Contraseña actual",
    CHANGE_NEW_PASSWORD             : "Nueva contraseña",
    CHANGE_BUTTON                   : "Cambiar contraseña",
    CHANGE_ERROR_INVALID            : "El email indicado es incorrecto o no requiere cambios.",
    CHANGE_ERROR_OLD                : "Debe indicar su contraseña actual.",
    CHANGE_ERROR_NEW                : "Debe indicar la nueva contraseña.",
    CHANGE_ERROR_CONFIRM            : "Debe confirmar la nueva contraseña.",
    CHANGE_ERROR_PASSWORD           : "La contraseña actual es incorrecta.",
    CHANGE_ERROR_DIFFERENT          : "La nueva contraseña debe ser diferente a la actual.",
    CHANGE_ERROR_MATCH              : "Las contraseñas no coinciden.",
    CHANGE_SUCCESS                  : "Su contraseña fue reseteada correctamente.",



    // Clients
    CLIENTS_NAME                    : "Clientes",
    CLIENTS_SINGULAR                : "Cliente",
    CLIENTS_DETAILS_TITLE           : "Detalles del cliente",
    CLIENTS_CREATE_TITLE            : "Crear cliente",
    CLIENTS_EDIT_TITLE              : "Editar cliente",
    CLIENTS_VIEW_TITLE              : "Ver cliente",
    CLIENTS_DELETE_TITLE            : "Eliminar cliente",
    CLIENTS_DELETE_TEXT             : "¿Realmente desea eliminar el cliente <b>{0}</b>?",
    CLIENTS_PAYMENT_TITLE           : "Marcar como pagado",
    CLIENTS_PAYMENT_TEXT            : "¿Realmente desea marcar el cliente <b>{0}</b> como pagado?",
    CLIENTS_ITS_NAME                : "Nombre del establecimiento",
    CLIENTS_CONTACT                 : "Contacto",
    CLIENTS_SOCIAL_REASON_SHORT     : "Razón social",
    CLIENTS_SOCIAL_REASON           : "Razón social del cliente",
    CLIENTS_DNI                     : "DNI del cliente",
    CLIENTS_CUIT                    : "CUIT del cliente",
    CLIENTS_FIRST_NAME              : "Nombre del contacto",
    CLIENTS_LAST_NAME               : "Apellido del contacto",
    CLIENTS_EMAIL                   : "Email administrativo",
    CLIENTS_PHONE                   : "Teléfono administrativo",
    CLIENTS_ADDRESS                 : "Dirección del establecimiento",
    CLIENTS_LOCALITY                : "Localidad / Barrio",
    CLIENTS_PROVINCE                : "Provincia",
    CLIENTS_ZIP_CODE                : "Código postal",
    CLIENTS_MAIN_PLAN               : "Plan principal",
    CLIENTS_PROMO_PLAN              : "Plan promocional",
    CLIENTS_PROMO_END               : "Fin de la promoción",
    CLIENTS_SERVICE_START           : "Inicio del servicio",
    CLIENTS_NEXT_PAYMENT            : "Próximo pago",
    CLIENTS_LANGUAGE                : "Idioma del cliente",
    CLIENTS_INFO                    : "Esta es la información de su establecimiento. Si quiere modificar algún dato puede solicitarlo con un pedido.",
    CLIENTS_NONE_AVAILABLE          : "No hay clientes disponibles.",
    CLIENTS_ERROR_EXISTS            : "No existe el cliente indicado.",
    CLIENTS_ERROR_NAME              : "Debe indicar el nombre del establecimiento.",
    CLIENTS_ERROR_IVA_EMPTY         : "Por favor, complete la categoría de IVA.",
    CLIENTS_ERROR_IVA_INVALID       : "La categoría de IVA indicada es inválida.",
    CLIENTS_ERROR_CUIT_EMPTY        : "Por favor, complete el CUIT.",
    CLIENTS_ERROR_CUIT_INVALID      : "El CUIT indicado es inválido.",
    CLIENTS_ERROR_CUIT_USED         : "El CUIT indicado esta usado por otro cliente.",
    CLIENTS_ERROR_DNI_EMPTY         : "Por favor, complete el DNI.",
    CLIENTS_ERROR_DNI_INVALID       : "El DNI indicado es inválido.",
    CLIENTS_ERROR_DNI_USED          : "El DNI indicado esta usado por otro cliente.",
    CLIENTS_ERROR_EMAIL_EMPTY       : "Debe indicar el email administrativo.",
    CLIENTS_ERROR_EMAIL_INVALID     : "El email indicado es inválido.",
    CLIENTS_ERROR_PROVINCE          : "La provincia indicada no existe.",
    CLIENTS_ERROR_PLAN              : "Debe indicar el plan principal.",
    CLIENTS_ERROR_PROMO_END         : "Debe indicar el fin de la promoción.",
    CLIENTS_ERROR_SERVICE_START     : "Debe indicar el inicio del servicio.",
    CLIENTS_ERROR_NEXT_PAYMENT      : "Debe indicar el próximo pago.",
    CLIENTS_ERROR_LANGUAGE          : "Debe indicar el idioma del cliente.",
    CLIENTS_ERROR_STATUS            : "Debe indicar el estado del cliente.",
    CLIENTS_SUCCESS_CREATE          : "El cliente se creó correctamente.",
    CLIENTS_SUCCESS_EDIT            : "El cliente se editó correctamente.",
    CLIENTS_SUCCESS_DELETE          : "El cliente se eliminó correctamente.",
    CLIENTS_SUCCESS_PAID            : "El cliente se marcó como pagado correctamente.",

    // Clients: Tables
    TABLES_NAME                     : "Mesas",
    TABLES_SINGULAR                 : "Mesa",
    TABLES_VIEW_TITLE               : "Ver mesa",
    TABLES_CREATE_TITLE             : "Crear mesa",
    TABLES_EDIT_TITLE               : "Editar mesa",
    TABLES_DELETE_TITLE             : "Eliminar mesa",
    TABLES_DELETE_TEXT              : "¿Realmente desea eliminar la mesa <b>{0}</b>?",
    TABLES_GENERATE_TITLE           : "Generar mesas",
    TABLES_NONE_AVAILABLE           : "No hay mesas disponibles.",
    TABLES_ITS_NAME                 : "Nombre de la mesa",
    TABLES_ROW                      : "Fila en la grilla",
    TABLES_COLUMN                   : "Columna en la grilla",
    TABLES_PREFIX                   : "Prefijo del nombre",
    TABLES_AMOUNT                   : "Cantidad de mesas a crear",
    TABLES_START                    : "Desde que número comenzar",
    TABLES_COLUMNS                  : "Cantidad de columnas",
    TABLES_START_ROW                : "Fila inicial",
    TABLES_START_COLUMN             : "Columna inicial",
    TABLES_FILL_ZEROS               : "Completar con ceros al comienzo",
    TABLES_ERROR_EXISTS             : "No existe la mesa indicada.",
    TABLES_ERROR_NAME               : "Debe indicar el nombre de la mesa.",
    TABLES_ERROR_ROW_EMPTY          : "Debe indicar la fila en la grilla.",
    TABLES_ERROR_ROW_INVALID        : "La fila debe ser un número mayor a 0.",
    TABLES_ERROR_COLUMN_EMPTY       : "Debe indicar la columna en la grilla.",
    TABLES_ERROR_COLUMN_INVALID     : "La columna debe ser un número mayor a 0.",
    TABLES_ERROR_STATUS             : "Debe indicar el estado de la mesa.",
    TABLES_ERROR_PREFIX             : "Debe indicar el prefijo del nombre.",
    TABLES_ERROR_AMOUNT_EMPTY       : "Debe indicar la cantidad de mesas a crear.",
    TABLES_ERROR_AMOUNT_INVALID     : "La cantidad de mesas a crear debe ser un número mayor a 0.",
    TABLES_ERROR_START              : "El número inicial ser un número mayor o igual a 0.",
    TABLES_ERROR_COLUMNS_EMPTY      : "Debe indicar la cantidad columnas.",
    TABLES_ERROR_COLUMNS_INVALID    : "La cantidad columnas debe ser un número mayor a 0.",
    TABLES_ERROR_START_ROW          : "Debe indicar una fila inicial como un número mayor a 0.",
    TABLES_ERROR_START_COLUMN       : "Debe indicar una columna inicial como un número mayor a 0.",
    TABLES_SUCCESS_CREATE           : "La mesa se creó correctamente.",
    TABLES_SUCCESS_EDIT             : "La mesa se editó correctamente.",
    TABLES_SUCCESS_DELETE           : "La mesa se eliminó correctamente.",
    TABLES_SUCCESS_GENERATE         : "Las mesas se generaron correctamente.",

    // Clients: Codes
    CODES_NAME                      : "Códigos",
    CODES_SINGULAR                  : "Código",
    CODES_VIEW_TITLE                : "Ver código",
    CODES_PRINT_TITLE               : "Imprimir código",
    CODES_NONE_AVAILABLE            : "No hay códigos disponibles.",
    CODES_FOR_MENU                  : "Código para menú",
    CODES_FOR_ORDER                 : "Código para pedido",
    CODES_PRINTS                    : "Impresiones",

    // Clients: Options
    OPTIONS_NAME                    : "Opciones",
    OPTIONS_GENERAL                 : "Opciones generales",
    OPTIONS_TABLES                  : "Opciones de mesas",
    OPTIONS_ORDERS                  : "Opciones de pedidos",
    OPTIONS_PAYMENTS                : "Opciones de formas de pago",
    OPTIONS_SLUG                    : "Url para la versión web",
    OPTIONS_WHATSAPP                : "WhatsApp para el menú (5491144445555)",
    OPTIONS_LOGO                    : "Logo para usar en la App",
    OPTIONS_BACKGROUND              : "Imagen de fondo de la App",
    OPTIONS_CODE_IMAGE              : "Imagen de fondo del código QR",
    OPTIONS_FONT_COLOR              : "Color del texto de la App",
    OPTIONS_BUTTON_COLOR            : "Color del fondo del boton",
    OPTIONS_BUTTON_FONT             : "Color del texto del boton",
    OPTIONS_SHOW_CENTS              : "Mostrar centavos en los precios",
    OPTIONS_SHOW_SIZES              : "Mostrar tamaños en el menú",
    OPTIONS_ACTION_INTERVAL         : "Tiempo entre las acciones (segundos)",
    OPTIONS_REQUEST_INTERVAL        : "Tiempo para volver a solicitar (segundos)",
    OPTIONS_REQUEST_TIMEOUT         : "Tiempo luego del cual se completa la solicitud (segundos)",
    OPTIONS_REQUEST_GOBACK          : "Volver al inicio luego de solicitar",
    OPTIONS_USES_SEMAPHORE          : "Permitir hasta 3 llamadas en las solicitudes",
    OPTIONS_SMALL_GRID              : "Mostrar las mesas pequeñas en el tablero",
    OPTIONS_USES_GRID               : "Usar una grilla para las mesas en el tablero",
    OPTIONS_RESET_ORDER_NUM         : "Horario de reseteo del número de pedido (diario)",
    OPTIONS_CANCEL_ORDERS           : "Cancelar pedidos al reseteor el número de pedido",
    OPTIONS_CASH_PAYMENT            : "Pago en efectivo",
    OPTIONS_DEBIT_PAYMENT           : "Pago con tarjeta de débito",
    OPTIONS_CREDIT_PAYMENT          : "Pago con tarjeta de crédito",
    OPTIONS_MP_PAYMENT              : "Pago con MercadoPago",
    OPTIONS_ERROR_URL               : "La url indicada esta siendo usada por otro cliente.",
    OPTIONS_SUCCESS_SAVE            : "Las opciones se guardarón correctamente.",

    // Clients: Payments
    PAYMENTS_NAME                   : "Pagos",
    PAYMENTS_SINGULAR               : "Pago",
    PAYMENTS_NONE_AVAILABLE         : "No hay pagos disponibles.",
    PAYMENTS_TIME                   : "Periodo",



    // Products
    PRODUCTS_NAME                   : "Productos",
    PRODUCTS_SINGULAR               : "Producto",
    PRODUCTS_VIEW_TITLE             : "Ver producto",
    PRODUCTS_CREATE_TITLE           : "Crear producto",
    PRODUCTS_EDIT_TITLE             : "Editar producto",
    PRODUCTS_DELETE_TITLE           : "Eliminar producto",
    PRODUCTS_DELETE_TEXT            : "¿Realmente desea eliminar el producto <b>{0}</b>?",
    PRODUCTS_NONE_AVAILABLE         : "No hay productos disponibles.",
    PRODUCTS_ITS_NAME               : "Nombre del producto",
    PRODUCTS_DESCRIPTION            : "Descripción del producto",
    PRODUCTS_BANNER                 : "Imágen del banner",
    PRODUCTS_CATEGORY               : "Categoría del producto",
    PRODUCTS_SUBCATEGORY            : "Subcategoría del producto",
    PRODUCTS_OPTIONALS              : "Opcionales del producto",
    PRODUCTS_ADD_OPTIONAL           : "Agregar opcional",
    PRODUCTS_HAS_SIZES              : "Tiene tamaños",
    PRODUCTS_PRICE                  : "Precio del producto",
    PRODUCTS_STATUS                 : "Estado del producto",
    PRODUCTS_ERROR_EXISTS           : "No existe el producto indicado.",
    PRODUCTS_ERROR_EDIT             : "No tiene permiso para editar el producto indicado.",
    PRODUCTS_ERROR_NAME             : "Debe indicar el nombre del producto.",
    PRODUCTS_ERROR_CATEGORY         : "Debe indicar la categoría del producto.",
    PRODUCTS_ERROR_SUBCATEGORY      : "Debe indicar la subcategoría del producto.",
    PRODUCTS_ERROR_PRICE            : "Debe indicar el precio del producto.",
    PRODUCTS_ERROR_OPTIONALS        : "Uno de los opcionales indicaos no existe.",
    PRODUCTS_ERROR_STATUS           : "Debe indicar el estado del producto.",
    PRODUCTS_SUCCESS_CREATE         : "El producto se creó correctamente.",
    PRODUCTS_SUCCESS_EDIT           : "El producto se editó correctamente.",
    PRODUCTS_SUCCESS_DELETE         : "El producto se eliminó correctamente.",

    // Products: Categories
    CATEGORIES_NAME                 : "Categorías",
    CATEGORIES_SINGULAR             : "Categoría",
    CATEGORIES_CREATE_TITLE         : "Crear categoría",
    CATEGORIES_EDIT_TITLE           : "Editar categoría",
    CATEGORIES_DELETE_TITLE         : "Eliminar categoría",
    CATEGORIES_DELETE_TEXT          : "¿Realmente desea eliminar la categoría <b>{0}</b>?",
    CATEGORIES_NONE_AVAILABLE       : "No hay categorías disponibles.",
    CATEGORIES_ITS_NAME             : "Nombre de la categoría",
    CATEGORIES_DESCRIPTION          : "Descripción de la categoría",
    CATEGORIES_ICON                 : "Imagen del ícono",
    CATEGORIES_BACKGROUND           : "Imagen del fondo",
    CATEGORIES_MARGIN_TOP           : "Margen superior",
    CATEGORIES_FONT_COLOR           : "Color de la letra",
    CATEGORIES_ERROR_EXISTS         : "No existe la categoría indicada.",
    CATEGORIES_ERROR_NAME           : "Debe indicar el nombre de la categoría.",
    CATEGORIES_ERROR_STATUS         : "Debe indicar el estado de la categoría.",
    CATEGORIES_SUCCESS_CREATE       : "La categoría se creó correctamente.",
    CATEGORIES_SUCCESS_EDIT         : "La categoría se editó correctamente.",
    CATEGORIES_SUCCESS_DELETE       : "La categoría se eliminó correctamente.",

    // Products: Subcategories
    SUBCATEGORIES_NAME              : "Subcategorías",
    SUBCATEGORIES_SINGULAR          : "Subcategoría",
    SUBCATEGORIES_CREATE_TITLE      : "Crear subcategoría",
    SUBCATEGORIES_EDIT_TITLE        : "Editar subcategoría",
    SUBCATEGORIES_DELETE_TITLE      : "Eliminar subcategoría",
    SUBCATEGORIES_DELETE_TEXT       : "¿Realmente desea eliminar la subcategoría <b>{0}</b>?",
    SUBCATEGORIES_NONE_AVAILABLE    : "No hay subcategorías disponibles.",
    SUBCATEGORIES_CATEGORY          : "Categoría de la subcategoría",
    SUBCATEGORIES_ITS_NAME          : "Nombre de la subcategoría",
    SUBCATEGORIES_DESCRIPTION       : "Descripción de la subcategoría",
    SUBCATEGORIES_ERROR_EXISTS      : "No existe la subcategoría indicada.",
    SUBCATEGORIES_ERROR_NAME        : "Debe indicar el nombre de la subcategoría.",
    SUBCATEGORIES_ERROR_CATEGORY    : "Debe indicar la categoría de la subcategoría.",
    SUBCATEGORIES_ERROR_STATUS      : "Debe indicar el estado de la subcategoría.",
    SUBCATEGORIES_SUCCESS_CREATE    : "La subcategoría se creó correctamente.",
    SUBCATEGORIES_SUCCESS_EDIT      : "La subcategoría se editó correctamente.",
    SUBCATEGORIES_SUCCESS_DELETE    : "La subcategoría se eliminó correctamente.",

    // Products: Optionals
    OPTIONALS_NAME                  : "Opcionales",
    OPTIONALS_SINGULAR              : "Opcional",
    OPTIONALS_VIEW_TITLE            : "Ver opcional",
    OPTIONALS_CREATE_TITLE          : "Crear opcional",
    OPTIONALS_EDIT_TITLE            : "Editar opcional",
    OPTIONALS_DELETE_TITLE          : "Eliminar opcional",
    OPTIONALS_DELETE_TEXT           : "¿Realmente desea eliminar el opcional <b>{0}</b>?",
    OPTIONALS_ADD_ITEM_TITLE        : "Agregar opción",
    OPTIONALS_EDIT_ITEM_TITLE       : "Editar opción",
    OPTIONALS_DELETE_ITEM_TITLE     : "Eliminar opción",
    OPTIONALS_DELETE_ITEM_TEXT      : "¿Realmente desea eliminar la opción <b>{0}</b>?",
    OPTIONALS_NONE_AVAILABLE        : "No hay opcionales disponibles.",
    OPTIONALS_NONE_ITEMS            : "No hay opciones disponibles.",
    OPTIONALS_ITS_NAME              : "Nombre descriptivo del opcional",
    OPTIONALS_SHORT_NAME            : "Nombre corto usado en la app",
    OPTIONALS_MENU_NAME             : "Nombre del menú",
    OPTIONALS_ITS_MENU_NAME         : "Nombre usado en en el menú de la app",
    OPTIONALS_MENU_NAME_TIP         : "Nombre que se usa en el texto \"Toca para ver ... disponibles\" en el menú de la app",
    OPTIONALS_MIN_AMOUNT            : "Mímina cantidad a seleccionar",
    OPTIONALS_MAX_AMOUNT            : "Máxima cantidad a seleccionar",
    OPTIONALS_IS_REQUIRED           : "Es requerido seleccionar al menos una opción",
    OPTIONALS_ITEM_NAME             : "Nombre de la opción",
    OPTIONALS_ITEM_DESCRIPTION      : "Descripción de la opción",
    OPTIONALS_ITEM_PRICE            : "Precio de la opción",
    OPTIONALS_ERROR_EXISTS          : "No existe el opcional indicado.",
    OPTIONALS_ERROR_EDIT            : "No tiene permiso para editar el opcional indicado.",
    OPTIONALS_ERROR_NAME            : "Debe indicar el nombre descriptivo.",
    OPTIONALS_ERROR_SHORT_NAME      : "Debe indicar el nombre corto.",
    OPTIONALS_ERROR_MENU_NAME       : "Debe indicar el nombre para el menú.",
    OPTIONALS_ERROR_MIN_AMOUNT      : "La cantidad mínima debe ser un número mayor o igual a cero.",
    OPTIONALS_ERROR_MAX_AMOUNT      : "La cantidad máxima debe ser un número mayor a cero.",
    OPTIONALS_ERROR_MIN_MAX         : "La cantidad máxima debe ser mayor o igual a la cantidad mínima.",
    OPTIONALS_ERROR_STATUS          : "Debe indicar el estado del opcional.",
    OPTIONALS_ERROR_ITEM_EXISTS     : "No existe la opción indicada.",
    OPTIONALS_ERROR_ITEM_NAME       : "Debe indicar el nombre de la opción.",
    OPTIONALS_ERROR_ITEM_STATUS     : "Debe indicar el estado de la opción.",
    OPTIONALS_SUCCESS_CREATE        : "El opcional se creó correctamente.",
    OPTIONALS_SUCCESS_EDIT          : "El opcional se editó correctamente.",
    OPTIONALS_SUCCESS_DELETE        : "El opcional se eliminó correctamente.",
    OPTIONALS_SUCCESS_ADD_ITEM      : "La opción se creó correctamente.",
    OPTIONALS_SUCCESS_EDIT_ITEM     : "La opción se editó correctamente.",
    OPTIONALS_SUCCESS_DELETE_ITEM   : "La opción se eliminó correctamente.",

    // Products: Sizes
    SIZES_NAME                      : "Tamaños",
    SIZES_SINGULAR                  : "Tamaño",
    SIZES_CREATE_TITLE              : "Crear tamaño",
    SIZES_EDIT_TITLE                : "Editar tamaño",
    SIZES_DELETE_TITLE              : "Eliminar tamaño",
    SIZES_DELETE_TEXT               : "¿Realmente desea eliminar el tamaño <b>{0}</b>?",
    SIZES_NONE_AVAILABLE            : "No hay tamaños disponibles.",
    SIZES_ITS_NAME                  : "Nombre del tamaño",
    SIZES_SHORT_NAME                : "Nombre corto usado en la app",
    SIZES_ERROR_EXISTS              : "No existe el tamaño indicada.",
    SIZES_ERROR_NAME                : "Debe indicar el nombre del tamaño.",
    SIZES_ERROR_SHORT_NAME          : "Debe indicar el nombre corto del tamaño.",
    SIZES_ERROR_STATUS              : "Debe indicar el estado del tamaño.",
    SIZES_SUCCESS_CREATE            : "El tamaño se creó correctamente.",
    SIZES_SUCCESS_EDIT              : "El tamaño se editó correctamente.",
    SIZES_SUCCESS_DELETE            : "El tamaño se eliminó correctamente.",



    // Tickets
    TICKETS_NAME                    : "Tickets",
    TICKETS_SINGULAR                : "Ticket",
    TICKETS_VIEW_TITLE              : "Ver ticket",
    TICKETS_CREATE_TITLE            : "Crear ticket",
    TICKETS_EDIT_TITLE              : "Editar ticket",
    TICKETS_DELETE_TITLE            : "Eliminar ticket",
    TICKETS_DELETE_TEXT             : "¿Realmente desea eliminar el ticket <b>{0}</b>?",
    TICKETS_OPEN_TITLE              : "Abrir ticket",
    TICKETS_OPEN_TEXT               : "¿Realmente desea abrir el ticket <b>{0}</b>?",
    TICKETS_OPEN_TAB                : "Tickets abiertos",
    TICKETS_OPEN                    : "Abrir",
    TICKETS_CLOSE_TITLE             : "Cerrar ticket",
    TICKETS_CLOSE_TEXT              : "¿Realmente desea cerrar el ticket <b>{0}</b>?",
    TICKETS_CLOSE_TAB               : "Tickets cerrados",
    TICKETS_CLOSE                   : "Cerrar",
    TICKETS_ASSIGN_TITLE            : "Asignar ticket",
    TICKETS_ASSIGN                  : "Asignar",
    TICKETS_MUTE_TITLE              : "Silenciar ticket",
    TICKETS_UNMUTE_TITLE            : "Notificar ticket",
    TICKETS_NONE_AVAILABLE          : "No hay tickets disponibles.",
    TICKETS_ITS_SUBJECT             : "Asunto del ticket",
    TICKETS_MESSAGE                 : "Mensaje del ticket",
    TICKETS_ASSIGNED                : "Asignado a",
    TICKETS_LAST_REPLY              : "Última respuesta",
    TICKETS_NONE_MESSAGES           : "No hay mensajes disponibles.",
    TICKETS_WRITE_MESSAGE           : "Escribir mensaje",
    TICKETS_EDIT_MESSAGE            : "Editar mensaje",
    TICKETS_DELETE_MESSAGE          : "Eliminar mensaje",
    TICKETS_DELETE_MESSAGE_TEXT     : "¿Realmente desea eliminar el mensaje <b>{0}</b>?",
    TICKETS_ERROR_EXISTS            : "No existe el ticket indicado.",
    TICKETS_ERROR_TYPE              : "Debe indicar el tipo del ticket.",
    TICKETS_ERROR_SUBJECT           : "Debe indicar el asunto del ticket.",
    TICKETS_ERROR_MESSAGE           : "Debe indicar el mensaje del ticket.",
    TICKETS_ERROR_ADMIN             : "No existe el usuario a quien desea asignar el ticket.",
    TICKETS_ERROR_EDIT              : "No tiene permiso para editar el ticket.",
    TICKETS_ERROR_DELETE            : "No tiene permiso para eliminar el ticket.",
    TICKETS_ERROR_ASSIGN            : "No tiene permiso para asignar el ticket.",
    TICKETS_ERROR_OPEN              : "No tiene permiso para abrir el ticket.",
    TICKETS_ERROR_CLOSE             : "No tiene permiso para cerrar el ticket.",
    TICKETS_ERROR_MESSAGE_EXISTS    : "No existe el mensaje indicado.",
    TICKETS_ERROR_MESSAGE_EDIT      : "No tiene permiso para editar el mensaje indicado.",
    TICKETS_ERROR_MESSAGE_DELETE    : "No tiene permiso para eliminar el mensaje indicado.",
    TICKETS_SUCCESS_CREATE          : "El ticket se creó correctamente.",
    TICKETS_SUCCESS_EDIT            : "El ticket se editó correctamente.",
    TICKETS_SUCCESS_DELETE          : "El ticket se eliminó correctamente.",
    TICKETS_SUCCESS_ASSIGN          : "El ticket se asignó correctamente.",
    TICKETS_SUCCESS_OPEN            : "El ticket se abrió correctamente.",
    TICKETS_SUCCESS_CLOSE           : "El ticket se cerró correctamente.",
    TICKETS_SUCCESS_MUTE            : "El ticket se silenció correctamente.",
    TICKETS_SUCCESS_UNMUTE          : "El ticket se desilenció correctamente.",
    TICKETS_SUCCESS_ADD_MESSAGE     : "El mensaje se agregó correctamente.",
    TICKETS_SUCCESS_EDIT_MESSAGE    : "El mensaje se editó correctamente.",
    TICKETS_SUCCESS_DELETE_MESSAGE  : "El mensaje se eliminó correctamente.",

    // Tickets: Types
    TICKET_TYPES_NAME               : "Tipos de ticket",
    TICKET_TYPES_SINGULAR           : "Tipo de ticket",
    TICKET_TYPES_CREATE_TITLE       : "Crear tipo de ticket",
    TICKET_TYPES_EDIT_TITLE         : "Editar tipo de ticket",
    TICKET_TYPES_DELETE_TITLE       : "Eliminar tipo de ticket",
    TICKET_TYPES_DELETE_TEXT        : "¿Realmente desea eliminar el tipo de ticket <b>{0}</b>?",
    TICKET_TYPES_ITS_NAME           : "Nombre del tipo de ticket",
    TICKET_TYPES_CREDENTIAL         : "Asignar automáticamente a",
    TICKET_TYPES_ASSIGNED           : "Asignado a",
    TICKET_TYPES_NONE_AVAILABLE     : "No hay tipos de tickets disponibles.",
    TICKET_TYPES_ERROR_EXISTS       : "No existe el tipo de ticket indicado.",
    TICKET_TYPES_ERROR_NAME         : "Debe indicar el nombre del tipo de ticket.",
    TICKET_TYPES_ERROR_STATUS       : "Debe indicar el estado del tipo de ticket.",
    TICKET_TYPES_SUCCESS_CREATE     : "El tipo de ticket se creó correctamente.",
    TICKET_TYPES_SUCCESS_EDIT       : "El tipo de ticket se editó correctamente.",
    TICKET_TYPES_SUCCESS_DELETE     : "El tipo de ticket se eliminó correctamente.",



    // Dashboard
    DASHBOARD_NAME                  : "Tablero",
    DASHBOARD_ACTIVITY              : "Actividad",
    DASHBOARD_BILL_TITLE            : "Aceptar cuenta",
    DASHBOARD_BILL_TEXT             : "El cliente desea pedir la cuenta usando el medio de pago <b>{0}</b>",
    DASHBOARD_COMPLETE              : "Finalizar",
    DASHBOARD_COMPLETE_TITLE        : "Finalizar sesión",
    DASHBOARD_COMPLETE_TEXT         : "¿Realmente desea finalizar la sesión de la mesa <b>{0}</b>?",
    DASHBOARD_WAITER                : "Mozo",
    DASHBOARD_WAITER_SHORT          : "Mz",
    DASHBOARD_BILL                  : "Cuenta",
    DASHBOARD_BILL_SHORT            : "Cta",
    DASHBOARD_NO_TABLE              : "Sin Mesa",
    DASHBOARD_COMPELTED             : " - Completado",
    DASHBOARD_ACTION_1              : "Inicio del servicio",
    DASHBOARD_ACTION_2              : "Fin del servicio",
    DASHBOARD_ACTION_3              : "Nuevo comensal",
    DASHBOARD_ACTION_11             : "Mozo solicitado (1)",
    DASHBOARD_ACTION_12             : "Mozo solicitado (2)",
    DASHBOARD_ACTION_13             : "Mozo solicitado (3)",
    DASHBOARD_ACTION_14             : "Mozo aceptado",
    DASHBOARD_ACTION_15             : "Mozo completado",
    DASHBOARD_ACTION_16             : "Mozo cancelado",
    DASHBOARD_ACTION_21             : "Cuenta solicitada (1)",
    DASHBOARD_ACTION_22             : "Cuenta solicitada (2)",
    DASHBOARD_ACTION_23             : "Cuenta solicitada (3)",
    DASHBOARD_ACTION_24             : "Cuenta aceptada",
    DASHBOARD_ACTION_25             : "Cuenta completada",
    DASHBOARD_ACTION_26             : "Cuenta cancelada",
    DASHBOARD_ACTION_31             : "Pedido verificando",
    DASHBOARD_ACTION_32             : "Pedido cancelado",
    DASHBOARD_ACTION_33             : "Pedido confirmado",
    DASHBOARD_ACTION_34             : "Pedido rechazado",
    DASHBOARD_ACTION_35             : "Pedido completado",
    DASHBOARD_PAYMENT_CASH          : "Efectivo",
    DASHBOARD_PAYMENT_DEBIT         : "Tarjeta de débito",
    DASHBOARD_PAYMENT_CREDIT        : "Tarjeta de crédito",
    DASHBOARD_PAYMENT_MP            : "MercadoPago",
    DASHBOARD_ERROR_SERVINGS        : "No existe el servicio indicado.",
    DASHBOARD_ERROR_ALL             : "No tiene permiso para aceptar el mozo y la cuenta.",
    DASHBOARD_ERROR_WAITER          : "No tiene permiso para aceptar el mozo.",
    DASHBOARD_ERROR_BILL            : "No tiene permiso para aceptar la cuenta.",
    DASHBOARD_ERROR_COMPLETE        : "No tiene permiso para completar la sesión.",
    DASHBOARD_SUCCESS_ALL           : "El mozo y la cuenta se aceptaron correctamente.",
    DASHBOARD_SUCCESS_WAITER        : "El mozo se aceptó correctamente.",
    DASHBOARD_SUCCESS_BILL          : "La cuenta se aceptó correctamente.",
    DASHBOARD_SUCCESS_COMPLETE      : "La sesión se finalizó correctamente.",

    // Dashboard: Orders
    ORDERS_LIST_TITLE               : "Listado de pedidos",
    ORDERS_ACTIVE_TITLE             : "Pedidos activos",
    ORDERS_COMPLETED_TITLE          : "Pedidos completados",
    ORDERS_CONFIRM_TITLE            : "Confirmar pedido",
    ORDERS_CONFIRM_TEXT             : "¿Realmente desea confirmar el pedido <b>{0}</b>?",
    ORDERS_REJECT_TITLE             : "Rechazar pedido",
    ORDERS_REJECT_TEXT              : "¿Realmente desea rechazar el pedido <b>{0}</b>?",
    ORDERS_COMPLETE_TITLE           : "Completar pedido",
    ORDERS_COMPLETE_TEXT            : "¿Realmente desea completar el pedido <b>{0}</b>?",
    ORDERS_CANCEL_TITLE             : "Cancelar pedido",
    ORDERS_CANCEL_TEXT              : "¿Realmente desea cancelar el pedido <b>{0}</b>?",
    ORDERS_PAYMENT_TITLE            : "Forma de pago",
    ORDERS_AMOUNT_SINGULAR          : "{0} pedido",
    ORDERS_AMOUNT_PLURAL            : "{0} pedidos",
    ORDER_NAME                      : "Pedido #{0}",
    ORDER_DESC_1                    : "{0} - {1}",
    ORDER_DESC_2                    : "Sin mesa - {1}",
    ORDERS_SIZE                     : "Tamaño:",
    ORDERS_COMMENTS                 : "Comentarios",
    ORDERS_REJECT_ITEM              : "Rechazar producto",
    ORDERS_REJECT_MESSAGE           : "Motivo de rechazo",
    ORDERS_ERROR_CONFIRM            : "No es posible confirmar el pedido indicado.",
    ORDERS_ERROR_REJECT             : "No es posible rechazar el pedido indicado.",
    ORDERS_ERROR_REJECTIONS         : "Debe indicar un motivo de rechazo en al menos un producto.",
    ORDERS_ERROR_COMPLETE           : "No es posible completar el pedido indicado.",
    ORDERS_ERROR_CANCEL             : "No es posible cancelar el pedido indicado.",
    ORDERS_SUCCESS_CONFIRM          : "El pedido se confirmó correctamente.",
    ORDERS_SUCCESS_REJECT           : "El pedido se rechazó correctamente.",
    ORDERS_SUCCESS_COMPLETE         : "El pedido se completó correctamente.",
    ORDERS_SUCCESS_CANCEL           : "El pedido se canceló correctamente.",



    // Users
    USERS_NAME                      : "Usuarios",
    USERS_SINGULAR                  : "Usuario",
    USERS_CREATE_TITLE              : "Crear usuario",
    USERS_VIEW_TITLE                : "Ver usuario",
    USERS_EDIT_TITLE                : "Editar usuario",
    USERS_DELETE_TITLE              : "Eliminar usuario",
    USERS_DELETE_TEXT               : "¿Realmente desea eliminar el usuario <b>{0}</b>?",
    USERS_FIRST_NAME                : "Nombre",
    USERS_LAST_NAME                 : "Apellido",
    USERS_NICK_NAME                 : "Apodo",
    USERS_NEW_PASSWORD              : "Nueva contraseña",
    USERS_PASSWORD                  : "Contraseña",
    USERS_PASSWORD_CONFIRM          : "Confirmar contraseña",
    USERS_PHONE                     : "Teléfono",
    USERS_DNI                       : "DNI",
    USERS_CUIT                      : "CUIT",
    USERS_ADRRESS                   : "Dirección",
    USERS_BIRTHDATE                 : "Cumpleaños",
    USERS_LANGUAGE                  : "Idioma",
    USERS_LEVEL                     : "Nivel",
    USERS_SEND_EMAILS               : "Enviar emails",
    USERS_SEND_TICKETS              : "Enviar nuevos tickets",
    USERS_NONE_AVAILABLE            : "No hay usuarios disponibles.",
    USERS_ERROR_EXISTS              : "No existe el usuario indicado.",
    USERS_ERROR_FIRST_NAME          : "Debe indicar el nombre.",
    USERS_ERROR_LAST_NAME           : "Debe indicar el apellido.",
    USERS_ERROR_LEVEL_EMPTY         : "Debe completar el nivel.",
    USERS_ERROR_LEVEL_INVALID       : "El nivel indicado es incorrecto.",
    USERS_ERROR_EMAIL_EMPTY         : "Debe indicar el email.",
    USERS_ERROR_EMAIL_INVALID       : "El email es incorrecto.",
    USERS_ERROR_EMAIL_USED          : "El email indicado esta siendo usado por otra cuenta.",
    USERS_ERROR_PASSWORD_EMPTY      : "Debe indicar la contraseña.",
    USERS_ERROR_PASSWORD_INVALID    : "La contraseña debe contener 6 o más caracteres y al menos un número y una letra.",
    USERS_ERROR_PASSWORD_CONFIRM    : "Debe confirmar su nueva contraseña.",
    USERS_ERROR_PASSWORD_MATCH      : "Las contraseñas son diferentes.",
    USERS_ERROR_DNI_INVALID         : "El DNI indicado es incorrecto.",
    USERS_ERROR_DNI_USED            : "El DNI indicado esta siendo usado por otra cuenta.",
    USERS_ERROR_CUIT_INVALID        : "El CUIT indicado es incorrecto.",
    USERS_ERROR_CUIT_USED           : "El CUIT indicado esta siendo usado por otra cuenta.",
    USERS_ERROR_LANGUAGE            : "Debe indicar el idioma.",
    USERS_SUCCESS_CREATE            : "El usuario se creó correctamente.",
    USERS_SUCCESS_EDIT              : "El usuario se editó correctamente.",
    USERS_SUCCESS_DELETE            : "El usuario se eliminó correctamente.",

    // Users: Admins
    ADMINS_NAME                     : "Administradores",
    ADMINS_SINGULAR                 : "Administrador",
    ADMINS_CREATE_TITLE             : "Crear administrador",
    ADMINS_EDIT_TITLE               : "Editar administrador",
    ADMINS_DELETE_TITLE             : "Eliminar administrador",
    ADMINS_DELETE_TEXT              : "¿Realmente desea eliminar el administrador <b>{0}</b>?",
    ADMINS_NONE_AVAILABLE           : "No hay administradores disponibles.",
    ADMINS_ERROR_EXISTS             : "No existe el administrador indicado.",
    ADMINS_ERROR_EDIT               : "No puede editar el administrador indicado.",
    ADMINS_SUCCESS_CREATE           : "El administrador se creó correctamente.",
    ADMINS_SUCCESS_EDIT             : "El administrador se editó correctamente.",
    ADMINS_SUCCESS_DELETE           : "El administrador se eliminó correctamente.",



    // Settings
    SETTINGS_NAME                   : "Configuración",

    // Settings: Plans
    PLANS_NAME                      : "Planes",
    PLANS_SINGULAR                  : "Plan",
    PLANS_CREATE_TITLE              : "Crear plan",
    PLANS_EDIT_TITLE                : "Editar plan",
    PLANS_DELETE_TITLE              : "Eliminar plan",
    PLANS_DELETE_TEXT               : "¿Realmente desea eliminar el plan <b>{0}</b>?",
    PLANS_ITS_NAME                  : "Nombre del plan",
    PLANS_DESCRIPTION               : "Descripción del plan",
    PLANS_PRICE                     : "Precio del plan",
    PLANS_REQUEST_WAITER            : "Se puede solicitar mozo",
    PLANS_REQUEST_WAITER_SHORT      : "Mozo",
    PLANS_CREATE_ORDERS             : "Se puede crear pedidos",
    PLANS_CREATE_ORDERS_SHORT       : "Pedidos",
    PLANS_FREE                      : "Gratis",
    PLANS_NONE_AVAILABLE            : "No hay planes disponibles.",
    PLANS_ERROR_EXISTS              : "No existe el plan indicado.",
    PLANS_ERROR_NAME                : "Debe indicar el nombre del plan.",
    PLANS_ERROR_STATUS              : "Debe indicar el estado del plan.",
    PLANS_SUCCESS_CREATE            : "El plan se creó correctamente.",
    PLANS_SUCCESS_EDIT              : "El plan se editó correctamente.",
    PLANS_SUCCESS_DELETE            : "El plan se eliminó correctamente.",

    // Settings: Email Templates
    EMAIL_TEMPLATES_NAME            : "Emails",
    EMAIL_TEMPLATES_LIST            : "Plantillas de emails",
    EMAIL_TEMPLATES_TITLE           : "Editar plantilla de emails",
    EMAIL_TEMPLATES_SEND_AS         : "Enviar desde el mail",
    EMAIL_TEMPLATES_SEND_NAME       : "Enviar con el nombre",
    EMAIL_TEMPLATES_SEND_TO         : "Enviar a",
    EMAIL_TEMPLATES_SEND_TO_HELP    : "Indique los emails a quien se envía el email. Si se deja en blanco se envía a los administradores.",
    EMAIL_TEMPLATES_MESSAGE         : "Mensaje del email",
    EMAIL_TEMPLATES_NONE_AVAILABLE  : "No hay plantillas de emails disponibles.",
    EMAIL_TEMPLATES_ERROR_EXISTS    : "No existe la plantilla de email indicada.",
    EMAIL_TEMPLATES_ERROR_SEND_AS   : "Debe indicar el email de quien lo envia.",
    EMAIL_TEMPLATES_ERROR_SUBJECT   : "Debe indicar el asunto del email.",
    EMAIL_TEMPLATES_ERROR_MESSAGE   : "Debe indicar el mensaje del email.",
    EMAIL_TEMPLATES_ERROR_TYPE      : "No existe el tipo de email indicado.",
    EMAIL_TEMPLATES_SUCCESS_EDIT    : "La plantilla de emails se editó correctamente.",

    // Settings: Actions Log
    ACTIONS_LOG_NAME                : "Acciones",
    ACTIONS_LOG_LIST                : "Log de acciones",
    ACTIONS_LOG_SESSION             : "Sesión",
    ACTIONS_LOG_ACTION              : "Acción",
    ACTIONS_LOG_SECTION             : "Sección",
    ACTIONS_LOG_NONE_AVAILABLE      : "No hay acciones disponibles.",

    // Setup: Errors Log
    ERRORS_LOG_NAME                 : "Errores",
    ERRORS_LOG_SINGULAR             : "Error",
    ERRORS_LOG_LIST                 : "Log de errores",
    ERRORS_LOG_VIEW_TITLE           : "Ver error",
    ERRORS_LOG_NONE_AVAILABLE       : "No hay errores disponibles.",
    ERRORS_LOG_AMOUNT               : "Cantidad",
    ERRORS_LOG_RESOLVE              : "Marcar como resuelto",
    ERRORS_LOG_RESOLVED             : "Resuelto",
    ERRORS_LOG_ERROR_EXISTS         : "No existe el error indicado.",
    ERRORS_LOG_SUCCESS_RESOLVED     : "El error se marcó como resuelto correctamente.",



    // Profile: Profile
    ACCOUNT_NAME                    : "Cuenta",
    ACCOUNT_MINE                    : "Mi cuenta",
    ACCOUNT_CHANGE_PASSWORD         : "Cambiar contraseña",
    ACCOUNT_CHANGE_AVATAR           : "Cambiar Avatar",
    ACCOUNT_ERROR_FIRST_NAME        : "Debe indicar su nombre.",
    ACCOUNT_ERROR_LAST_NAME         : "Debe indicar su apellido.",
    ACCOUNT_ERROR_EMAIL_EMPTY       : "Debe indicar su email.",
    ACCOUNT_ERROR_EMAIL_INVALID     : "El email es incorrecto o esta usado por otro usuario.",
    ACCOUNT_ERROR_AVATAR_EMPTY      : "Debe proporcionar una image.",
    ACCOUNT_ERROR_AVATAR_SIZE       : "La imagen es muy grande, intente con una más pequeña.",
    ACCOUNT_ERROR_AVATAR_TYPE       : "El archivo provisto no es una imagen.",
    ACCOUNT_SUCCESS_EDIT            : "La cuenta fue editada correctamente.",
    ACCOUNT_SUCCESS_UPLOAD          : "El avatar se subió correctamente.",



    // Media Dialog
    MEDIA_SELECT_TITLE              : "Seleccionar archivo",
    MEDIA_CREATE_TITLE              : "Crear directorio",
    MEDIA_EDIT_DIR_TITLE            : "Renombrar directorio",
    MEDIA_EDIT_FILE_TITLE           : "Renombrar archivo",
    MEDIA_DELETE_DIR_TITLE          : "Eliminar directorio",
    MEDIA_DELETE_FILE_TITLE         : "Eliminar archivo",
    MEDIA_DELETE_DIR_TEXT           : "¿Realmente desea eliminar el directorio <b>{0}</b>?",
    MEDIA_DELETE_FILE_TEXT          : "¿Realmente desea eliminar el archivo <b>{0}</b>?",
    MEDIA_NONE_AVAILABLE            : "No hay archivos disponibles",
    MEDIA_ERROR_CREATE              : "No fue posible crear el directorio indicado.",
    MEDIA_ERROR_CREATE_NAME         : "Debe indicar el nombre del directorio.",
    MEDIA_ERROR_CREATE_EXISTS       : "Ya existe un archivo/directorio con ese nombre.",
    MEDIA_ERROR_UPLOAD              : "Ocurrió al subir el archivo.",
    MEDIA_ERROR_UPLOAD_EMPTY        : "Debe indicar un archivo para subir.",
    MEDIA_ERROR_UPLOAD_SIZE         : "El archivo es demasiado grande.",
    MEDIA_ERROR_UPLOAD_EXISTS       : "Ya existe un archivo con ese nombre.",
    MEDIA_ERROR_RENAME              : "Ocurrió un error al renombrar el archivo/directorio indicado.",
    MEDIA_ERROR_RENAME_OLD_NAME     : "Debe indicar el archivo/directorio para renombrar.",
    MEDIA_ERROR_RENAME_NEW_NAME     : "Debe indicar el nuevo nombre del archivo.",
    MEDIA_ERROR_RENAME_OLD_EXISTS   : "No existe el archivo/directorio para renombrar.",
    MEDIA_ERROR_RENAME_NEW_EXISTS   : "Ya existe un archivo/directorio con el nuevo nombre.",
    MEDIA_ERROR_DELETE              : "Ocurrió un error al eliminar el archivo/directorio indicado.",
    MEDIA_ERROR_DELETE_NAME         : "Debe indicar el archivo/directorio para eliminar.",
    MEDIA_ERROR_DELETE_EXISTS       : "No existe el archivo/directorio para eliminar.",
    MEDIA_SUCCESS_CREATE            : "El directorio se creó correctamente.",
    MEDIA_SUCCESS_UPLOAD            : "El archivo se subió correctamente.",
    MEDIA_SUCCESS_RENAME            : "El archivo/directorio se renombró correctamente.",
    MEDIA_SUCCESS_DELETE            : "El archivo/directorio se eliminó correctamente.",

    // DropZone
    DROPZONE_FILES_TITLE             : "Arrastra archivos a cualquier lugar para subirlos",
    DROPZONE_IMAGES_TITLE            : "Arrastra imágenes a cualquier lugar para subirlas",
    DROPZONE_OR                      : "ó",
    DROPZONE_FILES_BUTTON            : "Seleccionar archivos",
    DROPZONE_IMAGES_BUTTON           : "Seleccionar imágenes",
    DROPZONE_FILES_DROP              : "Arrastra los archivos aquí para subirlos",
    DROPZONE_IMAGES_DROP             : "Arrastra las imágenes aquí para subirlas",

    // Access
    ACCESS_ASSISTANT                : "Asistente",
    ACCESS_MANAGER                  : "Gerente",
    ACCESS_SUPPORT                  : "Soporte",
    ACCESS_ADMIN                    : "Administrador",
    ACCESS_OWNER                    : "Dueño",

    // Status
    STATUS_ACTIVE                   : "Activo",
    STATUS_ACTIVE_FEM               : "Activa",
    STATUS_INACTIVE                 : "Inactivo",
    STATUS_INACTIVE_FEM             : "Inactiva",
    STATUS_OPEN                     : "Abierto",
    STATUS_OPEN_FEM                 : "Abierta",
    STATUS_CLOSED                   : "Cerrado",
    STATUS_CLOSED_FEM               : "Cerrada",
    STATUS_CREATING                 : "Creando",
    STATUS_VERIFYING                : "Verificando",
    STATUS_REJECTED                 : "Rechazado",
    STATUS_CONFIRMED                : "Confirmado",
    STATUS_COMPLETED                : "Completado",
    STATUS_CANCELLED                : "Cancelado",

    // Replazable Date Formats. {d: Day number, d0: 2 digit day, dn: Day name, d3: 3 letters day name, m: month, m0: 2 digit month, mn: Month name, m3: 3 letters month name, y: year, h: hours, i: minutes}
    DATE_DAY_NAMES                  : [ "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado" ],
    DATE_DAY_SHORTS                 : [ "Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá" ],
    DATE_MONTH_NAMES                : [ "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre" ],

    DATE_TIME                       : "{h}:{i}",
    DATE_DAY_TIME                   : "{d} {m3} {h}:{i}",
    DATE_DAY_MONTH                  : "{d} de {mn}",
    DATE_DAY_MONTH_YEAR             : "{d} de {mn}, {y}",
    DATE_MONTH_YEAR                 : "{mn} {y}",
    DATE_REDUCED                    : "{dn} {d0}/{m0}",
    DATE_COMPLETE                   : "{dn}, {d} de {mn}, {y}",
    DATE_SHORT_COMPLETE             : "{d3}, {d} de {m3}, {y}",
    DATE_DASHES                     : "{d0}-{m0}-{y}",
    DATE_DASHES_REVERSE             : "{y}-{m0}-{d0}",
    DATE_DASHES_TIME                : "{d0}-{m0}-{y} a las {h}:{i}",
    DATE_DASHES_HOUR                : "{d0}-{m0}-{y}, {h}:{i}",
    DATE_SLASHES                    : "{d0}/{m0}/{y}",
    DATE_SLASHES_DAY                : "{d0}/{m0}",
    DATE_SORTABLE                   : "{y}/{m0}/{d0}",

    DATE_MIN_AGO                    : "{i} minuto atras",
    DATE_MINS_AGO                   : "{i} minutos atras",
    DATE_HOUR_AGO                   : "{h} hora atras",
    DATE_HOURS_AGO                  : "{h} horas atras",
    DATE_DAY_AGO                    : "{h} día atras",
    DATE_DAYS_AGO                   : "{h} días atras",
    DATE_IN_MIN                     : "En {i} minuto",
    DATE_IN_MINS                    : "En {i} minutos",
    DATE_IN_HOUR                    : "En {h} hora",
    DATE_IN_HOURS                   : "En {h} horas",
    DATE_IN_DAY                     : "En {h} día",
    DATE_IN_DAYS                    : "En {h} días",
    DATE_TOMORROW_AT                : "Mañana a las {h}:{i}",
    DATE_TODAY_AT                   : "Hoy a las {h}:{i}",
    DATE_YESTERDAY_AT               : "Ayer a las {h}:{i}",
    DATE_THIS_WEEK_AT               : "{dn} a las {h}:{i}",
    DATE_THIS_YEAR_AT               : "{d} de {m3} a las {h}:{i}",
    DATE_OTHER_YEAR_AT              : "{d} de {m3} {y} a las {h}:{i}",

    DATE_YESTERDAY                  : "Ayer",
    DATE_TODAY                      : "Hoy",
    DATE_TOMORROW                   : "Mañana",
    DATE_LAST_WEEK                  : "La Semana Pasada",
    DATE_THIS_WEEK                  : "Esta Semana",
    DATE_NEXT_WEEK                  : "La Semana Próxima",
    DATE_PARSED_WEEK                : "Semana del {0} al {1}",
    DATE_DURATION                   : "{0} - {1}",

};
