import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    OPTIONS_LOADING, OPTIONS_ELEM,
} from "Utils/Types";



// The initial State
const initialState = {
    loading : false,
    error   : false,
    edition : 0,
    elem    : {},
};



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, OPTIONS_ELEM)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case OPTIONS_LOADING:
        return {
            ...state,
            loading   : true,
        };

    case OPTIONS_ELEM:
        return {
            loading : false,
            error   : false,
            edition : state.edition + 1,
            elem    : action.data || {},
        };
    
    default:
        return state;
    }
};
