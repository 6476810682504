import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchClient }      from "Actions/App/Client/ClientActions";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import ClientEdit           from "./ClientEdit";
import Aside                from "dashboard/dist/Components/Aside";
import Details              from "dashboard/dist/Components/Details";
import DetailList           from "dashboard/dist/Components/DetailList";
import DetailItem           from "dashboard/dist/Components/DetailItem";
import DetailActionList     from "dashboard/dist/Components/DetailActionList";
import DetailAction         from "dashboard/dist/Components/DetailAction";



/**
 * The Client Details
 */
class ClientDetails extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
    }

    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        this.setState({ action });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get());
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.props.fetchClient(this.props.clientID);
        this.endAction();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { canEdit, error, elem, clientID } = this.props;
        const { action                         } = this.state;

        return <Aside>
            <Details
                isLoading={Utils.isEmpty(elem) || elem.clientID !== clientID}
                hasError={error}
                error="CLIENTS_ERROR_EXISTS"
            >
                <DetailList message="CLIENTS_DETAILS_TITLE">
                    <DetailItem icon="name"         message={elem.name}             tooltip="CLIENTS_ITS_NAME"      />
                    <DetailItem icon="name"         message={elem.socialReason}     tooltip="CLIENTS_SOCIAL_REASON" />
                    <DetailItem icon="iva"          message={elem.ivaCategoryName}  tooltip="GENERAL_IVA_CATEGORY"  />
                    <DetailItem icon="cuit"         message={elem.cuit}             tooltip="CLIENTS_CUIT"          prefix="GENERAL_CUIT"   />
                    <DetailItem icon="dni"          message={elem.dni}              tooltip="CLIENTS_DNI"           prefix="GENERAL_DNI" />
                    <DetailItem icon="contact"      message={elem.contact}          tooltip="CLIENTS_CONTACT"       />
                    <DetailItem icon="email"        message={elem.email}            tooltip="CLIENTS_EMAIL"         isEmail />
                    <DetailItem icon="phone"        message={elem.phone}            tooltip="CLIENTS_PHONE"         isPhone />
                    <DetailItem icon="address"      message={elem.addressText}      tooltip="CLIENTS_ADDRESS"       />
                    <DetailItem icon="plan"         message={elem.planText}         tooltip="PLANS_SINGULAR"        />
                    <DetailItem icon="money"        message={elem.planPriceText}    tooltip="PLANS_SINGULAR"        />
                    <DetailItem icon="time"         message={elem.serviceStartDate} tooltip="CLIENTS_SERVICE_START" prefix="GENERAL_START" />
                    <DetailItem icon="payment"      message={elem.nextPaymentDate}  tooltip="CLIENTS_NEXT_PAYMENT"  prefix="GENERAL_PAYMENT" className={elem.paymentClass} />
                    <DetailItem icon="language"     message={elem.languageName}     tooltip="CLIENTS_LANGUAGE"      />
                    <DetailItem icon="status"       message={elem.statusName}       tooltip="GENERAL_STATUS"        className={elem.statusClass} />
                    <DetailItem icon="observations" message={elem.observations}     tooltip="GENERAL_OBSERVATIONS"  />
                </DetailList>
                <DetailActionList onAction={this.startAction} canEdit={canEdit}>
                    <DetailAction action="EDIT" message="CLIENTS_EDIT_TITLE" />
                </DetailActionList>
            </Details>

            <ClientEdit
                open={action.isEdit}
                elemID={clientID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
        </Aside>;
    }

    

    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchClient : PropTypes.func.isRequired,
        elem        : PropTypes.object.isRequired,
        error       : PropTypes.bool.isRequired,
        canEdit     : PropTypes.bool.isRequired,
        clientID    : PropTypes.number.isRequired,
    }
    
    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            error   : state.client.error,
            canEdit : state.client.canEdit,
            elem    : state.client.elem,
        };
    }
}

export default connect(ClientDetails.mapStateToProps, {
    fetchClient,
})(ClientDetails);
