import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Code }             from "Utils/API";

// Types
import {
    CODE, CODE_LOADING,
    CODE_LIST, CODE_ELEM,
} from "Utils/Types";



/**
 * Fetches the Code List
 * @param {String}   type
 * @param {Number}   elemID
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCodes(type, elemID, params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : CODE_LOADING });
        }
        Params.unset(params);
        let data = {};

        switch (type) {
        case "CLIENT":
            params.clientID = elemID;
            data = await Code.getAllForClient(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : CODE_LIST, data });
        dispatch(setSection(CODE));
    };
}

/**
 * Fetches a single Code
 * @param {Number} codeID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCode(codeID) {
    return async (dispatch) => {
        const data = await Code.getOne({ codeID });
        dispatch({ type : CODE_ELEM, data });
    };
}
