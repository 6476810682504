import { combineReducers }      from "redux";

// Core
import CoreReducer              from "Reducers/Core/CoreReducer";
import AuthReducer              from "Reducers/Core/AuthReducer";
import MediaReducer             from "Reducers/Core/MediaReducer";

// Client
import ClientReducer            from "Reducers/App/Client/ClientReducer";
import UserReducer              from "Reducers/App/Client/UserReducer";
import TableReducer             from "Reducers/App/Client/TableReducer";
import CodeReducer              from "Reducers/App/Client/CodeReducer";
import OptionsReducer           from "Reducers/App/Client/OptionsReducer";
import PaymentReducer           from "Reducers/App/Client/PaymentReducer";

// Product
import ProductReducer           from "Reducers/App/Product/ProductReducer";
import CategoryReducer          from "Reducers/App/Product/CategoryReducer";
import SubcategoryReducer       from "Reducers/App/Product/SubcategoryReducer";
import OptionalReducer          from "Reducers/App/Product/OptionalReducer";
import SizeReducer              from "Reducers/App/Product/SizeReducer";

// Dashboard
import DashboardReducer         from "Reducers/App/Client/DashboardReducer";

// Ticket
import TicketReducer            from "Reducers/App/Ticket/TicketReducer";
import TicketTypeReducer        from "Reducers/App/Ticket/TicketTypeReducer";

// Settings
import AdminReducer             from "Reducers/App/Setup/AdminReducer";
import PlanReducer              from "Reducers/App/Setup/PlanReducer";
import EmailTemplateReducer     from "Reducers/App/Setup/EmailTemplateReducer";
import ActionLogReducer         from "Reducers/App/Setup/ActionLogReducer";
import ErrorLogReducer          from "Reducers/App/Setup/ErrorLogReducer";

// Profile
import ProfileReducer           from "Reducers/App/Profile/ProfileReducer";




// The Combined Reducers
export default combineReducers({
    core             : CoreReducer,
    auth             : AuthReducer,
    media            : MediaReducer,

    // Client
    client           : ClientReducer,
    user             : UserReducer,
    table            : TableReducer,
    code             : CodeReducer,
    options          : OptionsReducer,
    payment          : PaymentReducer,
    
    // Product
    product          : ProductReducer,
    category         : CategoryReducer,
    subcategory      : SubcategoryReducer,
    optional         : OptionalReducer,
    size             : SizeReducer,

    // Dashboard
    dashboard        : DashboardReducer,

    // Ticket
    ticket           : TicketReducer,
    ticketType       : TicketTypeReducer,

    // Settings
    admin            : AdminReducer,
    plan             : PlanReducer,
    emailTemplate    : EmailTemplateReducer,
    actionLog        : ActionLogReducer,
    errorLog         : ErrorLogReducer,

    // Profile
    profile          : ProfileReducer,
});
