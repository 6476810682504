// The App Actions
export default [
    // Ticket Actions
    {
        name    : "ASSIGN",
        icon    : "assign",
        message : "TICKETS_ASSIGN",
    },
    {
        name    : "OPEN",
        icon    : "status",
        message : "TICKETS_OPEN",
    },
    {
        name    : "CLOSE",
        icon    : "status",
        message : "TICKETS_CLOSE",
    },
    {
        name    : "MUTE",
        icon    : "mute",
        message : "TICKETS_MUTE",
    },
    {
        name    : "UNMUTE",
        icon    : "unmute",
        message : "TICKETS_UNMUTE",
    },
    

    // Order Actions
    {
        name    : "CONFIRM",
        icon    : "check",
    },
    {
        name    : "REJECT",
        icon    : "close",
    },
    {
        name    : "COMPLETE",
        icon    : "check",
    },
    {
        name    : "CANCEL",
        icon    : "close",
    },


    // Special Actions
    {
        name    : "GENERATE",
        icon    : "add",
        message : "GENERAL_GENERATE",
    },
    {
        name    : "PAYMENT",
        icon    : "payment",
    },
    {
        name    : "RESOLVE",
        icon    : "check",
    },
];
