import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Category }         from "Utils/API";

// Types
import {
    CATEGORY, CATEGORY_LOADING,
    CATEGORY_LIST, CATEGORY_ELEM, CATEGORY_EDIT,
} from "Utils/Types";



/**
 * Fetches the Category List
 * @param {String}   type
 * @param {Number}   elemID
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCategories(type, elemID, params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : CATEGORY_LOADING });
        }
        Params.unset(params);
        let data = {};

        switch (type) {
        case "CLIENT":
            params.clientID = elemID;
            data = await Category.getAllForClient(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : CATEGORY_LIST, data });
        dispatch(setSection(CATEGORY));
    };
}

/**
 * Fetches a single Category
 * @param {Number} categoryID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCategory(categoryID) {
    return async (dispatch) => {
        const data = await Category.getOne({ categoryID });
        dispatch({ type : CATEGORY_ELEM, data });
    };
}

/**
 * Fetches the Category Edit data
 * @param {Number} clientID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchCategoryEdit(clientID) {
    return async (dispatch) => {
        const data = await Category.getEditData({ clientID });
        dispatch({ type : CATEGORY_EDIT, data });
    };
}



/**
 * Edits/Creates a Category
 * @param {Object} data
 * @returns {() => Void}
 */
export function editCategory(data) {
    return () => Category.edit(data);
}

/**
 * Deletes a Category
 * @param {Number} categoryID
 * @returns {() => Void}
 */
export function deleteCategory(categoryID) {
    return () => Category.delete({ categoryID });
}
