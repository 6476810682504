import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";
import ViewField            from "dashboard/dist/Components/ViewField";

// Actions
import {
    fetchProduct, editPartialProduct,
} from "Actions/App/Product/ProductActions";



/**
 * The Product Partial Dialog
 */
class ProductPartial extends React.Component {
    // The Initial Data
    initialData = {
        productID : 0,
        price     : "",
        sizeData  : {},
        status    : Status.getID("ACTIVE"),
    }

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : false,
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, fetchProduct } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchProduct(elemID);
                loading = true;
            } else if (elem) {
                data = this.getData(elem);
            }
        
        // Data Updated
        } else if (prevProps.edition !== edition && elemID) {
            data = this.getData(elem);
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Creates the Product Data
     * @param {Object} elem
     * @returns {Object}
     */
    getData(elem) {
        const data = Utils.extend(this.initialData, elem);
        for (const { sizeID, isActive, price, status } of elem.sizes) {
            data.sizeData[sizeID] = { isActive, price, status };
        }
        return data;
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const { data, errors } = this.state;
        if (name.includes("-")) {
            const parts = name.split("-");
            data.sizeData[parts[1]][parts[0]] = value;
            this.setState({
                data   : { ...data,   ...data.sizeData },
                errors : { ...errors, [name] : ""      },
            });
        } else {
            this.setState({
                data   : { ...data,   [name] : value },
                errors : { ...errors, [name] : ""    },
            });
        }
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const params    = { ...data };
                params.sizeData = JSON.stringify(data.sizeData);
                await this.props.editPartialProduct(params);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose, elem, sizes } = this.props;
        const { data, loading, errors      } = this.state;
        
        const hasSizes    = Boolean(elem.hasSizes && sizes.length > 0);
        const activeSizes = hasSizes ? elem.activeSizes : [];

        return <EditDialog
            open={open}
            icon="product"
            title="PRODUCTS_EDIT_TITLE"
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                isHidden={hasSizes}
                type="number"
                name="price"
                label="PRODUCTS_PRICE"
                value={data.price}
                error={errors.price}
                onChange={this.handleChange}
                step="0.01"
                isRequired
            />
            <Columns amount="3" isHidden={!hasSizes}>
                {activeSizes.map((size) => <React.Fragment key={size.sizeID}>
                    <ViewField value={size.sizeName} isSmall smallMargin />
                    <InputField
                        type="number"
                        name={`price-${size.sizeID}`}
                        label="GENERAL_PRICE"
                        value={data.sizeData[size.sizeID] ? data.sizeData[size.sizeID].price : 0}
                        error={errors[`price-${size.sizeID}`]}
                        onChange={this.handleChange}
                        step="0.01"
                        isRequired
                        isSmall
                        smallMargin
                        shrink
                    />
                    <InputField
                        type="select"
                        name={`status-${size.sizeID}`}
                        label="GENERAL_STATUS"
                        value={data.sizeData[size.sizeID] ? data.sizeData[size.sizeID].status : Status.getID("ACTIVE")}
                        error={errors[`status-${size.sizeID}`]}
                        onChange={this.handleChange}
                        options={Status.getSelect()}
                        withNone
                        isRequired
                        isSmall
                        smallMargin
                        shrink
                    />
                </React.Fragment>)}
            </Columns>
            <InputField
                type="select"
                name="status"
                label="PRODUCTS_STATUS"
                value={data.status}
                error={errors.status}
                onChange={this.handleChange}
                options={Status.getSelect()}
                withNone
                isRequired
                shrink
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchProduct       : PropTypes.func.isRequired,
        editPartialProduct : PropTypes.func.isRequired,
        open               : PropTypes.bool.isRequired,
        onClose            : PropTypes.func.isRequired,
        onSubmit           : PropTypes.func.isRequired,
        edition            : PropTypes.number.isRequired,
        sizes              : PropTypes.array.isRequired,
        elem               : PropTypes.object.isRequired,
        elemID             : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            sizes   : state.product.sizes,
            edition : state.product.edition,
            elem    : state.product.elem,
        };
    }
}

export default connect(ProductPartial.mapStateToProps, {
    fetchProduct, editPartialProduct,
})(ProductPartial);
