import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Sections
import AdminContainer       from "Components/Core/Admin/AdminContainer";
import ProfileContainer     from "Components/App/Profile/ProfileContainer";
import ClientPage           from "Components/App/Client/Client/ClientPage";
import DashboardPage        from "Components/App/Client/Dashboard/DashboardPage";
import SetupContainer       from "Components/App/Setup/SetupContainer";

// Components
import AdminRoute           from "Components/Utils/Route/AdminRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";



/**
 * The Admin Router
 */
class AdminRouter extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        if (!this.props.isAdmin) {
            return <React.Fragment />;
        }
        return <SwitchRoute>
            <AdminRoute url="PROFILE"          component={ProfileContainer} />
            <AdminRoute url="CLIENT_DASHBOARD" component={DashboardPage}    />
            <AdminRoute url="CLIENT"           component={ClientPage}       />
            <AdminRoute url="SETTINGS"         component={SetupContainer}   />
            <AdminRoute url="NONE"             component={AdminContainer}   />
        </SwitchRoute>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isAdmin : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAdmin : state.auth.isAdmin,
        };
    }
}

export default connect(AdminRouter.mapStateToProps)(AdminRouter);
