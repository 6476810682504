export default {
    
    NONE                : "",

    // Auth Urls
    LOGIN               : "login",
    SIGNUP              : "signup",
    PASS_RECOVER        : "recover",
    PASS_CODE           : "code",
    PASS_RESET          : "reset/:code",
    PASS_CHANGE         : "change",
    INFORMATION         : "information",

    
    // Client Urls
    CLIENTS             : "clients",
    CLIENT              : "clients/:clientID",
    CLIENT_DASHBOARD    : "clients/:clientID/dashboard",

    USERS               : "users",
    PRODUCTS            : "products",
    CATEGORIES          : "categories",
    SUBCATEGORIES       : "subcategories",
    OPTIONALS           : "optionals",
    SIZES               : "sizes",
    TABLES              : "tables",
    CODES               : "codes",
    OPTIONS             : "options",
    DASHBOARD           : "dashboard",
    PAYMENTS            : "payments",

    
    // Ticket Urls
    TICKETS             : "tickets",
    TICKET_TYPES        : "types",

    // Setup/Profile Urls
    SETTINGS            : "settings",
    ADMINS              : "admins",
    PLANS               : "plans",
    EMAIL_TEMPLATES     : "emails",
    ACTIONS             : "actions",
    ERRORS              : "errors",
    PROFILE             : "profile"
}
