import { setSection }       from "Actions/Core/CoreActions";
import { Dashboard }        from "Utils/API";

// Types
import {
    DASHBOARD, DASHBOARD_LOADING,
    DASHBOARD_ELEM, DASHBOARD_UPDATE,
} from "Utils/Types";



/**
 * Fetches the Dashboard
 * @param {Number=} clientID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchDashboard(clientID) {
    return async (dispatch) => {
        dispatch({ type : DASHBOARD_LOADING });
        const data = await Dashboard.getData({ clientID });
        dispatch({ type : DASHBOARD_ELEM, data });
        dispatch(setSection(DASHBOARD));
    };
}

/**
 * Fetches the New Data
 * @param {Number} clientID
 * @param {Number} actionID
 * @param {Number} time
 * @returns {(dispatch: Function) => Void}
 */
export function fetchNewData(clientID, actionID, time) {
    return async (dispatch) => {
        const data = await Dashboard.getNewData({ clientID, actionID, time });
        dispatch({ type : DASHBOARD_UPDATE, data });
    };
}



/**
 * Accepts all the Requests
 * @param {Number} servingID
 * @returns {() => Void}
 */
export function acceptAll(servingID) {
    return () => Dashboard.acceptAll({ servingID });
}

/**
 * Accepts the Waiter Request
 * @param {Number} servingID
 * @returns {() => Void}
 */
export function acceptWaiter(servingID) {
    return () => Dashboard.acceptWaiter({ servingID });
}

/**
 * Accepts the Bill Request
 * @param {Number} servingID
 * @returns {() => Void}
 */
export function acceptBill(servingID) {
    return () => Dashboard.acceptBill({ servingID });
}

/**
 * Completes a Serving
 * @param {Number} servingID
 * @returns {() => Void}
 */
export function completeServing(servingID) {
    return () => Dashboard.completeServing({ servingID });
}

/**
 * Confirms an Order
 * @param {Number} orderID
 * @returns {() => Void}
 */
export function confirmOrder(orderID) {
    return () => Dashboard.confirmOrder({ orderID });
}

/**
 * Rejects an Order
 * @param {Number} orderID
 * @param {String} rejections
 * @returns {() => Void}
 */
export function rejectOrder(orderID, rejections) {
    return () => Dashboard.rejectOrder({ orderID, rejections });
}

/**
 * Completes an Order
 * @param {Number} orderID
 * @returns {() => Void}
 */
export function completeOrder(orderID) {
    return () => Dashboard.completeOrder({ orderID });
}

/**
 * Cancels an Order
 * @param {Number} orderID
 * @returns {() => Void}
 */
export function cancelOrder(orderID) {
    return () => Dashboard.cancelOrder({ orderID });
}
