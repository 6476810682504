import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchProduct }     from "Actions/App/Product/ProductActions";
import Action               from "dashboard/dist/Core/Action";

// Components
import ProductPartial       from "./ProductPartial";
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import Columns              from "dashboard/dist/Components/Columns";
import ViewField            from "dashboard/dist/Components/ViewField";



/**
 * The Product View Dialog
 */
class ProductView extends React.Component {
    // The Current State
    state = {
        action  : Action.get(),
        loading : true,
        update  : false,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchProduct } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchProduct(elemID);
            this.setState({ loading : true });

        // Data Updated
        } else if (prevProps.edition !== edition) {
            this.setState({ loading : false });
        }
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        this.setState({ action });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get());
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        const { elemID, fetchProduct } = this.props;
        fetchProduct(elemID);
        this.endAction();
        this.setState({ update : true });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, open, onClose     } = this.props;
        const { loading, update, action } = this.state;
        
        const title = loading ? "GENERAL_LOADING" : elem.name || "PRODUCTS_VIEW_TITLE";

        return <>
            <ViewDialog
                open={open}
                icon="product"
                title={title}
                secondary="PRODUCTS_EDIT_TITLE"
                onSecondary={() => this.startAction(Action.get("EDIT"))}
                onClose={() => onClose(update)}
                isLoading={loading}
                withSpacing
            >
                <ViewField label="PRODUCTS_DESCRIPTION" value={elem.description}   />
                <ViewField label="PRODUCTS_OPTIONALS"   value={elem.optionalsName} />
                <Columns>
                    <ViewField label="PRODUCTS_CATEGORY"    value={elem.categoryName}    />
                    <ViewField label="PRODUCTS_SUBCATEGORY" value={elem.subcategoryName} />
                </Columns>
                {elem.hasSizes ? <>
                    <Columns amount="3" isHidden={!elem.hasSizes}>
                        {elem.activeSizes.map((size, index) => <React.Fragment key={index}>
                            <ViewField label="SIZES_SINGULAR" value={size.sizeName} isSmall smallMargin />
                            <ViewField label="GENERAL_PRICE"  value={size.priceText} isSmall smallMargin />
                            <ViewField label="GENERAL_STATUS" value={size.statusName} isSmall smallMargin />
                        </React.Fragment>)}
                    </Columns>
                    <ViewField label="PRODUCTS_STATUS" value={elem.statusName} />
                </> : <Columns>
                    <ViewField label="PRODUCTS_PRICE"  value={elem.priceText}  />
                    <ViewField label="PRODUCTS_STATUS" value={elem.statusName} />
                </Columns>}
            </ViewDialog>

            <ProductPartial
                open={action.isEdit}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchProduct : PropTypes.func.isRequired,
        open         : PropTypes.bool.isRequired,
        onClose      : PropTypes.func.isRequired,
        edition      : PropTypes.number.isRequired,
        elem         : PropTypes.object.isRequired,
        elemID       : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.product.edition,
            elem    : state.product.elem,
        };
    }
}

export default connect(ProductView.mapStateToProps, {
    fetchProduct,
})(ProductView);
