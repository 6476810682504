import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Client }           from "Utils/API";

// Types
import {
    CLIENT, CLIENT_LOADING,
    CLIENT_LIST, CLIENT_ELEM, CLIENT_EDIT,
} from "Utils/Types";



/**
 * Fetches the Client List
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchClients(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : CLIENT_LOADING });
        }
        Params.unset(params);
        const data = await Client.getAll(params);
        data.sort = params;
        dispatch({ type : CLIENT_LIST, data });
        dispatch(setSection(CLIENT));
    };
}

/**
 * Fetches a single Client
 * @param {Number} clientID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchClient(clientID) {
    return async (dispatch) => {
        const data = await Client.getOne({ clientID });
        dispatch({ type : CLIENT_ELEM, data });
    };
}

/**
 * Fetches the Client Edit data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchClientEdit() {
    return async (dispatch) => {
        const data = await Client.getEditData();
        dispatch({ type : CLIENT_EDIT, data });
    };
}



/**
 * Edits/Creates a Client
 * @param {Object} data
 * @returns {() => Void}
 */
export function editClient(data) {
    return () => Client.edit(data);
}

/**
 * Deletes a Client
 * @param {Number} clientID
 * @returns {() => Void}
 */
export function deleteClient(clientID) {
    return () => Client.delete({ clientID });
}

/**
 * Marks the Client as Paid
 * @param {Number} clientID
 * @returns {() => Void}
 */
export function markAsPaid(clientID) {
    return () => Client.markAsPaid({ clientID });
}
