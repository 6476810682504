import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import Alert                from "dashboard/dist/Components/Alert";
import Button               from "dashboard/dist/Components/Button";
import HyperLink            from "dashboard/dist/Components/HyperLink";

// Images
import Logo                 from "Styles/Images/Logo.png";



/**
 * The Change Password Page Component
 */
class AuthForm extends React.Component {
    /**
     * Handles the Submit
     * @param {React.FormEvent<HTMLFormElement>} e
     * @returns {Void}
     */
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.onSubmit();
    }

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { title, help, error, url, link, button, isLoading, children } = this.props;
        
        const hasHelp = !!help;
        const hasLink = !!url && !!link;

        return <form className="auth-form" onSubmit={this.handleSubmit}>
            <h1><img src={Logo} alt={NLS.get("TITLE")} /></h1>
            <h2>{NLS.get(title)}</h2>
            {hasHelp && <p>{NLS.get(help)}</p>}
            <Alert variant="error" message={error} />

            {children}

            <div className={hasLink ? "auth-footer" : "auth-footer-btn"}>
                {hasLink && <div className="auth-link">
                    <HyperLink
                        variant="primary"
                        url={url}
                        message={link}
                        useBase
                    />
                </div>}
                <Button
                    variant="primary"
                    onClick={this.handleSubmit}
                    isDisabled={isLoading}
                    message={button}
                />
            </div>
        </form>;
    }

    

    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        onSubmit  : PropTypes.func.isRequired,
        title     : PropTypes.string.isRequired,
        help      : PropTypes.string,
        error     : PropTypes.string,
        url       : PropTypes.string,
        link      : PropTypes.string,
        button    : PropTypes.string.isRequired,
        isLoading : PropTypes.bool.isRequired,
        children  : PropTypes.any,
    }
}

export default AuthForm;
