import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";
import StatusData           from "Utils/Status";

// Types
import {
    TICKET_LOADING,
    TICKET_LIST, TICKET_ELEM,
    TICKET_EDIT, TICKET_ASSIGN,
} from "Utils/Types";



// The initial State
const initialState = {
    loading     : false,
    error       : false,
    edition     : 0,
    canCreate   : false,
    canEdit     : false,
    canImport   : false,
    canExport   : false,
    list        : [],
    total       : 0,
    elem        : {},
    member      : {},
    messages    : [],
    canModify   : false,
    canOpen     : false,
    canClose    : false,
    canAssign   : false,
    ticketTypes : [],
    credentials : [],
    admins      : [],
    sort        : {
        filter   : StatusData.OPEN,
        orderBy  : "lastReply",
        orderAsc : 0,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.adminName       = elem.adminName || NLS.get("GENERAL_NOBODY");
    elem.lastReplyString = DateTime.formatString(elem.lastReply);
    elem.statusName      = Status.getName(elem.status);
    elem.statusColor     = Status.getColor(elem.status);
    elem.statusClass     = Status.getTextClass(elem.status);
    return elem;
}

/**
 * Parses all the Messages
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseMessages(list) {
    for (const elem of list) {
        elem.createdDate   = DateTime.formatDate(elem.createdTime, "dashes");
        elem.createdString = DateTime.formatString(elem.createdTime);
    }
    return list;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, TICKET_LIST, TICKET_ELEM)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case TICKET_LOADING:
        return {
            ...state,
            loading   : true,
        };
    
    case TICKET_LIST:
        return {
            ...state,
            loading     : false,
            error       : false,
            canCreate   : action.data.canCreate,
            canEdit     : action.data.canEdit,
            canImport   : action.data.canImport,
            canExport   : action.data.canExport,
            canAssign   : action.data.canAssign,
            list        : parseList(action.data.list),
            total       : action.data.total,
            sort        : action.data.sort,
        };
    
    case TICKET_ELEM:
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            elem        : parseElem(action.data.elem),
            messages    : parseMessages(action.data.messages),
            member      : action.data.member,
            canModify   : action.data.canModify,
            canOpen     : action.data.canOpen,
            canClose    : action.data.canClose,
            canAssign   : action.data.canAssign,
            ticketTypes : action.data.ticketTypes,
            credentials : action.data.credentials,
        };

    case TICKET_EDIT:
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            canAssign   : action.data.canAssign,
            ticketTypes : action.data.ticketTypes,
            credentials : action.data.credentials,
        };
    
    case TICKET_ASSIGN:
        return {
            ...state,
            error       : false,
            edition     : state.edition + 1,
            elem        : parseElem(action.data.elem),
            canAssign   : action.data.canAssign,
            admins      : action.data.credentials,
        };
    
    default:
        return state;
    }
};
