import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { changePass }       from "Actions/Core/AuthActions";
import Href                 from "dashboard/dist/Core/Href";

// Components
import AuthForm             from "Components/Auth/AuthForm";
import IconField            from "dashboard/dist/Components/IconField";



/**
 * The Change Password Page Component
 */
class ChangePassPage extends React.Component {
    // The Current State
    state = {
        data    : {
            oldPassword     : "",
            newPassword     : "",
            confirmPassword : "",
        },
        loading : false,
        errors  : {},
    }
    
    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }
    
    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { email, changePass } = this.props;
        const { data, loading     } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                data.email = email;
                await changePass(data);
                Href.goto("LOGIN");
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }


    
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, loading, errors } = this.state;

        return <AuthForm
            onSubmit={this.handleSubmit}
            title="CHANGE_TITLE"
            help="CHANGE_HELP"
            error={errors.form}
            button="CHANGE_BUTTON"
            isLoading={loading}
        >
            <IconField
                className="auth-field"
                type="password"
                name="oldPassword"
                icon="password"
                placeholder="CHANGE_OLD_PASSWORD"
                autoComplete="new-password"
                value={data.password}
                error={errors.oldPassword}
                onChange={this.handleChange}
                isRequired
            />
            <IconField
                className="auth-field"
                type="password"
                name="newPassword"
                icon="password"
                placeholder="CHANGE_NEW_PASSWORD"
                autoComplete="new-password"
                value={data.password}
                error={errors.newPassword}
                onChange={this.handleChange}
                isRequired
            />
            <IconField
                className="auth-field"
                type="password"
                name="confirmPassword"
                icon="password"
                placeholder="USERS_PASSWORD_CONFIRM"
                autoComplete="new-password"
                value={data.confirmPassword}
                onChange={this.handleChange}
                error={errors.confirmPassword}
                isRequired
            />
        </AuthForm>;
    }

    

    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        changePass : PropTypes.func.isRequired,
        email      : PropTypes.string.isRequired,
    }
    
    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            email : state.auth.email,
        };
    }
}

export default connect(ChangePassPage.mapStateToProps, {
    changePass,
})(ChangePassPage);
