import { setSection }       from "Actions/Core/CoreActions";
import { Options }          from "Utils/API";

// Types
import {
    OPTIONS, OPTIONS_LOADING, OPTIONS_ELEM,
} from "Utils/Types";



/**
 * Fetches the Options
 * @param {Number}   clientID
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchOptions(clientID, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : OPTIONS_LOADING });
        }
        const data = await Options.getAllForClient({ clientID });
        dispatch({ type : OPTIONS_ELEM, data });
        dispatch(setSection(OPTIONS));
    };
}



/**
 * Edits the Options
 * @param {Object} data
 * @returns {() => Void}
 */
export function editOptions(data) {
    return () => Options.edit(data);
}
