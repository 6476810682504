import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "dashboard/dist/Core/NLS";



/**
 * The Orders List
 */
class OrderList extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { orders, onView, isCompleted } = this.props;
        
        const title     = isCompleted ? "ORDERS_COMPLETED_TITLE" : "ORDERS_ACTIVE_TITLE";
        const className = isCompleted ? "dashboard-orders-completed" : "dashboard-orders-active";
        const orderList = orders.filter((elem) => isCompleted ? !elem.isActive : elem.isActive);
        
        return <div className={`dashboard-orders dashboard-list darker-scrollbars ${className}`}>
            <h3>{NLS.get(title)}</h3>
            {orderList.map((elem, index) => <div
                key={index}
                className="dashboard-action"
                onClick={() => onView(elem.servingID)}
            >
                <span className="dashboard-action-name">{elem.orderName}</span>
                <span className="dashboard-action-desc">{elem.orderDesc}</span>
                <span className="dashboard-action-time">{elem.time}</span>
            </div>)}
        </div>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        orders      : PropTypes.array.isRequired,
        onView      : PropTypes.func.isRequired,
        isCompleted : PropTypes.bool,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            orders : state.dashboard.orders,
        };
    }
}

export default connect(OrderList.mapStateToProps)(OrderList);
