import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchClient }      from "Actions/App/Client/ClientActions";

// Sections
import UserList             from "Components/App/Client/User/UserList";
import ProductList          from "Components/App/Product/Product/ProductList";
import CategoryList         from "Components/App/Product/Category/CategoryList";
import SubcategoryList      from "Components/App/Product/Subcategory/SubcategoryList";
import OptionalList         from "Components/App/Product/Optional/OptionalList";
import SizeList             from "Components/App/Product/Size/SizeList";
import TableList            from "Components/App/Client/Table/TableList";
import CodeList             from "Components/App/Client/Code/CodeList";
import TicketList           from "Components/App/Ticket/Ticket/TicketList";
import OptionsPage          from "Components/App/Client/OptionsPage";
import PaymentList          from "Components/App/Client/PaymentList";
import ActionLogList        from "Components/App/Setup/ActionLogList";

// Components
import ClientDetails        from "./ClientDetails";
import SecondaryNav         from "Components/Utils/Side/SecondaryNav";
import SupportRoute         from "Components/Utils/Route/SupportRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationList       from "dashboard/dist/Components/NavigationList";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";



/**
 * The Client Page
 */
class ClientPage extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchClient(this.props.params.clientID);
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        if (this.props.params.clientID !== prevProps.params.clientID) {
            this.props.fetchClient(this.props.params.clientID);
        }
    }


    
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elem, from, params, match, route, isAdmin } = this.props;
        const { path, url                                 } = match;
        
        return <>
            <SecondaryNav
                message={elem.name}
                fallback="CLIENTS_SINGULAR"
                href={from}
                path={route}
                baseUrl={url}
            >
                <NavigationList>
                    <NavigationItem message="USERS_NAME"         url="USERS"         icon="user"        />
                    <NavigationItem message="PRODUCTS_NAME"      url="PRODUCTS"      icon="product"     />
                    <NavigationItem message="CATEGORIES_NAME"    url="CATEGORIES"    icon="category"    />
                    <NavigationItem message="SUBCATEGORIES_NAME" url="SUBCATEGORIES" icon="subcategory" />
                    <NavigationItem message="OPTIONALS_NAME"     url="OPTIONALS"     icon="optional"    />
                    <NavigationItem message="SIZES_NAME"         url="SIZES"         icon="size"        />
                    <NavigationItem message="TABLES_NAME"        url="TABLES"        icon="table"       />
                    <NavigationItem message="CODES_NAME"         url="CODES"         icon="code"        />
                    <NavigationItem message="OPTIONS_NAME"       url="OPTIONS"       icon="options"     />
                </NavigationList>
                <NavigationList>
                    <NavigationItem message="PAYMENTS_NAME"      url="PAYMENTS"      icon="payment"     isHidden={!isAdmin} />
                    <NavigationItem message="TICKETS_NAME"       url="TICKETS"       icon="ticket"      />
                    <NavigationItem message="DASHBOARD_NAME"     url="DASHBOARD"     icon="dashboard"   isHidden={!isAdmin} />
                </NavigationList>
                <NavigationList>
                    <NavigationItem message="ACTIONS_LOG_NAME"   url="ACTIONS"       icon="actions"     />
                </NavigationList>
            </SecondaryNav>

            <SwitchRoute type="CLIENT" path={path} baseUrl={url} withDetails>
                <SupportRoute url="USERS"         component={UserList}        />
                <SupportRoute url="PRODUCTS"      component={ProductList}     />
                <SupportRoute url="CATEGORIES"    component={CategoryList}    />
                <SupportRoute url="SUBCATEGORIES" component={SubcategoryList} />
                <SupportRoute url="OPTIONALS"     component={OptionalList}    />
                <SupportRoute url="SIZES"         component={SizeList}        />
                <SupportRoute url="TABLES"        component={TableList}       />
                <SupportRoute url="CODES"         component={CodeList}        />
                <SupportRoute url="TICKETS"       component={TicketList}      />
                <SupportRoute url="OPTIONS"       component={OptionsPage}     />
                <SupportRoute url="PAYMENTS"      component={PaymentList}     />
                <SupportRoute url="ACTIONS"       component={ActionLogList}   />
            </SwitchRoute>

            <ClientDetails clientID={params.clientID} />
        </>;
    }

    

    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchClient : PropTypes.func.isRequired,
        isAdmin     : PropTypes.bool.isRequired,
        elem        : PropTypes.object.isRequired,
        from        : PropTypes.string.isRequired,
        params      : PropTypes.object.isRequired,
        match       : PropTypes.object.isRequired,
        route       : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isAdmin : state.auth.isAdmin,
            elem    : state.client.elem,
        };
    }
}

export default connect(ClientPage.mapStateToProps, {
    fetchClient,
})(ClientPage);
