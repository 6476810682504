import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import DialogMessage        from "dashboard/dist/Components/DialogMessage";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchTemplate, editTemplate,
} from "Actions/App/Setup/EmailTemplateActions";



/**
 * The Email Template Edit Dialog
 */
class EmailTemplateEdit extends React.Component {
    // The Initial Data
    initialData = {
        templateCode : "",
        sendAs       : "",
        sendName     : "",
        sendTo       : "",
        subject      : "",
        message      : "",
    }

    // The Current State
    state = {
        loading : false,
        data    : { ...this.initialData },
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemCode, elem, fetchTemplate } = this.props;
        let loading = false;
        let data    = null;
        
        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemCode) {
                fetchTemplate(elemCode);
                loading = true;
            }

        // Data Updated
        } else if (prevProps.edition !== edition && elemCode) {
            data = Utils.extend(this.initialData, elem);
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editTemplate(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose, elem   } = this.props;
        const { data, loading, errors } = this.state;

        return <EditDialog
            open={open}
            icon="email"
            title="EMAIL_TEMPLATES_TITLE"
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <DialogMessage>
                <h3 className="no-margin">{elem.description}</h3>
            </DialogMessage>
            <InputField
                name="subject"
                label="GENERAL_SUBJECT"
                value={data.subject}
                error={errors.subject}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                name="sendAs"
                label="EMAIL_TEMPLATES_SEND_AS"
                value={data.sendAs}
                error={errors.sendAs}
                onChange={this.handleChange}
            />
            <InputField
                name="sendName"
                label="EMAIL_TEMPLATES_SEND_NAME"
                value={data.sendName}
                error={errors.sendName}
                onChange={this.handleChange}
            />
            <InputField
                type="fields"
                name="sendTo"
                label="EMAIL_TEMPLATES_SEND_TO"
                helperText="EMAIL_TEMPLATES_SEND_TO_HELP"
                value={data.sendTo}
                error={errors.sendTo}
                onChange={this.handleChange}
                isSmall
            />
            <InputField
                type="textarea"
                name="message"
                label="EMAIL_TEMPLATES_MESSAGE"
                value={data.message}
                error={errors.message}
                onChange={this.handleChange}
                isRequired
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchTemplate : PropTypes.func.isRequired,
        editTemplate  : PropTypes.func.isRequired,
        open          : PropTypes.bool.isRequired,
        onClose       : PropTypes.func.isRequired,
        onSubmit      : PropTypes.func.isRequired,
        edition       : PropTypes.number.isRequired,
        elem          : PropTypes.object.isRequired,
        elemCode      : PropTypes.string,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.emailTemplate.edition,
            elem    : state.emailTemplate.elem,
        };
    }
}

export default connect(EmailTemplateEdit.mapStateToProps, {
    fetchTemplate, editTemplate,
})(EmailTemplateEdit);
