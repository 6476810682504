import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Client }           from "Utils/API";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import ClientEdit           from "./ClientEdit";
import Main                 from "dashboard/dist/Components/Main";
import Content              from "dashboard/dist/Components/Content";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";
import Downloader           from "dashboard/dist/Components/Downloader";

// Actions
import {
    fetchClients, deleteClient, markAsPaid,
} from "Actions/App/Client/ClientActions";



/**
 * The Client List
 */
class ClientList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }
    
    /**
     * Fetch the content
     * @param {Object=}  params
     * @param {Boolean=} withLoader
     * @returns {Void}
     */
    fetch = (params, withLoader) => {
        this.props.fetchClients(params || this.props.data.sort, withLoader);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        this.setState({ action, elemID });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }



    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch(null, false);
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const elemID = this.state.elemID;
        this.endAction();
        await this.props.deleteClient(elemID);
        this.fetch(null, false);
    }

    /**
     * Handles the Payment Submit
     * @returns {Promise}
     */
    payElem = async () => {
        const elemID = this.state.elemID;
        this.endAction();
        await this.props.markAsPaid(elemID);
        this.fetch(null, false);
    }
    
    

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                      } = this.state;
        const { data, route                         } = this.props;
        const { canEdit, list, total, sort, loading } = data;

        const elemName = Utils.getValue(list, "clientID", elemID, "name");
        
        return <Main>
            <Header message="CLIENTS_NAME" icon="client" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content>
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="CLIENTS_NONE_AVAILABLE"
                    isLoading={loading}
                >
                    <TableHead>
                        <TableHeader field="name"             message="GENERAL_NAME"                />
                        <TableHeader field="socialReason"     message="CLIENTS_SOCIAL_REASON_SHORT" />
                        <TableHeader field="firstName"        message="CLIENTS_CONTACT"             />
                        <TableHeader field="planID"           message="PLANS_SINGULAR"              />
                        <TableHeader field="planPrice"        message="GENERAL_PRICE"               maxWidth="80" />
                        <TableHeader field="serviceStartTime" message="CLIENTS_SERVICE_START"       maxWidth="110" />
                        <TableHeader field="nextPaymentTime"  message="CLIENTS_NEXT_PAYMENT"        maxWidth="100" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.clientID} elemID={elem.clientID}>
                            <TableCell message={elem.name}          circle={elem.statusColor} />
                            <TableCell message={elem.socialReason}  />
                            <TableCell message={elem.contact}       />
                            <TableCell message={elem.planText}      />
                            <TableCell message={elem.planPriceText} />
                            <TableCell message={elem.serviceStart}  />
                            <TableCell message={elem.nextPayment}   className={elem.paymentClass} />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"    message="CLIENTS_VIEW_TITLE"    route={route} />
                        <TableAction action="EDIT"    message="CLIENTS_EDIT_TITLE"    />
                        <TableAction action="PAYMENT" message="CLIENTS_PAYMENT_TITLE" />
                        <TableAction action="DELETE"  message="CLIENTS_DELETE_TITLE"  />
                    </TableActionList>
                </Table>
            </Content>

            <ClientEdit
                open={action.isVCE}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="CLIENTS_DELETE_TITLE"
                message="CLIENTS_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isPayment}
                icon="payment"
                title="CLIENTS_PAYMENT_TITLE"
                message="CLIENTS_PAYMENT_TEXT"
                content={elemName}
                onSubmit={this.payElem}
                onClose={this.endAction}
            />
            <Downloader
                download={action.isExport}
                source={Client.export()}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchClients : PropTypes.func.isRequired,
        deleteClient : PropTypes.func.isRequired,
        markAsPaid   : PropTypes.func.isRequired,
        data         : PropTypes.object.isRequired,
        route        : PropTypes.string.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.client,
        };
    }
}

export default connect(ClientList.mapStateToProps, {
    fetchClients, deleteClient, markAsPaid,
})(ClientList);
