import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchCode }        from "Actions/App/Client/CodeActions";
import { Code }             from "Utils/API";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import Downloader           from "dashboard/dist/Components/Downloader";



/**
 * The Code View Dialog
 */
class CodeView extends React.Component {
    // The Current State
    state = {
        loading : false,
        printed : false,
    }

    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, fetchCode } = this.props;

        // Dialog Opens
        if (open && !prevProps.open) {
            fetchCode(elemID);
            this.setState({ loading : true, printed : false });

        // Data Updated
        } else if (prevProps.edition !== edition && elemID) {
            this.setState({ loading : false, printed : false });
        }

        // Dialog Closes
        if (!open && prevProps.open) {
            this.setState({ printed : false });
        }
    }

    /**
     * Remove the printed
     * @returns {Void}
     */
    componentWillUnmount() {
        this.setState({ printed : false });
    }

    /**
     * Prints a Code
     * @returns {Void}
     */
    printCode = () => {
        this.setState({ printed : true });
    }

    /**
     * Handles the Dialog Close
     * @returns {Void}
     */
    handleClose = () => {
        if (this.state.printed) {
            this.props.onSubmit();
        } else {
            this.props.onClose();
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, elem, code } = this.props;
        const { loading, printed } = this.state;
        
        return <>
            <ViewDialog
                open={open}
                className="align-center"
                icon="code"
                title="CODES_VIEW_TITLE"
                secondary="CODES_PRINT_TITLE"
                onSecondary={this.printCode}
                onClose={this.handleClose}
                isLoading={loading}
                withSpacing
            >
                {!!code && <img
                    src={`data:image/png;base64, ${code}`}
                    alt={elem.tableName}
                />}
            </ViewDialog>

            <Downloader
                download={printed}
                source={Code.print({ hash : elem.hash })}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCode : PropTypes.func.isRequired,
        open      : PropTypes.bool.isRequired,
        onClose   : PropTypes.func.isRequired,
        onSubmit  : PropTypes.func.isRequired,
        edition   : PropTypes.number.isRequired,
        code      : PropTypes.string.isRequired,
        elem      : PropTypes.object.isRequired,
        elemID    : PropTypes.number,
        clientID  : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.code.edition,
            code    : state.code.code,
            elem    : state.code.elem,
        };
    }
}

export default connect(CodeView.mapStateToProps, {
    fetchCode,
})(CodeView);
