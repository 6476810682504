import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Client }           from "Utils/API";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import EditDialog           from "dashboard/dist/Components/EditDialog";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchTicket, fetchTicketEdit, createTicket, editTicket,
} from "Actions/App/Ticket/TicketActions";



/**
 * The Ticket Edit Dialog
 */
class TicketEdit extends React.Component {
    // The Initial Data
    initialData = {
        ticketID     : 0,
        ticketTypeID : 0,
        clientID     : 0,
        clientName   : "",
        adminID      : 0,
        subject      : "",
        message      : "",
    }

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : false,
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, clientID, credential, fetchTicket, fetchTicketEdit } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchTicket(elemID);
                loading = true;
            // Load edit data
            } else {
                fetchTicketEdit();
                loading = true;
            }
        
        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
            } else if (credential.clientID) {
                data = { ...this.initialData, clientID : credential.clientID };
            } else {
                data = { ...this.initialData, clientID };
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                if (!this.props.elemID) {
                    await this.props.createTicket(data);
                } else {
                    await this.props.editTicket(data);
                }
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose, ticketTypes, credentials, canAssign } = this.props;
        const { data, loading, errors                                      } = this.state;
        
        const isEdit     = !!elemID;
        const showClient = !isEdit && !data.clientID;

        return <EditDialog
            open={open}
            icon="ticket"
            title={!!elemID ? "TICKETS_EDIT_TITLE" : "TICKETS_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                isHidden={!showClient}
                name="clientName"
                label="CLIENTS_SINGULAR"
                suggestID="clientID"
                suggestFetch={Client.search}
                value={data.clientName}
                error={errors.clientID}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="select"
                name="ticketTypeID"
                label="TICKET_TYPES_SINGULAR"
                options={ticketTypes}
                value={data.ticketTypeID}
                onChange={this.handleChange}
                error={errors.ticketTypeID}
                withNone
                isRequired
            />
            <InputField
                name="subject"
                label="TICKETS_ITS_SUBJECT"
                value={data.subject}
                onChange={this.handleChange}
                error={errors.subject}
                isRequired
            />
            <InputField
                isHidden={!!elemID}
                type="textarea"
                name="message"
                label="TICKETS_MESSAGE"
                value={data.message}
                onChange={this.handleChange}
                error={errors.message}
                isRequired
            />
            <InputField
                isHidden={!canAssign}
                type="select"
                name="adminID"
                label="TICKETS_ASSIGNED"
                options={credentials}
                value={data.adminID}
                onChange={this.handleChange}
                error={errors.adminID}
                withNone
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchTicket     : PropTypes.func.isRequired,
        fetchTicketEdit : PropTypes.func.isRequired,
        createTicket    : PropTypes.func.isRequired,
        editTicket      : PropTypes.func.isRequired,
        open            : PropTypes.bool.isRequired,
        onClose         : PropTypes.func.isRequired,
        onSubmit        : PropTypes.func.isRequired,
        credential      : PropTypes.object.isRequired,
        ticketTypes     : PropTypes.array.isRequired,
        credentials     : PropTypes.array.isRequired,
        canAssign       : PropTypes.bool.isRequired,
        edition         : PropTypes.number.isRequired,
        elem            : PropTypes.object.isRequired,
        elemID          : PropTypes.number,
        clientID        : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            credential  : state.auth.credential,
            ticketTypes : state.ticket.ticketTypes,
            credentials : state.ticket.credentials,
            canAssign   : state.ticket.canAssign,
            edition     : state.ticket.edition,
            elem        : state.ticket.elem,
        };
    }
}

export default connect(TicketEdit.mapStateToProps, {
    fetchTicket, fetchTicketEdit, createTicket, editTicket,
})(TicketEdit);
