import { Media }            from "Utils/API";

// Types
import {
    MEDIA_LOADING, MEDIA_LIST,
} from "Utils/Types";



/**
 * Fetches the Media List
 * @param {String}  mediaType
 * @param {String}  path
 * @param {String=} selected
 * @returns {(dispatch: Function) => Void}
 */
export function fetchMedia(mediaType, path, selected = "") {
    return async (dispatch) => {
        dispatch({ type : MEDIA_LOADING });
        const data = await Media.getAll({ mediaType, path, selected });
        dispatch({ type : MEDIA_LIST, data });
    };
}




/**
 * Create Directory
 * @param {String} path
 * @param {String} name
 * @returns {() => Void}
 */
export function createDirectory(path, name) {
    return () => Media.create({ path, name });
}

/**
 * Uploads a Media
 * @param {String} path
 * @param {Object} file
 * @returns {() => Void}
 */
export function uploadMedia(path, file) {
    return () => Media.upload({ path, file });
}

/**
 * Renames a Media
 * @param {String} path
 * @param {String} oldName
 * @param {String} newName
 * @returns {() => Void}
 */
export function renameMedia(path, oldName, newName) {
    return () => Media.rename({ path, oldName, newName });
}

/**
 * Deletes Media
 * @param {String} path
 * @param {String} name
 * @returns {() => Void}
 */
export function deleteMedia(path, name) {
    return () => Media.delete({ path, name });
}
