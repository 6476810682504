export default {
    
    // ID to Section
    SECTIONS  : {
    },
    
    
    
    // ID to Action
    ACTIONS   : {
    },
};
