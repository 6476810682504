import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { Code }             from "Utils/API";
import { fetchCodes }       from "Actions/App/Client/CodeActions";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import CodeView             from "./CodeView";
import Main                 from "dashboard/dist/Components/Main";
import Content              from "dashboard/dist/Components/Content";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import Downloader           from "dashboard/dist/Components/Downloader";



/**
 * The Code List
 */
class CodeList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=}  params
     * @param {Boolean=} withLoader
     * @returns {Void}
     */
    fetch = (params, withLoader) => {
        const { type, elemID, data } = this.props;
        this.props.fetchCodes(type, elemID, params || data.sort, withLoader);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        this.setState({ action, elemID });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get(), 0);
    }

    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction();
        this.fetch(null, false);
    }


    
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                   } = this.state;
        const { data, params, route, withDetails } = this.props;
        const { list, total, sort, loading       } = data;

        const elemHash = Utils.getValue(list, "codeID", elemID, "hash");

        return <Main withDetails={withDetails}>
            <Header message="CODES_NAME" icon="code" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content>
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="CODES_NONE_AVAILABLE"
                    isLoading={loading}
                >
                    <TableHead>
                        <TableHeader field="tableID"     message="TABLES_SINGULAR" />
                        <TableHeader field="hash"        message="GENERAL_HASH"    grow="2" />
                        <TableHeader field="prints"      message="CODES_PRINTS"    maxWidth="100" align="center" />
                        <TableHeader field="createdTime" message="GENERAL_CREATED" maxWidth="100" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.codeID} elemID={elem.codeID}>
                            <TableCell message={elem.codeName}    />
                            <TableCell message={elem.hash}        />
                            <TableCell message={elem.prints}      />
                            <TableCell message={elem.createdDate} />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction}>
                        <TableAction action="VIEW"   message="CODES_VIEW_TITLE"  />
                        <TableAction action="EXPORT" message="CODES_PRINT_TITLE" />
                    </TableActionList>
                </Table>
            </Content>

            <CodeView
                open={action.isVCE}
                elemID={elemID}
                clientID={params.clientID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <Downloader
                download={action.isExport}
                source={Code.print({ hash : elemHash })}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCodes  : PropTypes.func.isRequired,
        data        : PropTypes.object.isRequired,
        type        : PropTypes.string.isRequired,
        params      : PropTypes.object.isRequired,
        route       : PropTypes.string.isRequired,
        withDetails : PropTypes.bool.isRequired,
        elemID      : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.code,
        };
    }
}

export default connect(CodeList.mapStateToProps, {
    fetchCodes,
})(CodeList);
