import { setSection }       from "Actions/Core/CoreActions";
import { Profile }          from "Utils/API";

// Types
import {
    PROFILE, PROFILE_ELEM,
} from "Utils/Types";



/**
 * Fetches the current Credential's Profile
 * @returns {(dispatch: Function) => Void}
 */
export function fetchProfile() {
    return async (dispatch) => {
        const data = await Profile.get();
        dispatch({ type : PROFILE_ELEM, data });
        dispatch(setSection(PROFILE));
        return data;
    };
}



/**
 * Edits the Credential's Profile
 * @param {Object} data
 * @returns {() => Void}
 */
export function editProfile(data) {
    return () => Profile.edit(data);
}

/**
 * Uploads the Credential's Avatar
 * @param {Object} image
 * @returns {() => Void}
 */
export function uploadProfile(image) {
    return () => Profile.upload({ image });
}

/**
 * Resends the Credential's Confirmation Email
 * @returns {() => Void}
 */
export function sendConfirmation() {
    return () => Profile.sendConfirmation();
}
