import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { User }             from "Utils/API";

// Types
import {
    USER, USER_LOADING,
    USER_LIST, USER_ELEM,
} from "Utils/Types";



/**
 * Fetches the User List
 * @param {String}   type
 * @param {Number}   elemID
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchUsers(type, elemID, params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : USER_LOADING });
        }
        Params.unset(params);
        let data = {};

        switch (type) {
        case "CLIENT":
            params.clientID = elemID;
            data = await User.getAllForClient(params);
            break;
        case "MANAGER":
            data = await User.getAllForManager(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : USER_LIST, data });
        dispatch(setSection(USER));
    };
}

/**
 * Fetches a single User
 * @param {Number} credentialID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchUser(credentialID) {
    return async (dispatch) => {
        const data = await User.getOne({ credentialID });
        dispatch({ type : USER_ELEM, data });
    };
}



/**
 * Edits/Creates a User
 * @param {Object} data
 * @returns {() => Void}
 */
export function editUser(data) {
    return () => User.edit(data);
}

/**
 * Deletes a User
 * @param {Number} credentialID
 * @returns {() => Void}
 */
export function deleteUser(credentialID) {
    return () => User.delete({ credentialID });
}
