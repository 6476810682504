import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Sections
import SupportContainer     from "Components/Core/Support/SupportContainer";
import ProfileContainer     from "Components/App/Profile/ProfileContainer";
import ClientPage           from "Components/App/Client/Client/ClientPage";
import DashboardPage        from "Components/App/Client/Dashboard/DashboardPage";

// Components
import SupportRoute         from "Components/Utils/Route/SupportRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";



/**
 * The Support Router
 */
class SupportRouter extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        if (!this.props.isSupport) {
            return <React.Fragment />;
        }
        return <SwitchRoute>
            <SupportRoute url="PROFILE"          component={ProfileContainer} />
            <SupportRoute url="CLIENT_DASHBOARD" component={DashboardPage}    />
            <SupportRoute url="CLIENT"           component={ClientPage}       />
            <SupportRoute url="NONE"             component={SupportContainer} />
        </SwitchRoute>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isSupport : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isSupport : state.auth.isSupport,
        };
    }
}

export default connect(SupportRouter.mapStateToProps)(SupportRouter);
