// Core Types
export const CORE_LOADING               = "CORE_LOADING";
export const CORE_REDIRECT              = "CORE_REDIRECT";
export const CORE_SECTION               = "CORE_SECTION";
export const CORE_RESULT                = "CORE_RESULT";
export const CORE_MENU_OPEN             = "CORE_MENU_OPEN";
export const CORE_MENU_CLOSE            = "CORE_MENU_CLOSE";
export const CORE_DETAILS_SET           = "CORE_DETAILS_SET";
export const CORE_DETAILS_OPEN          = "CORE_DETAILS_OPEN";
export const CORE_DETAILS_CLOSE         = "CORE_DETAILS_CLOSE";

export const AUTH_CURRENT_USER          = "AUTH_CURRENT_USER";
export const AUTH_CHANGE_EMAIL          = "AUTH_CHANGE_EMAIL";

export const MEDIA_LOADING              = "MEDIA_LOADING";
export const MEDIA_LIST                 = "MEDIA_LIST";



// Client Types
export const CLIENT                     = "CLIENT";
export const CLIENT_LOADING             = "CLIENT_LOADING";
export const CLIENT_LIST                = "CLIENT_LIST";
export const CLIENT_ELEM                = "CLIENT_ELEM";
export const CLIENT_EDIT                = "CLIENT_EDIT";

export const USER                       = "USER";
export const USER_LOADING               = "USER_LOADING";
export const USER_LIST                  = "USER_LIST";
export const USER_ELEM                  = "USER_ELEM";
export const USER_EDIT                  = "USER_EDIT";

export const TABLE                      = "TABLE";
export const TABLE_LOADING              = "TABLE_LOADING";
export const TABLE_LIST                 = "TABLE_LIST";
export const TABLE_ELEM                 = "TABLE_ELEM";
export const TABLE_EDIT                 = "TABLE_EDIT";

export const CODE                       = "CODE";
export const CODE_LOADING               = "CODE_LOADING";
export const CODE_LIST                  = "CODE_LIST";
export const CODE_ELEM                  = "CODE_ELEM";

export const OPTIONS                    = "OPTIONS";
export const OPTIONS_LOADING            = "OPTIONS_LOADING";
export const OPTIONS_ELEM               = "OPTIONS_ELEM";

export const PAYMENT                    = "PAYMENT";
export const PAYMENT_LOADING            = "PAYMENT_LOADING";
export const PAYMENT_LIST               = "PAYMENT_LIST";



// Product Types
export const PRODUCT                    = "PRODUCT";
export const PRODUCT_LOADING            = "PRODUCT_LOADING";
export const PRODUCT_LIST               = "PRODUCT_LIST";
export const PRODUCT_ELEM               = "PRODUCT_ELEM";
export const PRODUCT_EDIT               = "PRODUCT_EDIT";

export const CATEGORY                   = "CATEGORY";
export const CATEGORY_LOADING           = "CATEGORY_LOADING";
export const CATEGORY_LIST              = "CATEGORY_LIST";
export const CATEGORY_ELEM              = "CATEGORY_ELEM";
export const CATEGORY_EDIT              = "CATEGORY_EDIT";

export const SUBCATEGORY                = "SUBCATEGORY";
export const SUBCATEGORY_LOADING        = "SUBCATEGORY_LOADING";
export const SUBCATEGORY_LIST           = "SUBCATEGORY_LIST";
export const SUBCATEGORY_ELEM           = "SUBCATEGORY_ELEM";
export const SUBCATEGORY_EDIT           = "SUBCATEGORY_EDIT";

export const OPTIONAL                   = "OPTIONAL";
export const OPTIONAL_LOADING           = "OPTIONAL_LOADING";
export const OPTIONAL_LIST              = "OPTIONAL_LIST";
export const OPTIONAL_ELEM              = "OPTIONAL_ELEM";
export const OPTIONAL_EDIT              = "OPTIONAL_EDIT";
export const OPTIONAL_ITEM              = "OPTIONAL_ITEM";

export const SIZE                       = "SIZE";
export const SIZE_LOADING               = "SIZE_LOADING";
export const SIZE_LIST                  = "SIZE_LIST";
export const SIZE_ELEM                  = "SIZE_ELEM";
export const SIZE_EDIT                  = "SIZE_EDIT";



// Dashboard Types
export const DASHBOARD                  = "DASHBOARD";
export const DASHBOARD_LOADING          = "DASHBOARD_LOADING";
export const DASHBOARD_ELEM             = "DASHBOARD_ELEM";
export const DASHBOARD_UPDATE           = "DASHBOARD_UPDATE";



// Ticket Types
export const TICKET                     = "TICKET";
export const TICKET_LOADING             = "TICKET_LOADING";
export const TICKET_LIST                = "TICKET_LIST";
export const TICKET_ELEM                = "TICKET_ELEM";
export const TICKET_EDIT                = "TICKET_EDIT";
export const TICKET_ASSIGN              = "TICKET_ASSIGN";

export const TICKET_TYPE                = "TICKET_TYPE";
export const TICKET_TYPE_LOADING        = "TICKET_TYPE_LOADING";
export const TICKET_TYPE_LIST           = "TICKET_TYPE_LIST";
export const TICKET_TYPE_ELEM           = "TICKET_TYPE_ELEM";
export const TICKET_TYPE_EDIT           = "TICKET_TYPE_EDIT";



// Settings Types
export const ADMIN                      = "ADMIN";
export const ADMIN_LOADING              = "ADMIN_LOADING";
export const ADMIN_LIST                 = "ADMIN_LIST";
export const ADMIN_ELEM                 = "ADMIN_ELEM";

export const PLAN                       = "PLAN";
export const PLAN_LOADING               = "PLAN_LOADING";
export const PLAN_LIST                  = "PLAN_LIST";
export const PLAN_ELEM                  = "PLAN_ELEM";
export const PLAN_EDIT                  = "PLAN_EDIT";

export const EMAIL_TEMPLATE             = "EMAIL_TEMPLATE";
export const EMAIL_TEMPLATE_LOADING     = "EMAIL_TEMPLATE_LOADING";
export const EMAIL_TEMPLATE_LIST        = "EMAIL_TEMPLATE_LIST";
export const EMAIL_TEMPLATE_ELEM        = "EMAIL_TEMPLATE_ELEM";

export const ACTION_LOG                 = "ACTION_LOG";
export const ACTION_LOG_LOADING         = "ACTION_LOG_LOADING";
export const ACTION_LOG_LIST            = "ACTION_LOG_LIST";

export const ERROR_LOG                  = "ERROR_LOG";
export const ERROR_LOG_LOADING          = "ERROR_LOG_LOADING";
export const ERROR_LOG_LIST             = "ERROR_LOG_LIST";
export const ERROR_LOG_ELEM             = "ERROR_LOG_ELEM";



// Profile Types
export const PROFILE                    = "PROFILE";
export const PROFILE_ELEM               = "PROFILE_ELEM";
