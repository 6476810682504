import React                from "react";
import PropTypes            from "prop-types";

// Sections
import ClientView           from "Components/App/Client/Client/ClientView";
import TicketList           from "Components/App/Ticket/Ticket/TicketList";
import UserList             from "Components/App/Client/User/UserList";
import ProductList          from "Components/App/Product/Product/ProductList";
import OptionalList         from "Components/App/Product/Optional/OptionalList";
import TableList            from "Components/App/Client/Table/TableList";

// Components
import PrimaryNav           from "Components/Utils/Side/PrimaryNav";
import ManagerRoute         from "Components/Utils/Route/ManagerRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";



/**
 * The Manager Container
 */
class ManagerContainer extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { route } = this.props;
        
        return <>
            <PrimaryNav path={route}>
                <NavigationItem message="GENERAL_INFO"   url="INFORMATION" icon="client"    />
                <NavigationItem message="TICKETS_NAME"   url="TICKETS"     icon="ticket"    />
                <NavigationItem message="USERS_NAME"     url="USERS"       icon="user"      />
                <NavigationItem message="PRODUCTS_NAME"  url="PRODUCTS"    icon="product"   />
                <NavigationItem message="OPTIONALS_NAME" url="OPTIONALS"   icon="optional"  />
                <NavigationItem message="TABLES_NAME"    url="TABLES"      icon="table"     />
                <NavigationItem message="DASHBOARD_NAME" url="DASHBOARD"   icon="dashboard" />
            </PrimaryNav>
            <SwitchRoute type="MANAGER">
                <ManagerRoute url="INFORMATION" component={ClientView}   exact />
                <ManagerRoute url="TICKETS"     component={TicketList}   exact />
                <ManagerRoute url="USERS"       component={UserList}     exact />
                <ManagerRoute url="PRODUCTS"    component={ProductList}  exact />
                <ManagerRoute url="OPTIONALS"   component={OptionalList} exact />
                <ManagerRoute url="TABLES"      component={TableList}    exact />
            </SwitchRoute>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        route : PropTypes.string.isRequired,
    }
}

export default ManagerContainer;
