import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";

// Dialogs
import TicketView           from "./TicketView";
import TicketEdit           from "./TicketEdit";
import TicketAssign         from "./TicketAssign";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Content              from "dashboard/dist/Components/Content";
import Header               from "dashboard/dist/Components/Header";
import ActionList           from "dashboard/dist/Components/ActionList";
import TabList              from "dashboard/dist/Components/TabList";
import TabItem              from "dashboard/dist/Components/TabItem";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";
import TableActionList      from "dashboard/dist/Components/TableActionList";
import TableAction          from "dashboard/dist/Components/TableAction";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";

// Actions
import {
    fetchTickets, deleteTicket,
    openTicket, closeTicket,
    muteTicket, unmuteTicket,
} from "Actions/App/Ticket/TicketActions";



/**
 * The Ticket List
 */
class TicketList extends React.Component {
    // The Current State
    state = {
        action : Action.get(),
        elemID : 0,
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=}  params
     * @param {Boolean=} withLoader
     * @returns {Void}
     */
    fetch = (params, withLoader) => {
        const { type, elemID, data } = this.props;
        this.props.fetchTickets(type, elemID, params || data.sort, withLoader);
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @param {Number} elemID
     * @returns {Void}
     */
    startAction = (action, elemID) => {
        if (action.isTab) {
            const params = { ...this.props.data.sort, filter : elemID, page : 0 };
            this.fetch(params);
        } else if (action.isMute) {
            this.muteElem(elemID);
        } else if (action.isUnmute) {
            this.unmuteElem(elemID);
        } else {
            this.setState({ action, elemID });
        }
    }

    /**
     * Ends an Action
     * @param {Boolean=} update
     * @returns {Void}
     */
    endAction = (update) => {
        this.startAction(Action.get(), 0);
        if (update) {
            this.fetch(null, false);
        }
    }

    /**
     * Ends an Edit
     * @param {Object=} response
     * @returns {Void}
     */
    endEdit = (response) => {
        this.endAction(!!response.success);
    }



    /**
     * Returns true if it can edit the Ticket
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canEdit(elemID) {
        const { list } = this.props.data;
        return Utils.getValue(list, "ticketID", elemID, "canEdit");
    }

    /**
     * Returns true if it can assign the Ticket
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canAssign(elemID) {
        const { list, canAssign } = this.props.data;
        return canAssign && Utils.getValue(list, "ticketID", elemID, "canEdit");
    }

    /**
     * Returns true if it can open the Ticket
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canOpen(elemID) {
        const { list } = this.props.data;
        return Utils.getValue(list, "ticketID", elemID, "canOpen");
    }

    /**
     * Returns true if it can close the Ticket
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canClose(elemID) {
        const { list } = this.props.data;
        return Utils.getValue(list, "ticketID", elemID, "canClose");
    }

    /**
     * Returns true if it can mute the Ticket
     * @param {Number} elemID
     * @returns {Boolean}
     */
    canMute(elemID) {
        const { list } = this.props.data;
        const isMute = Utils.getValue(list, "ticketID", elemID, "isMute");
        return !isMute;
    }



    /**
     * Handles the Edit Submit
     * @returns {Void}
     */
    editElem = () => {
        this.endAction(true);
    }
    
    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const elemID   = this.state.elemID;
        if (this.canEdit(elemID)) {
            const response = await this.props.deleteTicket(elemID);
            this.endEdit(response);
        }
    }

    /**
     * Handles the Open Submit
     * @returns {Promise}
     */
    openElem = async () => {
        const elemID = this.state.elemID;
        if (this.canOpen(elemID)) {
            const response = await this.props.openTicket(elemID);
            this.endEdit(response);
        }
    }

    /**
     * Handles the Close Submit
     * @returns {Promise}
     */
    closeElem = async () => {
        const elemID = this.state.elemID;
        if (this.canClose(elemID)) {
            const response = await this.props.closeTicket(elemID);
            this.endEdit(response);
        }
    }

    /**
     * Handles the Mute Submit
     * @param {Number} elemID
     * @returns {Promise}
     */
    muteElem = async (elemID) => {
        if (this.canMute(elemID)) {
            const result = await this.props.muteTicket(elemID);
            this.endEdit(result);
        }
    }

    /**
     * Handles the Unmute Submit
     * @param {Number} elemID
     * @returns {Promise}
     */
    unmuteElem = async (elemID) => {
        if (!this.canMute(elemID)) {
            const result = await this.props.unmuteTicket(elemID);
            this.endEdit(result);
        }
    }


    
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elemID                         } = this.state;
        const { data, type, params, route, withDetails } = this.props;
        const { canEdit, list, total, sort, loading    } = data;

        const showClient = type === "admin";
        const elemName   = Utils.getValue(list, "ticketID", elemID, "subject");

        return <Main withDetails={withDetails}>
            <Header message="TICKETS_NAME" icon="ticket" route={route}>
                <ActionList data={data} onAction={this.startAction} />
            </Header>
            <Content>
                <TabList variant="light" selected={sort.filter} onAction={this.startAction}>
                    <TabItem message="TICKETS_OPEN_TAB"  status="OPEN"   />
                    <TabItem message="TICKETS_CLOSE_TAB" status="CLOSED" />
                </TabList>
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="TICKETS_NONE_AVAILABLE"
                    isLoading={loading}
                    hasTabs
                >
                    <TableHead>
                        <TableHeader field="ticketTypeID" message="TICKET_TYPES_SINGULAR" />
                        <TableHeader field="subject"      message="GENERAL_SUBJECT"       grow="2" />
                        <TableHeader field="adminID"      message="TICKETS_ASSIGNED"      />
                        <TableHeader field="clientID"     message="CLIENTS_SINGULAR"      isHidden={!showClient} />
                        <TableHeader field="lastReply"    message="TICKETS_LAST_REPLY"    />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow key={elem.ticketID} elemID={elem.ticketID}>
                            <TableCell message={elem.ticketTypeName}  />
                            <TableCell message={elem.subject}         />
                            <TableCell message={elem.adminName}       />
                            <TableCell message={elem.clientName}      />
                            <TableCell message={elem.lastReplyString} />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={this.startAction} canEdit={canEdit}>
                        <TableAction action="VIEW"   message="TICKETS_VIEW_TITLE"   />
                        <TableAction action="EDIT"   message="TICKETS_EDIT_TITLE"   hide={(elemID) => !this.canEdit(elemID)}   />
                        <TableAction action="DELETE" message="TICKETS_DELETE_TITLE" hide={(elemID) => !this.canEdit(elemID)}   />
                        <TableAction action="ASSIGN" message="TICKETS_ASSIGN_TITLE" hide={(elemID) => !this.canAssign(elemID)} />
                        <TableAction action="OPEN"   message="TICKETS_OPEN_TITLE"   hide={(elemID) => !this.canOpen(elemID)}   />
                        <TableAction action="CLOSE"  message="TICKETS_CLOSE_TITLE"  hide={(elemID) => !this.canClose(elemID)}  />
                        <TableAction action="MUTE"   message="TICKETS_MUTE_TITLE"   hide={(elemID) => !this.canMute(elemID)}   />
                        <TableAction action="UNMUTE" message="TICKETS_UNMUTE_TITLE" hide={(elemID) => this.canMute(elemID)}    />
                    </TableActionList>
                </Table>
            </Content>

            <TicketView
                open={action.isView}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <TicketEdit
                open={action.isCE}
                elemID={elemID}
                clientID={params.clientID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <TicketAssign
                open={action.isAssign}
                elemID={elemID}
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            
            <ConfirmDialog
                open={action.isOpen}
                icon="status"
                title="TICKETS_OPEN_TITLE"
                message="TICKETS_OPEN_TEXT"
                content={elemName}
                onSubmit={this.openElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isClose}
                icon="status"
                title="TICKETS_CLOSE_TITLE"
                message="TICKETS_CLOSE_TEXT"
                content={elemName}
                onSubmit={this.closeElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title="TICKETS_DELETE_TITLE"
                message="TICKETS_DELETE_TEXT"
                content={elemName}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchTickets : PropTypes.func.isRequired,
        deleteTicket : PropTypes.func.isRequired,
        openTicket   : PropTypes.func.isRequired,
        closeTicket  : PropTypes.func.isRequired,
        muteTicket   : PropTypes.func.isRequired,
        unmuteTicket : PropTypes.func.isRequired,
        data         : PropTypes.object.isRequired,
        type         : PropTypes.string.isRequired,
        params       : PropTypes.object.isRequired,
        route        : PropTypes.string.isRequired,
        withDetails  : PropTypes.bool,
        elemID       : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.ticket,
        };
    }
}

export default connect(TicketList.mapStateToProps, {
    fetchTickets, deleteTicket,
    openTicket, closeTicket,
    muteTicket, unmuteTicket,
})(TicketList);
