import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import MediaType            from "dashboard/dist/Core/MediaType";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import MediaField           from "Components/Utils/Media/MediaField";
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchCategory, fetchCategoryEdit, editCategory,
} from "Actions/App/Product/CategoryActions";



/**
 * The Category Edit Dialog
 */
class CategoryEdit extends React.Component {
    // The Initial Data
    initialData = {
        clientID        : 0,
        categoryID      : 0,
        name            : "",
        description     : "",
        iconImage       : "",
        backgroundImage : "",
        marginTop       : 0,
        fontColor       : "",
        position        : "",
        status          : Status.getID("ACTIVE"),
        observations    : "",
    }

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : false,
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, clientID, position, fetchCategory, fetchCategoryEdit } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchCategory(elemID);
                loading = true;
            // Load edit data
            } else if (clientID) {
                fetchCategoryEdit(clientID);
                loading = true;
            }
        
        // Data Updated
        } else if (prevProps.edition !== edition) {
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
            } else {
                data = { ...this.initialData, clientID, position };
            }
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.editCategory(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose } = this.props;
        const { data, loading, errors } = this.state;
        
        return <EditDialog
            open={open}
            icon="category"
            title={!!elemID ? "CATEGORIES_EDIT_TITLE" : "CATEGORIES_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                name="name"
                label="CATEGORIES_ITS_NAME"
                value={data.name}
                error={errors.name}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="textarea"
                name="description"
                label="CATEGORIES_DESCRIPTION"
                value={data.description}
                error={errors.description}
                onChange={this.handleChange}
            />
            <Columns>
                <MediaField
                    mediaType={MediaType.IMAGE}
                    mediaPath="categorias"
                    name="iconImage"
                    label="CATEGORIES_ICON"
                    value={data.iconImage}
                    error={errors.iconImage}
                    onChange={this.handleChange}
                />
                <MediaField
                    mediaType={MediaType.IMAGE}
                    mediaPath="categorias"
                    name="backgroundImage"
                    label="CATEGORIES_BACKGROUND"
                    value={data.backgroundImage}
                    error={errors.backgroundImage}
                    onChange={this.handleChange}
                />

                <InputField
                    type="number"
                    name="marginTop"
                    label="CATEGORIES_MARGIN_TOP"
                    value={data.marginTop}
                    onChange={this.handleChange}
                    error={errors.marginTop}
                    shrink
                />
                <InputField
                    type="color"
                    name="fontColor"
                    label="CATEGORIES_FONT_COLOR"
                    value={data.fontColor}
                    onChange={this.handleChange}
                    error={errors.fontColor}
                    shrink
                />

                <InputField
                    type="number"
                    name="position"
                    label="GENERAL_POSITION"
                    value={data.position}
                    onChange={this.handleChange}
                    error={errors.position}
                    shrink
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    value={data.status}
                    error={errors.status}
                    onChange={this.handleChange}
                    options={Status.getFemSelect()}
                    withNone
                    isRequired
                    shrink
                />
            </Columns>
            <InputField
                type="textarea"
                name="observations"
                label="GENERAL_OBSERVATIONS"
                value={data.observations}
                onChange={this.handleChange}
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchCategory     : PropTypes.func.isRequired,
        fetchCategoryEdit : PropTypes.func.isRequired,
        editCategory      : PropTypes.func.isRequired,
        open              : PropTypes.bool.isRequired,
        onClose           : PropTypes.func.isRequired,
        onSubmit          : PropTypes.func.isRequired,
        edition           : PropTypes.number.isRequired,
        position          : PropTypes.number.isRequired,
        elem              : PropTypes.object.isRequired,
        elemID            : PropTypes.number,
        clientID          : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition  : state.category.edition,
            position : state.category.position,
            elem     : state.category.elem,
        };
    }
}

export default connect(CategoryEdit.mapStateToProps, {
    fetchCategory, fetchCategoryEdit, editCategory,
})(CategoryEdit);
