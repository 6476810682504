import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { resetPass }        from "Actions/Core/AuthActions";
import Href                 from "dashboard/dist/Core/Href";

// Components
import AuthForm             from "Components/Auth/AuthForm";
import IconField            from "dashboard/dist/Components/IconField";



/**
 * The Reset Password Page Component
 */
class ResetPassPage extends React.Component {
    // The Current State
    state = {
        data    : {
            password        : "",
            confirmPassword : "",
        },
        loading : false,
        errors  : {},
    }
    
    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }
    
    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { match, resetPass } = this.props;
        const { data, loading    } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                data.code = match.params.code;
                await resetPass(data);
                Href.goto("LOGIN");
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }

    

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, loading, errors } = this.state;

        return <AuthForm
            onSubmit={this.handleSubmit}
            title="RESET_TITLE"
            help="RESET_HELP"
            error={errors.form}
            url="LOGIN"
            link="RESET_ACCOUNT"
            button="RESET_BUTTON"
            isLoading={loading}
        >
            <IconField
                className="auth-field"
                type="password"
                name="password"
                icon="password"
                placeholder="USERS_PASSWORD"
                autoComplete="new-password"
                value={data.password}
                error={errors.password}
                onChange={this.handleChange}
                isRequired
            />
            <IconField
                className="auth-field"
                type="password"
                name="confirmPassword"
                icon="password"
                placeholder="USERS_PASSWORD_CONFIRM"
                autoComplete="new-password"
                value={data.confirmPassword}
                error={errors.confirmPassword}
                onChange={this.handleChange}
                isRequired
            />
        </AuthForm>;
    }

    

    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        resetPass : PropTypes.func.isRequired,
        match     : PropTypes.object.isRequired,
    }
}

export default connect(null, {
    resetPass,
})(ResetPassPage);
