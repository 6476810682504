import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import MediaType            from "dashboard/dist/Core/MediaType";
import Action               from "dashboard/dist/Core/Action";

// Components
import DropZone             from "dashboard/dist/Components/DropZone";
import MediaList            from "dashboard/dist/Components/MediaList";
import PromptDialog         from "dashboard/dist/Components/PromptDialog";
import DeleteDialog         from "dashboard/dist/Components/DeleteDialog";

// Actions
import {
    fetchMedia, createDirectory, uploadMedia, renameMedia, deleteMedia,
} from "Actions/Core/MediaActions";



/**
 * The Media Content
 */
class MediaContent extends React.Component {
    // The Current State
    state = {
        action   : Action.get(),
        elem     : {},
        selected : "",
    }

    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        const { mediaType, path, selected, passedRef } = this.props;
        const parts = selected.split("/");
        this.setState({ selected : parts[parts.length - 1] });
        this.props.fetchMedia(mediaType, path, selected);
        passedRef.current = this;
    }

    /**
     * Fetch the content
     * @returns {Void}
     */
    fetch = () => {
        const { mediaType, data } = this.props;
        this.props.fetchMedia(mediaType, data.path);
    }

    /**
     * Goes to the given Path
     * @param {String} path
     * @returns {Void}
     */
    goto = (path) => {
        const { mediaType } = this.props;
        this.props.fetchMedia(mediaType, path);
    }

    /**
     * Handles the Upload Submit
     * @param {Object[]} files
     * @returns {Promise}
     */
    onUploadSubmit = async (files) => {
        const path = this.props.data.path;
        this.endAction();
        for (const file of files) {
            await this.props.uploadMedia(path, file);
        }
        this.fetch();
    }

    /**
     * Handles the Create Submit
     * @param {String} name
     * @returns {Promise}
     */
    createElem = async (name) => {
        const path = this.props.data.path;
        this.endAction();
        const result = await this.props.createDirectory(path, name);
        if (result.success) {
            this.fetch();
        }
    }

    /**
     * Handles the Edit Submit
     * @param {String} newName
     * @returns {Promise}
     */
    editElem = async (newName) => {
        const path    = this.props.data.path;
        const oldName = this.state.elem.name;
        this.endAction();
        const result = await this.props.renameMedia(path, oldName, newName);
        if (result.success) {
            this.fetch();
        }
    }

    /**
     * Handles the Delete Submit
     * @returns {Promise}
     */
    deleteElem = async () => {
        const path = this.props.data.path;
        const name = this.state.elem.name;
        this.endAction();
        const result = await this.props.deleteMedia(path, name);
        if (result.success) {
            this.handleUnselect();
            this.fetch();
        }
    }



    /**
     * Starts an Action
     * @param {Object}  action
     * @param {Object=} elem
     * @returns {Void}
     */
    startAction = (action, elem = {}) => {
        this.setState({ action, elem });
    }

    /**
     * Ends an Action
     * @returns {Void}
     */
    endAction = () => {
        this.startAction(Action.get());
    }

    /**
     * Handles the Action
     * @param {Object} action
     * @param {Object} elem
     * @returns {Void}
     */
    handleAction = (action, elem) => {
        if (action.isSelect) {
            if (elem.isDir || elem.isBack) {
                this.goto(elem.path);
            } else if (this.props.canSelect) {
                if (this.state.selected !== elem.name) {
                    this.setState({ selected : elem.name });
                    this.selectMedia(elem.path);
                } else {
                    this.handleUnselect();
                }
            }
        } else if (action.isView && elem.isDir) {
            this.goto(elem.path);
        } else {
            this.setState({ action, elem });
        }
    }

    /**
     * Handles the Unselect
     * @returns {Void}
     */
    handleUnselect = () => {
        this.setState({ selected : "" });
        this.selectMedia();
    }

    /**
     * Sends the Selection
     * @param {String=} file
     * @returns {Void}
     */
    selectMedia(file) {
        if (this.props.onSelect) {
            this.props.onSelect(file);
        }
    }
    
    

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { action, elem, selected                  } = this.state;
        const { data, mediaType, withSpacing, canSelect } = this.props;
        const { canEdit, list, loading                  } = data;

        return <>
            <DropZone
                open={action.isUpload}
                onStart={this.startAction}
                onEnd={this.endAction}
                onDrop={this.onUploadSubmit}
                onlyImages={MediaType.onlyImages(mediaType)}
            />
            <MediaList
                onAction={this.handleAction}
                isLoading={loading}
                canEdit={canEdit}
                canSelect={canSelect}
                withSpacing={withSpacing}
                selected={selected}
                items={list}
            />

            <PromptDialog
                open={action.isCreate}
                icon="directory"
                title="MEDIA_CREATE_TITLE"
                message="GENERAL_NAME"
                onSubmit={this.createElem}
                onClose={this.endAction}
            />
            <PromptDialog
                open={action.isEdit}
                icon="edit"
                title={elem.isDir ? "MEDIA_EDIT_DIR_TITLE" : "MEDIA_EDIT_FILE_TITLE"}
                message="GENERAL_NAME"
                initialValue={elem.name}
                spellCheck="false"
                onSubmit={this.editElem}
                onClose={this.endAction}
            />
            <DeleteDialog
                open={action.isDelete}
                title={elem.isDir ? "MEDIA_DELETE_DIR_TITLE" : "MEDIA_DELETE_FILE_TITLE"}
                message={elem.isDir ? "MEDIA_DELETE_DIR_TEXT" : "MEDIA_DELETE_FILE_TEXT"}
                content={elem.name}
                onSubmit={this.deleteElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchMedia      : PropTypes.func.isRequired,
        createDirectory : PropTypes.func.isRequired,
        uploadMedia     : PropTypes.func.isRequired,
        renameMedia     : PropTypes.func.isRequired,
        deleteMedia     : PropTypes.func.isRequired,
        data            : PropTypes.object.isRequired,
        mediaType       : PropTypes.string,
        path            : PropTypes.string,
        selected        : PropTypes.string,
        onSelect        : PropTypes.func,
        canSelect       : PropTypes.bool,
        withSpacing     : PropTypes.bool,
        passedRef       : PropTypes.any,
    }

    /**
     * The Default Properties
     * @type {Object} defaultProps
     */
    static defaultProps = {
        mediaType : MediaType.ANY,
        path      : "",
        selected  : "",
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.media,
        };
    }
}

export default connect(MediaContent.mapStateToProps, {
    fetchMedia, createDirectory, uploadMedia, renameMedia, deleteMedia,
})(MediaContent);
