import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { closeMenu }        from "Actions/Core/CoreActions";

// Sections
import ClientList           from "Components/App/Client/Client/ClientList";
import TicketList           from "Components/App/Ticket/Ticket/TicketList";

// Components
import PrimaryNav           from "Components/Utils/Side/PrimaryNav";
import SupportRoute         from "Components/Utils/Route/SupportRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";
import NavigationItem       from "dashboard/dist/Components/NavigationItem";



/**
 * The Support Container
 */
class SupportContainer extends React.Component {
    /**
     * Do the Render
     * @returns {Object}
     */
    render() {
        const { route } = this.props;
        
        return <>
            <PrimaryNav path={route}>
                <NavigationItem message="CLIENTS_NAME" url="CLIENTS" icon="client" />
                <NavigationItem message="TICKETS_NAME" url="TICKETS" icon="ticket" />
            </PrimaryNav>
            <SwitchRoute type="SUPPORT">
                <SupportRoute url="CLIENTS" component={ClientList} exact />
                <SupportRoute url="TICKETS" component={TicketList} exact />
            </SwitchRoute>
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        closeMenu : PropTypes.func.isRequired,
        route     : PropTypes.string.isRequired,
    }
}

export default connect(null, {
    closeMenu,
})(SupportContainer);
