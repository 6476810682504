import React                from "react";
import PropTypes            from "prop-types";

// Components
import MediaDialog          from "Components/Utils/Media/MediaDialog";
import InputField           from "dashboard/dist/Components/InputField";



/**
 * The Media Field
 */
class MediaField extends React.Component {
    // The Current State
    state = {
        showMedia : false,
    }

    /**
     * Handles the Media Change
     * @param {*} value
     * @returns {Void}
     */
    handleMedia = (value) => {
        this.props.onChange(this.props.name, value);
        this.closeMedia();
    }

    /**
     * Opens the Media Dialog
     * @param {React.MouseEvent} e
     * @returns {Void}
     */
    openMedia = (e) => {
        this.setState({ showMedia : true });
        e.preventDefault();
    }

    /**
     * Closes the Media Dialog
     * @returns {Void}
     */
    closeMedia = () => {
        this.setState({ showMedia : false });
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { isHidden, value, isRequired, mediaPath, mediaType } = this.props;
        const { showMedia } = this.state;

        if (isHidden) {
            return <React.Fragment />;
        }
        return <>
            <InputField
                {...this.props}
                type="media"
                onMedia={this.openMedia}
                withLabel
            />
            <MediaDialog
                open={showMedia}
                mediaType={mediaType}
                path={mediaPath}
                selected={value}
                isRequired={isRequired}
                onSubmit={this.handleMedia}
                onClose={this.closeMedia}
            />
        </>;
    }


    
    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isHidden    : PropTypes.bool,
        className   : PropTypes.string,
        name        : PropTypes.string,
        label       : PropTypes.string,
        placeholder : PropTypes.string,
        value       : PropTypes.any,
        isRequired  : PropTypes.bool,
        onChange    : PropTypes.func.isRequired,
        mediaPath   : PropTypes.string,
        mediaType   : PropTypes.string,
        error       : PropTypes.string,
        helperText  : PropTypes.string,
        tabIndex    : PropTypes.string,
        noMargin    : PropTypes.bool,
        fullWidth   : PropTypes.bool,
        autoFocus   : PropTypes.bool,
    }

    /**
     * The Default Properties
     * @typedef {Object} defaultProps
     */
    static defaultProps = {
        className  : "",
        isRequired : false,
        noMargin   : false,
        fullWidth  : false,
        autoFocus  : false,
    }
}

export default MediaField;
