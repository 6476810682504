import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Payment }          from "Utils/API";

// Types
import {
    PAYMENT, PAYMENT_LOADING,
    PAYMENT_LIST,
} from "Utils/Types";



/**
 * Fetches the Payment List
 * @param {String}   type
 * @param {Number}   elemID
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchPayments(type, elemID, params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : PAYMENT_LOADING });
        }
        Params.unset(params);
        let data = {};

        switch (type) {
        case "CLIENT":
            params.clientID = elemID;
            data = await Payment.getAllForClient(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : PAYMENT_LIST, data });
        dispatch(setSection(PAYMENT));
    };
}
