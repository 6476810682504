import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import Status               from "dashboard/dist/Core/Status";
import MediaType            from "dashboard/dist/Core/MediaType";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import MediaField           from "Components/Utils/Media/MediaField";
import EditDialog           from "dashboard/dist/Components/EditDialog";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    fetchProduct, fetchProductEdit, editProduct,
} from "Actions/App/Product/ProductActions";



/**
 * The Product Edit Dialog
 */
class ProductEdit extends React.Component {
    // The Initial Data
    initialData = {
        clientID      : 0,
        productID     : 0,
        categoryID    : 0,
        subcategoryID : 0,
        name          : "",
        description   : "",
        bannerImage   : "",
        optionals     : "",
        hasSizes      : 0,
        price         : "",
        sizeData      : {},
        position      : "",
        status        : Status.getID("ACTIVE"),
        observations  : "",
    }

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : false,
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, clientID, position, sizes, fetchProduct, fetchProductEdit } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchProduct(elemID);
                loading = true;
            // Load edit data
            } else if (clientID) {
                fetchProductEdit(clientID);
                loading = true;
            }
        
        // Data Updated
        } else if (prevProps.edition !== edition) {
            const sizeData = {};
            for (const { key } of sizes) {
                sizeData[key] = { isActive : 0, price : 0, status : Status.getID("ACTIVE") };
            }
            if (elemID) {
                data = Utils.extend(this.initialData, elem);
                for (const { sizeID, isActive, price, status } of elem.sizes) {
                    sizeData[sizeID] = { isActive : isActive ? 1 : 0, price, status };
                }
            } else {
                data = { ...this.initialData, clientID, position };
            }
            data.sizeData = sizeData;
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        const { data, errors } = this.state;
        if (name.includes("-")) {
            const parts = name.split("-");
            data.sizeData[parts[1]][parts[0]] = value;
            this.setState({
                data   : { ...data,   ...data.sizeData },
                errors : { ...errors, [name] : ""      },
            });
        } else {
            this.setState({
                data   : { ...data,   [name] : value },
                errors : { ...errors, [name] : ""    },
            });
        }
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const params    = { ...data };
                params.sizeData = JSON.stringify(data.sizeData);
                await this.props.editProduct(params);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { elemID, open, onClose, categories, subcategories, optionals, sizes } = this.props;
        const { data, loading, errors                                              } = this.state;
        
        const catID    = data.categoryID;
        const subcats  = catID && subcategories[catID] ? subcategories[catID] : [];
        const hasSizes = Boolean(data.hasSizes && sizes.length > 0);

        return <EditDialog
            open={open}
            icon="product"
            title={!!elemID ? "PRODUCTS_EDIT_TITLE" : "PRODUCTS_CREATE_TITLE"}
            error={errors.form}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <InputField
                name="name"
                label="PRODUCTS_ITS_NAME"
                value={data.name}
                error={errors.name}
                onChange={this.handleChange}
                isRequired
            />
            <InputField
                type="textarea"
                name="description"
                label="PRODUCTS_DESCRIPTION"
                value={data.description}
                error={errors.description}
                onChange={this.handleChange}
            />
            <MediaField
                mediaType={MediaType.IMAGE}
                mediaPath="productos"
                name="bannerImage"
                label="PRODUCTS_BANNER"
                value={data.bannerImage}
                error={errors.bannerImage}
                onChange={this.handleChange}
            />
            <Columns amount={subcats.length > 0 ? 2 : 1}>
                <InputField
                    type="select"
                    name="categoryID"
                    label="PRODUCTS_CATEGORY"
                    options={categories}
                    value={data.categoryID}
                    error={errors.categoryID}
                    onChange={this.handleChange}
                    withNone
                    isRequired
                />
                <InputField
                    isHidden={subcats.length === 0}
                    type="select"
                    name="subcategoryID"
                    label="PRODUCTS_SUBCATEGORY"
                    options={subcats}
                    value={data.subcategoryID}
                    error={errors.subcategoryID}
                    onChange={this.handleChange}
                    withNone
                    isRequired
                />
            </Columns>
            <InputField
                type="fields"
                inputType="select"
                name="optionals"
                label="PRODUCTS_OPTIONALS"
                fieldButton="PRODUCTS_ADD_OPTIONAL"
                options={optionals}
                value={data.optionals}
                error={errors.optionals}
                onChange={this.handleChange}
                withNone
                isSmall
            />
            <InputField
                isHidden={sizes.length === 0}
                type="toggle"
                name="hasSizes"
                label="PRODUCTS_HAS_SIZES"
                value={data.hasSizes}
                error={errors.hasSizes}
                onChange={this.handleChange}
                smallMargin
            />
            <InputField
                isHidden={hasSizes}
                type="number"
                name="price"
                label="PRODUCTS_PRICE"
                value={data.price}
                error={errors.price}
                onChange={this.handleChange}
                step="0.01"
                isRequired
            />
            <Columns amount="3" isHidden={!hasSizes}>
                {sizes.map((elem) => <React.Fragment key={elem.key}>
                    <InputField
                        type="checkbox"
                        name={`isActive-${elem.key}`}
                        label={elem.value}
                        value={data.sizeData[elem.key] ? data.sizeData[elem.key].isActive : 0}
                        onChange={this.handleChange}
                    />
                    <InputField
                        type="number"
                        name={`price-${elem.key}`}
                        label="GENERAL_PRICE"
                        value={data.sizeData[elem.key] ? data.sizeData[elem.key].price : 0}
                        error={errors[`price-${elem.key}`]}
                        onChange={this.handleChange}
                        step="0.01"
                        isRequired
                        isSmall
                        smallMargin
                        shrink
                    />
                    <InputField
                        type="select"
                        name={`status-${elem.key}`}
                        label="GENERAL_STATUS"
                        value={data.sizeData[elem.key] ? data.sizeData[elem.key].status : Status.getID("ACTIVE")}
                        error={errors[`status-${elem.key}`]}
                        onChange={this.handleChange}
                        options={Status.getSelect()}
                        withNone
                        isRequired
                        isSmall
                        smallMargin
                        shrink
                    />
                </React.Fragment>)}
            </Columns>
            <Columns>
                <InputField
                    type="number"
                    name="position"
                    label="GENERAL_POSITION"
                    value={data.position}
                    error={errors.position}
                    onChange={this.handleChange}
                    shrink
                />
                <InputField
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    value={data.status}
                    error={errors.status}
                    onChange={this.handleChange}
                    options={Status.getSelect()}
                    withNone
                    isRequired
                    shrink
                />
            </Columns>
            <InputField
                type="textarea"
                name="observations"
                label="GENERAL_OBSERVATIONS"
                value={data.observations}
                onChange={this.handleChange}
            />
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchProduct     : PropTypes.func.isRequired,
        fetchProductEdit : PropTypes.func.isRequired,
        editProduct      : PropTypes.func.isRequired,
        open             : PropTypes.bool.isRequired,
        onClose          : PropTypes.func.isRequired,
        onSubmit         : PropTypes.func.isRequired,
        categories       : PropTypes.array.isRequired,
        subcategories    : PropTypes.object.isRequired,
        optionals        : PropTypes.array.isRequired,
        sizes            : PropTypes.array.isRequired,
        edition          : PropTypes.number.isRequired,
        position         : PropTypes.number.isRequired,
        elem             : PropTypes.object.isRequired,
        elemID           : PropTypes.number,
        clientID         : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            categories    : state.product.categories,
            subcategories : state.product.subcategories,
            optionals     : state.product.optionals,
            sizes         : state.product.sizes,
            edition       : state.product.edition,
            position      : state.product.position,
            elem          : state.product.elem,
        };
    }
}

export default connect(ProductEdit.mapStateToProps, {
    fetchProduct, fetchProductEdit, editProduct,
})(ProductEdit);
