import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "dashboard/dist/Core/NLS";
import Href                 from "dashboard/dist/Core/Href";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import Form                 from "dashboard/dist/Components/Form";
import Columns              from "dashboard/dist/Components/Columns";
import InputField           from "dashboard/dist/Components/InputField";
import Button               from "dashboard/dist/Components/Button";

// Actions
import {
    fetchProfile, editProfile,
} from "Actions/App/Profile/ProfileActions";



/**
 * The Settings Form
 */
class SettingsForm extends React.Component {
    // The Initial Data
    initialData = {
        credentialID    : 0,
        firstName       : "",
        lastName        : "",
        phone           : "",
        email           : "",
        dni             : "",
        cuit            : "",
        address         : "",
        birthDate       : "",
        language        : "",
        newPassword     : "",
        confirmPassword : "",
    }

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : true,
        errors  : {},
    }
    
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.props.fetchProfile();
    }

    /**
     * Get the Admin when the Element ID changes
     * @returns {Void}
     */
    componentDidUpdate() {
        const oldElem = this.state.data;
        const newElem = this.props.elem;
        let data = null;
        if (newElem.credentialID !== oldElem.credentialID && !Utils.isEmpty(newElem)) {
            data = Utils.extend(this.initialData, newElem);
        }
        if (data) {
            this.setState({ data, loading : false, errors : {} });
        }
    }
    


    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { elem, editProfile, fetchProfile } = this.props;
        const { data, loading                   } = this.state;

        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                const oldLang = elem.language;
                await editProfile(data);
                const profile = await fetchProfile();
                if (oldLang !== profile.elem.language) {
                    Href.reload("PROFILE", "SETTINGS");
                }
                this.setState({ loading : false });
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }
    
    

    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, loading, errors } = this.state;
        
        return <div className="settings-content">
            <Form className="settings-form white-scrollbars" onSubmit={this.handleSubmit}>
                <Columns>
                    <InputField
                        name="firstName"
                        label="USERS_FIRST_NAME"
                        value={data.firstName}
                        error={errors.firstName}
                        onChange={this.handleChange}
                        isRequired
                    />
                    <InputField
                        name="lastName"
                        label="USERS_LAST_NAME"
                        value={data.lastName}
                        error={errors.lastName}
                        onChange={this.handleChange}
                        isRequired
                    />
                    
                    <InputField
                        type="email"
                        name="email"
                        label="GENERAL_EMAIL"
                        value={data.email}
                        error={errors.email}
                        onChange={this.handleChange}
                        autoComplete="new-password"
                        isRequired
                    />
                    <InputField
                        name="phone"
                        label="USERS_PHONE"
                        value={data.phone}
                        error={errors.phone}
                        onChange={this.handleChange}
                    />
                
                    <InputField
                        name="dni"
                        label="USERS_DNI"
                        value={data.dni}
                        error={errors.dni}
                        onChange={this.handleChange}
                    />
                    <InputField
                        name="cuit"
                        label="USERS_CUIT"
                        value={data.cuit}
                        error={errors.cuit}
                        onChange={this.handleChange}
                    />

                    <InputField
                        name="address"
                        label="USERS_ADRRESS"
                        value={data.address}
                        error={errors.address}
                        onChange={this.handleChange}
                    />
                    <InputField
                        type="date"
                        name="birthDate"
                        label="USERS_BIRTHDATE"
                        value={data.birthDate}
                        error={errors.birthDate}
                        onChange={this.handleChange}
                    />

                    <InputField
                        className="columns-double"
                        type="select"
                        name="language"
                        label="USERS_LANGUAGE"
                        options="LANGUAGES"
                        value={data.language}
                        error={errors.language}
                        onChange={this.handleChange}
                        withNone
                        isRequired
                    />
                </Columns>
                
                <h3 className="settings-password">
                    {NLS.get("ACCOUNT_CHANGE_PASSWORD")}
                </h3>
                <Columns>
                    <InputField
                        type="password"
                        name="newPassword"
                        label="USERS_NEW_PASSWORD"
                        value={data.newPassword}
                        error={errors.newPassword}
                        onChange={this.handleChange}
                        autoComplete="new-password"
                    />
                    <InputField
                        type="password"
                        name="confirmPassword"
                        label="USERS_PASSWORD_CONFIRM"
                        value={data.confirmPassword}
                        error={errors.confirmPassword}
                        onChange={this.handleChange}
                        autoComplete="new-password"
                    />
                </Columns>
            </Form>
            <div className="settings-bottom">
                <Button
                    variant="primary"
                    className="settings-button"
                    message="GENERAL_SAVE"
                    onClick={this.handleSubmit}
                    isDisabled={loading}
                />
            </div>
        </div>;
    }
    


    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchProfile : PropTypes.func.isRequired,
        editProfile  : PropTypes.func.isRequired,
        elem         : PropTypes.object.isRequired,
    }
    
    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            elem : state.profile.elem,
        };
    }
}

export default connect(SettingsForm.mapStateToProps, {
    fetchProfile, editProfile,
})(SettingsForm);
