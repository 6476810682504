export default {

    TITLE                           : "QaRta",
    LANGUAGES                       : { "es" : "Español", "en" : "English" },



    // General
    GENERAL_ADD                     : "Add",
    GENERAL_ADD_FIELD               : "Add field",
    GENERAL_ACCEPT                  : "Aceept",
    GENERAL_ACCESS                  : "Access",
    GENERAL_BIRTHDATE               : "Birthdate",
    GENERAL_CANCEL                  : "Cancel",
    GENERAL_CLOSE                   : "Close",
    GENERAL_CODE                    : "Code",
    GENERAL_COLUMN                  : "Column",
    GENERAL_CREATE                  : "Create",
    GENERAL_CREATED                 : "Created",
    GENERAL_CREATED_FEM             : "Created",
    GENERAL_CREATED_TIME            : "Created date",
    GENERAL_CREATOR                 : "Created by",
    GENERAL_CREATOR_FEM             : "Created by",
    GENERAL_CUIT                    : "CUIT",
    GENERAL_DAY                     : "Day",
    GENERAL_DELETE                  : "Delete",
    GENERAL_DESCRIPTION             : "Description",
    GENERAL_DNI                     : "DNI",
    GENERAL_EDIT                    : "Edit",
    GENERAL_EMAIL                   : "Email",
    GENERAL_END                     : "Close",
    GENERAL_EXPORT                  : "Export",
    GENERAL_GENERATE                : "Generate",
    GENERAL_FILE                    : "Archive",
    GENERAL_FILTER                  : "Filter",
    GENERAL_FROM                    : "From",
    GENERAL_FROM_DATE               : "From date",
    GENERAL_FROM_HOUR               : "From time",
    GENERAL_HASH                    : "Hash",
    GENERAL_ID                      : "ID",
    GENERAL_IMPORT                  : "Import",
    GENERAL_INFO                    : "Information",
    GENERAL_IVA_CATEGORY            : "IVA Category",
    GENERAL_IVA_CATEGORIES          : { 1 : "Responsable Monotributo", 2 : "Responsable Inscripto", 3 : "Consumidor Final", 4 : "Exento" },
    GENERAL_LANGUAGE                : "Language",
    GENERAL_LEVEL                   : "Level",
    GENERAL_LOADING                 : "Loading",
    GENERAL_LOGIN_AS                : "Login as user",
    GENERAL_LOGIN_TIME              : "Last login",
    GENERAL_LOGOUT                  : "Logout",
    GENERAL_LOGGED_OUT              : "The session ended",
    GENERAL_NAME                    : "Name",
    GENERAL_NO                      : "No",
    GENERAL_NOBODY                  : "Nobody",
    GENERAL_OBSERVATIONS            : "Observations",
    GENERAL_PAGE_OF                 : "{0}-{1} of {2}",
    GENERAL_PAYMENT                 : "Payment",
    GENERAL_POSITION                : "Position",
    GENERAL_PRICE                   : "Price",
    GENERAL_ROW                     : "Row",
    GENERAL_ROWS_PER_PAGE           : "Rows per page: ",
    GENERAL_SAVE                    : "Save",
    GENERAL_SEARCH                  : "Search",
    GENERAL_SEARCH_ONE              : "Search ...",
    GENERAL_SEARCH_RESULTS          : "Results",
    GENERAL_SELECT                  : "Select",
    GENERAL_SELECT_FILE             : "Select file ...",
    GENERAL_SHORT_NAME              : "Short name",
    GENERAL_START                   : "Start",
    GENERAL_STATUS                  : "Status",
    GENERAL_SUBJECT                 : "Subject",
    GENERAL_TIME                    : "Time",
    GENERAL_TO                      : "Until",
    GENERAL_TO_DATE                 : "Until date",
    GENERAL_TO_HOUR                 : "Until time",
    GENERAL_TYPE                    : "Type",
    GENERAL_VIEW                    : "View",
    GENERAL_YES                     : "Yes",
    
};
