import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";

// Sections
import ManagerContainer     from "Components/Core/Manager/ManagerContainer";
import ProfileContainer     from "Components/App/Profile/ProfileContainer";
import DashboardPage        from "Components/App/Client/Dashboard/DashboardPage";

// Components
import ManagerRoute         from "Components/Utils/Route/ManagerRoute";
import SwitchRoute          from "dashboard/dist/Components/SwitchRoute";



/**
 * The Manager Router
 */
class ManagerRouter extends React.Component {
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        if (!this.props.isManager) {
            return <React.Fragment />;
        }
        return <SwitchRoute>
            <ManagerRoute url="PROFILE"   component={ProfileContainer} />
            <ManagerRoute url="DASHBOARD" component={DashboardPage}    />
            <ManagerRoute url="NONE"      component={ManagerContainer} />
        </SwitchRoute>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        isManager : PropTypes.bool.isRequired,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            isManager : state.auth.isManager,
        };
    }
}

export default connect(ManagerRouter.mapStateToProps)(ManagerRouter);
