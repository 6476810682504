import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    CLIENT_LOADING,
    CLIENT_LIST, CLIENT_ELEM, CLIENT_EDIT,
} from "Utils/Types";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
    elem      : {},
    withCUIT  : [],
    provinces : [],
    plans     : [],
    sort      : {
        orderBy  : "createdTime",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



/**
 * Parses the List
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseList(list) {
    return Utils.parseList(list, parseElem);
}

/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate      = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime  = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.ivaCategoryName  = NLS.get("GENERAL_IVA_CATEGORIES", elem.ivaCategory);
    elem.contact          = Utils.concat(" ", elem.firstName, elem.lastName);
    elem.addressText      = Utils.concat(" - ", elem.address, elem.locality, elem.provinceName, elem.zipCode);
    elem.promoEnd         = DateTime.formatIf(elem.promoEndTime, "dashes");
    elem.promoEndDate     = DateTime.formatIf(elem.promoEndTime, "dashesReverse");
    elem.serviceStart     = DateTime.formatDate(elem.serviceStartTime, "dashes");
    elem.serviceStartDate = DateTime.formatDate(elem.serviceStartTime, "dashesReverse");
    elem.nextPayment      = DateTime.formatDate(elem.nextPaymentTime, "dashes");
    elem.nextPaymentDate  = DateTime.formatDate(elem.nextPaymentTime, "dashesReverse");
    elem.planPriceText    = `$ ${elem.planPriceFormat}`;
    elem.languageName     = NLS.get("LANGUAGES", elem.language);
    elem.statusName       = Status.getName(elem.status);
    elem.statusColor      = Status.getColor(elem.status);
    elem.statusClass      = Status.getTextClass(elem.status);
    
    elem.planText = elem.planName;
    if (elem.promoPlanID) {
        elem.planText = `${elem.promoPlanName} (${elem.promoEnd})`;
    }
    
    const nextPayment = DateTime.create(elem.nextPaymentTime);
    elem.paymentClass = "";
    // 10 days ago
    if (nextPayment.isPastDay(240)) {
        elem.paymentClass = "text-red";
    // 3 days ago
    } else if (nextPayment.isPastDay(72)) {
        elem.paymentClass = "text-yellow";
    // 3 days after
    } else if (nextPayment.isFutureDay(72)) {
        elem.paymentClass = "text-green";
    }
    return elem;
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, CLIENT_LIST, CLIENT_ELEM, CLIENT_EDIT)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case CLIENT_LOADING:
        return {
            ...state,
            loading   : true,
        };
    
    case CLIENT_LIST:
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : parseList(action.data.list),
            total     : action.data.total,
            sort      : action.data.sort,
        };
    
    case CLIENT_ELEM:
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            canEdit   : action.data.canEdit,
            elem      : parseElem(action.data.elem),
            withCUIT  : action.data.withCUIT,
            provinces : action.data.provinces,
            plans     : action.data.plans,
        };

    case CLIENT_EDIT:
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            withCUIT  : action.data.withCUIT,
            provinces : action.data.provinces,
            plans     : action.data.plans,
        };
    
    default:
        return state;
    }
};
