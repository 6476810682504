import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { TicketType }       from "Utils/API";

// Types
import {
    TICKET_TYPE, TICKET_TYPE_LOADING,
    TICKET_TYPE_LIST, TICKET_TYPE_ELEM, TICKET_TYPE_EDIT,
} from "Utils/Types";



/**
 * Fetches the Ticket Type List
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTicketTypes(params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : TICKET_TYPE_LOADING });
        }
        Params.unset(params);
        const data = await TicketType.getAll(params);
        data.sort = params;
        dispatch({ type : TICKET_TYPE_LIST, data });
        dispatch(setSection(TICKET_TYPE));
    };
}

/**
 * Fetches a single Ticket Type
 * @param {Number} ticketTypeID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTicketType(ticketTypeID) {
    return async (dispatch) => {
        const data = await TicketType.getOne({ ticketTypeID });
        dispatch({ type : TICKET_TYPE_ELEM, data });
    };
}

/**
 * Fetches the Ticket Type Edit data
 * @returns {(dispatch: Function) => Void}
 */
export function fetchTicketTypeEdit() {
    return async (dispatch) => {
        const data = await TicketType.getEditData();
        dispatch({ type : TICKET_TYPE_EDIT, data });
    };
}



/**
 * Edits a Ticket Type
 * @param {Object} data
 * @returns {() => Void}
 */
export function editTicketType(data) {
    return () => TicketType.edit(data);
}

/**
 * Deletes a Ticket Type
 * @param {Number} ticketTypeID
 * @returns {() => Void}
 */
export function deleteTicketType(ticketTypeID) {
    return () => TicketType.delete({ ticketTypeID });
}
