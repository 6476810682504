import NLS                  from "dashboard/dist/Core/NLS";
import Status               from "dashboard/dist/Core/Status";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";

// Types
import {
    DASHBOARD_LOADING, DASHBOARD_ELEM, DASHBOARD_UPDATE,
} from "Utils/Types";



// The initial State
const initialState = {
    loading    : false,
    error      : false,
    edition    : 0,
    client     : {},
    hasTables  : false,
    hasWaiter  : false,
    hasOrders  : false,
    hasPayment : false,
    options    : {},
    tables     : [],
    orders     : [],
    actions    : [],
    servings   : [],
    lastAction : 0,
    lastTime   : 0,
};

// The State Colors
const STATE_COLORS = [ "gray", "green", "yellow", "red", "blue" ];

// The Action Types
const SERVING_CREATE   = 1;
const SERVING_COMPLETE = 2;

const WAITER_REQUEST_1 = 11;
const WAITER_REQUEST_2 = 12;
const WAITER_REQUEST_3 = 13;
const WAITER_ACCEPT    = 14;
const WAITER_COMPLETE  = 15;
const WAITER_CANCEL    = 16;

const BILL_REQUEST_1   = 21;
const BILL_REQUEST_2   = 22;
const BILL_REQUEST_3   = 23;
const BILL_ACCEPT      = 24;
const BILL_COMPLETE    = 25;
const BILL_CANCEL      = 26;

// The Serving States
const STATE_NONE      = 0;
const STATE_REQUEST_1 = 1;
const STATE_REQUEST_2 = 2;
const STATE_REQUEST_3 = 3;
const STATE_ACCEPTED  = 4;



/**
 * Parses the Servings
 * @param {Object[]} list
 * @returns {Array}
 */
function parseServings(list) {
    return Utils.parseList(list, (elem) => {
        elem.isCompleted = elem.completedTime > 0;
        elem.paymentName = elem.billPayment ? NLS.get(`DASHBOARD_PAYMENT_${elem.billPayment.toUpperCase()}`) : "";

        elem.title = elem.tableName || NLS.get("DASHBOARD_NO_TABLE");
        if (elem.isCompleted) {
            elem.title += NLS.get("DASHBOARD_COMPELTED");
        }
    });
}

/**
 * Parses the Tables
 * @param {Object[]} tables
 * @param {Object[]} servings
 * @returns {Array}
 */
function parseTables(tables, servings) {
    if (tables && servings) {
        for (const table of tables) {
            table.isActive    = false;
            table.servingID   = 0;
            table.waiterState = STATE_NONE;
            table.billState   = STATE_NONE;

            for (const serving of servings) {
                if (table.tableID === serving.tableID && serving.completedTime === 0) {
                    table.isActive    = true;
                    table.servingID   = serving.servingID;
                    table.waiterState = serving.waiterState;
                    table.billState   = serving.billState;
                    break;
                }
            }
            parseTable(table);
        }
    }
    return tables;
}

/**
 * Parses a single Table
 * @param {Object} table
 * @returns {Void}
 */
function parseTable(table) {
    table.waiterColor = STATE_COLORS[table.waiterState];
    table.billColor   = STATE_COLORS[table.billState];
    table.tableState  = 0;
    table.actionState = 0;
    table.actionName  = "";
    table.actionType  = "";

    if (table.isActive) {
        if (table.billState > STATE_NONE) {
            table.actionState = table.billState;
            table.actionName  = NLS.get("DASHBOARD_BILL");
        } else if (table.waiterState > STATE_NONE) {
            table.actionState = table.waiterState;
            table.actionName  = NLS.get("DASHBOARD_WAITER");
        }
        table.tableState = table.actionState + 1;
    
        let hasWaiter = table.waiterState > STATE_NONE && table.waiterState < STATE_ACCEPTED;
        let hasBill   = table.billState > STATE_NONE   && table.billState < STATE_ACCEPTED;
        if (hasWaiter && hasBill) {
            table.actionType = "ALL";
        } else if (hasWaiter) {
            table.actionType = "WAITER";
        } else if (hasBill) {
            table.actionType = "BILL";
        }
    }
}

/**
 * Parses the Actions
 * @param {Object[]} list
 * @returns {Array}
 */
function parseActions(list, lastAction) {
    let actionID  = lastAction;
    const actions = Utils.parseList(list, (elem) => {
        elem.time       = DateTime.formatDate(elem.createdTime, "time");
        elem.actionName = elem.tableID ? elem.tableName : NLS.get("DASHBOARD_NO_TABLE");
        elem.actionDesc = NLS.get(`DASHBOARD_ACTION_${elem.action}`);
        if (elem.actionID > actionID) {
            actionID = elem.actionID;
        }
    });
    return [ actions, actionID ];
}

/**
 * Parses the Orders
 * @param {Object[]} list
 * @returns {Object[]}
 */
function parseOrders(list) {
    return Utils.parseList(list, (elem) => {
        const descString = elem.tableID ? "ORDER_DESC_1" : "ORDER_DESC_2";
        elem.time        = DateTime.formatDate(elem.confirmedTime, "time");
        elem.statusName  = Status.getName(elem.status);
        elem.statusClass = Status.getTextClass(elem.status);
        elem.orderName   = NLS.format("ORDER_NAME", elem.number);
        elem.orderDesc   = NLS.format(descString, elem.tableName, elem.statusName);
        elem.isActive    = !elem.isCompleted && !elem.isCancelled;
        elem.total       = 0;

        for (const item of elem.items) {
            elem.total    += item.price;
            item.optionals = [];
            for (const optional of elem.optionals) {
                if (item.orderItemID === optional.orderItemID) {
                    let found = false;
                    for (const otherOptional of item.optionals) {
                        if (otherOptional.optionalID === optional.optionalID) {
                            otherOptional.items.push(optional.itemName);
                            otherOptional.options.push({
                                id    : optional.optionalItemID,
                                name  : optional.itemName,
                                price : optional.price,
                            });
                            found = true;
                        }
                    }
                    if (!found) {
                        item.optionals.push({
                            optionalID : optional.optionalID,
                            name       : optional.optionalShortName,
                            items      : [ optional.itemName ],
                            options    : [{
                                id    : optional.optionalItemID,
                                name  : optional.itemName,
                                price : optional.price,
                            }],
                        });
                    }
                }
            }
        }
        elem.total = Utils.formatNumber(elem.total, 2);
    });
}



/**
 * Parses the New Servings
 * @param {Object[]} oldServings
 * @param {Object[]} newServings
 * @returns {Void}
 */
function parseNewServings(oldServings, newServings) {
    for (const newServing of newServings.reverse()) {
        const index = oldServings.findIndex((elem) => elem.servingID === newServing.servingID);

        if (index === -1) {
            oldServings.unshift(newServing);
        } else {
            oldServings[index] = newServing;
        }
    }
}

/**
 * Parses the New Actions
 * @param {Object[]} tables
 * @param {Object[]} actions
 * @returns {Void}
 */
function parseNewActions(tables, actions) {
    if (!tables.length || !actions.length) {
        return;
    }
    for (const action of actions.reverse()) {
        const table = tables.find((elem) => elem.tableID === action.tableID);
        if (!table) {
            continue;
        }

        switch (action.action) {
        case SERVING_CREATE:
            table.isActive    = true;
            table.servingID   = action.servingID;
            table.waiterState = STATE_NONE;
            table.billState   = STATE_NONE;
            break;
        case SERVING_COMPLETE:
            table.isActive    = false;
            table.servingID   = 0;
            table.waiterState = STATE_NONE;
            table.billState   = STATE_NONE;
            break;

        case WAITER_REQUEST_1:
            table.waiterState = STATE_REQUEST_1;
            break;
        case WAITER_REQUEST_2:
            table.waiterState = STATE_REQUEST_2;
            break;
        case WAITER_REQUEST_3:
            table.waiterState = STATE_REQUEST_3;
            break;
        case WAITER_ACCEPT:
            table.waiterState = STATE_ACCEPTED;
            break;
        case WAITER_COMPLETE:
        case WAITER_CANCEL:
            table.waiterState = STATE_NONE;
            break;

        case BILL_REQUEST_1:
            table.billState = STATE_REQUEST_1;
            break;
        case BILL_REQUEST_2:
            table.billState = STATE_REQUEST_2;
            break;
        case BILL_REQUEST_3:
            table.billState = STATE_REQUEST_3;
            break;
        case BILL_ACCEPT:
            table.billState = STATE_ACCEPTED;
            break;
        case BILL_COMPLETE:
        case BILL_CANCEL:
            table.billState = STATE_NONE;
            break;
        default:
        }

        parseTable(table);
    }
}

/**
 * Parses the New Orders
 * @param {Object[]} oldOrders
 * @param {Object[]} newOrders
 * @returns {Void}
 */
function parseNewOrders(oldOrders, newOrders) {
    for (const newOrder of newOrders.reverse()) {
        const index = oldOrders.findIndex((elem) => elem.orderID === newOrder.orderID);

        if (index === -1) {
            oldOrders.unshift(newOrder);
        } else {
            oldOrders[index] = newOrder;
        }
    }
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
export default (state = initialState, action = {}) => {
    if (Utils.hasError(action, DASHBOARD_ELEM)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case DASHBOARD_LOADING:
        return {
            ...state,
            loading : true,
        };

    case DASHBOARD_ELEM: {
        const [ actions, lastAction ] = parseActions(action.data.actions, state.lastAction);
        const servings = parseServings(action.data.servings);
        return {
            ...state, actions, lastAction,
            loading    : false,
            error      : false,
            edition    : state.edition + 1,
            lastTime   : action.data.time,
            client     : action.data.client,
            hasTables  : action.data.hasTables,
            hasWaiter  : action.data.hasWaiter,
            hasOrders  : action.data.hasOrders,
            hasPayment : action.data.hasPayment,
            options    : action.data.options,
            servings   : servings,
            orders     : parseOrders(action.data.orders),
            tables     : parseTables(action.data.tables, servings),
        };
    }

    case DASHBOARD_UPDATE: {
        const newState = {};
        if (action.data.servings.length) {
            newState.servings = Utils.clone(state.servings);
            const servings = parseServings(action.data.servings);
            parseNewServings(newState.servings, servings);
        }
        if (action.data.actions.length) {
            const [ newActions, lastAction ] = parseActions(action.data.actions, state.lastAction);
            newState.tables     = Utils.clone(state.tables);
            newState.actions    = [ ...newActions, ...state.actions ];
            newState.lastAction = lastAction;
            parseNewActions(newState.tables, action.data.actions);
        }
        if (action.data.orders.length) {
            newState.orders = Utils.clone(state.orders);
            const newOrders = parseOrders(action.data.orders);
            parseNewOrders(newState.orders, newOrders);
        }
        return {
            ...state, ...newState,
            lastTime : action.data.time,
        };
    }

    default:
        return state;
    }
};
