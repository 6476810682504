import React                    from "react";
import PropTypes                from "prop-types";
import { connect }              from "react-redux";
import Utils                    from "dashboard/dist/Utils/Utils";

// Components
import EditDialog               from "dashboard/dist/Components/EditDialog";
import Avatar                   from "dashboard/dist/Components/Avatar";
import Icon                     from "dashboard/dist/Components/Icon";

// Actions
import {
    fetchTicketAssign, assignTicket,
} from "Actions/App/Ticket/TicketActions";



/**
 * The Ticket Assign Dialog
 */
class TicketAssign extends React.Component {
    // The Initial Data
    initialData = {
        ticketID : 0,
        adminID  : 0,
    }

    // The Current State
    state = {
        data    : { ...this.initialData },
        loading : false,
        errors  : {},
    }



    /**
     * Get the Data when the Element ID changes
     * @param {Object} prevProps
     * @returns {Void}
     */
    componentDidUpdate(prevProps) {
        const { open, edition, elemID, elem, fetchTicketAssign } = this.props;
        let loading = false;
        let data    = null;

        // Dialog Opens
        if (open && !prevProps.open) {
            data = { ...this.initialData };
            // Load new data
            if (elemID) {
                fetchTicketAssign(elemID);
                loading = true;
            }
        
        // Data Updated
        } else if (prevProps.edition !== edition) {
            data = Utils.extend(this.initialData, elem);
        }

        // Set the State
        if (data) {
            this.setState({ data, loading, errors : {} });
        }
    }

    /**
     * Handles the User Change
     * @param {Number} adminID
     * @returns {Void}
     */
    handleUser = (adminID) => {
        this.setState({
            data : { ...this.state.data, adminID },
        });
    }

    /**
     * Handles the Submit
     * @returns {Promise}
     */
    handleSubmit = async () => {
        const { data, loading } = this.state;
        if (!loading) {
            this.setState({ loading : true, errors : {} });
            try {
                await this.props.assignTicket(data);
                this.setState({ loading : false });
                this.props.onSubmit();
            } catch (errors) {
                this.setState({ loading : false, errors });
            }
        }
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { open, onClose, admins } = this.props;
        const { data, loading         } = this.state;

        return <EditDialog
            open={open}
            icon="assign"
            title="TICKETS_ASSIGN_TITLE"
            onSubmit={this.handleSubmit}
            onClose={onClose}
            isLoading={loading}
            withSpacing={false}
            isNarrow
        >
            <ul className="ticket-assign no-list">
                {admins.map((elem) => <li
                    key={elem.credentialID}
                    className={data.adminID === elem.credentialID ? "ticket-selected" : ""}
                    onClick={() => this.handleUser(elem.credentialID)}
                >
                    <Avatar
                        className="ticket-avatar"
                        name={elem.credentialName}
                        data={elem}
                    />
                    <div className="ticket-credential">
                        <h3>{elem.credentialName}</h3>
                        <p>{elem.email}</p>
                    </div>
                    {data.adminID === elem.credentialID && <Icon
                        className="ticket-icon"
                        icon="check"
                    />}
                </li>)}
            </ul>
        </EditDialog>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchTicketAssign : PropTypes.func.isRequired,
        assignTicket      : PropTypes.func.isRequired,
        open              : PropTypes.bool.isRequired,
        onClose           : PropTypes.func.isRequired,
        onSubmit          : PropTypes.func.isRequired,
        edition           : PropTypes.number.isRequired,
        admins            : PropTypes.array.isRequired,
        elem              : PropTypes.object.isRequired,
        elemID            : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            edition : state.ticket.edition,
            admins  : state.ticket.admins,
            elem    : state.ticket.elem,
        };
    }
}

export default connect(TicketAssign.mapStateToProps, {
    fetchTicketAssign, assignTicket,
})(TicketAssign);
