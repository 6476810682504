import Params               from "dashboard/dist/Core/Params";
import { setSection }       from "Actions/Core/CoreActions";
import { Subcategory }      from "Utils/API";

// Types
import {
    SUBCATEGORY, SUBCATEGORY_LOADING,
    SUBCATEGORY_LIST, SUBCATEGORY_ELEM, SUBCATEGORY_EDIT,
} from "Utils/Types";



/**
 * Fetches the Subcategory List
 * @param {String}   type
 * @param {Number}   elemID
 * @param {Object=}  params
 * @param {Boolean=} withLoader
 * @returns {(dispatch: Function) => Void}
 */
export function fetchSubcategories(type, elemID, params = {}, withLoader = true) {
    return async (dispatch) => {
        if (withLoader) {
            dispatch({ type : SUBCATEGORY_LOADING });
        }
        Params.unset(params);
        let data = {};

        switch (type) {
        case "CLIENT":
            params.clientID = elemID;
            data = await Subcategory.getAllForClient(params);
            break;
        default:
        }
        data.sort = params;
        dispatch({ type : SUBCATEGORY_LIST, data });
        dispatch(setSection(SUBCATEGORY));
    };
}

/**
 * Fetches a single Subcategory
 * @param {Number} subcategoryID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchSubcategory(subcategoryID) {
    return async (dispatch) => {
        const data = await Subcategory.getOne({ subcategoryID });
        dispatch({ type : SUBCATEGORY_ELEM, data });
    };
}

/**
 * Fetches the Subcategory Edit data
 * @param {Number} clientID
 * @returns {(dispatch: Function) => Void}
 */
export function fetchSubcategoryEdit(clientID) {
    return async (dispatch) => {
        const data = await Subcategory.getEditData({ clientID });
        dispatch({ type : SUBCATEGORY_EDIT, data });
    };
}



/**
 * Edits/Creates a Subcategory
 * @param {Object} data
 * @returns {() => Void}
 */
export function editSubcategory(data) {
    return () => Subcategory.edit(data);
}

/**
 * Deletes a Subcategory
 * @param {Number} subcategoryID
 * @returns {() => Void}
 */
export function deleteSubcategory(subcategoryID) {
    return () => Subcategory.delete({ subcategoryID });
}
