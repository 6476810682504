import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import NLS                  from "dashboard/dist/Core/NLS";
import Action               from "dashboard/dist/Core/Action";
import Utils                from "dashboard/dist/Utils/Utils";

// Components
import ViewDialog           from "dashboard/dist/Components/ViewDialog";
import ConfirmDialog        from "dashboard/dist/Components/ConfirmDialog";
import Button               from "dashboard/dist/Components/Button";
import InputField           from "dashboard/dist/Components/InputField";

// Actions
import {
    confirmOrder, rejectOrder, completeOrder, cancelOrder,
} from "Actions/App/Client/DashboardActions";

// Styles
import "Styles/Components/App/Orders.css";



/**
 * The Order View Dialog
 */
class OrderView extends React.Component {
    // The Current State
    state = {
        action      : Action.get(),
        orderID     : 0,
        orderItemID : 0,
        data        : {},
    }



    /**
     * Starts an Action
     * @param {Object} action
     * @returns {Void}
     */
    startAction = (action) => {
        this.setState({ action });
    }

    /**
     * Ends an Action
     * @param {Boolean} update
     * @returns {Void}
     */
    endAction = (update) => {
        this.startAction(Action.get());
        if (update) {
            this.props.onUpdate();
        }
    }

    /**
     * Selects an Order
     * @param {Number} orderID
     * @returns {Void}
     */
    selectOrder = (orderID) => {
        this.setState({ orderID, orderItemID : 0 });
    }

    /**
     * Selects an Item
     * @param {Object} orderItemID
     * @returns {Void}
     */
    selectItem = (orderItemID) => {
        this.setState({ orderItemID });
    }



    /**
     * Handles the Input Change
     * @param {String} name
     * @param {*}      value
     * @returns {Void}
     */
    handleChange = (name, value) => {
        this.setState({
            data   : { ...this.state.data,   [name] : value },
            errors : { ...this.state.errors, [name] : ""    },
        });
    }

    /**
     * Handles the Confirm Submit
     * @returns {Promise}
     */
    confirmElem = async () => {
        await this.props.confirmOrder(this.state.orderID);
        this.endAction(true);
    }

    /**
     * Handles the Reject Submit
     * @returns {Promise}
     */
    rejectElem = async () => {
        const { orderID, data } = this.state;
        const rejections = JSON.stringify(data);
        await this.props.rejectOrder(orderID, rejections);
        this.endAction(true);
    }

    /**
     * Handles the Complete Submit
     * @returns {Promise}
     */
    completeElem = async () => {
        await this.props.completeOrder(this.state.orderID);
        this.endAction(true);
    }

    /**
     * Handles the Cancel Submit
     * @returns {Promise}
     */
    cancelElem = async () => {
        await this.props.cancelOrder(this.state.orderID);
        this.endAction(true);
    }

    /**
     * Closes the Dialog
     * @returns {Promise}
     */
    closeDialog = async () => {
        await this.selectOrder(0);
        this.props.onClose();
    }



    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { servingID, open, servings, orders  } = this.props;
        const { action, orderID, orderItemID, data } = this.state;
        
        const serving     = servingID ? Utils.getValue(servings, "servingID", servingID) : {};
        const currOrders  = servingID ? orders.filter((elem) => elem.servingID === servingID) : [];
        const title       = servingID ? serving.title : "GENERAL_LOADING";
        const hasPayment  = Boolean(serving.paymentName);
        const hasOrders   = Boolean(currOrders.length);
        const hasOrder    = Boolean(orderID !== 0);
        const order       = hasOrder ? orders.find((elem) => elem.orderID === orderID) : {};
        const orderName   = hasOrder ? order.orderName : "";
        const items       = hasOrder ? order.items     : [];
        const canConfirm  = hasOrder && order.isVerifying;
        const canReject   = hasOrder && order.isVerifying;
        const canComplete = hasOrder && order.isConfirmed;
        const canCancel   = hasOrder && order.isConfirmed;
        const canAction   = canConfirm || canReject || canComplete || canCancel;
        const hasItem     = orderItemID !== 0;
        const item        = hasItem ? items.find((elem) => elem.orderItemID === orderItemID) : {};
        
        return <>
            <ViewDialog
                open={open}
                icon="table"
                title={title}
                className="orders-content"
                onClose={this.closeDialog}
                isWide
            >
                <section className="orders-border">
                    {hasPayment && <section className="orders-payment">
                        <h3>{NLS.get("ORDERS_PAYMENT_TITLE")}</h3>
                        <p>{serving.paymentName}</p>
                    </section>}
                    {hasOrders && <>
                        <header className="orders-title">
                            <h3>{NLS.get("ORDERS_LIST_TITLE")}</h3>
                        </header>
                        <ul className="orders-list no-list">
                            {currOrders.map((order) => <li
                                key={order.orderID}
                                className={order.orderID === orderID ? "orders-selected" : ""}
                                onClick={() => this.selectOrder(order.orderID)}
                            >
                                <header className="order-title">
                                    <h4>{order.orderName}</h4>
                                    <b className={order.statusClass}>{order.statusName}</b>
                                    <b>${order.total}</b>
                                </header>
                                <ul className="order-items no-list">
                                    {order.items.map((item) => <li key={item.orderItemID}>
                                        {item.quantity} x {item.productName}
                                    </li>)}
                                </ul>
                            </li>)}
                        </ul>
                    </>}
                </section>

                {hasOrder && <section className="orders-border">
                    <header className="orders-title">
                        <h3>{order.orderName}</h3>
                        <b className={order.statusClass}>{order.statusName}</b>
                        <b>${order.total}</b>
                    </header>
                    <ul className="orders-list no-list">
                        {items.map((item) => <li
                            key={item.orderItemID}
                            className={item.orderItemID === orderItemID ? "orders-selected" : ""}
                            onClick={() => this.selectItem(item.orderItemID)}
                        >
                            <header className="items-title">
                                <h4>{item.quantity} x {item.productName}</h4>
                                <b>${item.priceFormat}</b>
                            </header>
                            {!!item.productDescription && <div className="items-desc">
                                {item.productDescription}
                            </div>}
                            {!!item.sizeID && <div className="items-option">
                                <b>{NLS.get("ORDERS_SIZE")}</b> {item.sizeName}
                            </div>}
                            {item.optionals.map((optional) => <div key={optional.optionalID} className="items-option">
                                <b>{optional.name}:</b> {optional.items.join(", ")}
                            </div>)}
                        </li>)}
                    </ul>
                    {canAction && <footer className="orders-actions">
                        {canConfirm && <Button
                            icon="check"
                            variant="primary"
                            message="ORDERS_CONFIRM_TITLE"
                            onClick={() => this.startAction(Action.get("CONFIRM"))}
                        />}
                        {canReject && <Button
                            icon="close"
                            variant="primary"
                            message="ORDERS_REJECT_TITLE"
                            onClick={() => this.startAction(Action.get("REJECT"))}
                        />}
                        {canComplete && <Button
                            icon="check"
                            variant="primary"
                            message="ORDERS_COMPLETE_TITLE"
                            onClick={() => this.startAction(Action.get("COMPLETE"))}
                        />}
                        {canCancel && <Button
                            icon="close"
                            variant="primary"
                            message="ORDERS_CANCEL_TITLE"
                            onClick={() => this.startAction(Action.get("CANCEL"))}
                        />}
                    </footer>}
                </section>}

                {hasItem && <section>
                    <header className="orders-title">
                        <h3>{item.productName}</h3>
                        <b>${item.priceFormat}</b>
                    </header>
                    <ul className="orders-list no-list">
                        {!!item.productDescription && <li className="items-desc">
                            {item.productDescription}
                        </li>}
                        {!!item.sizeID && <li className="items-option">
                            <b>{NLS.get("ORDERS_SIZE")}</b>
                            <ul className="item-optionals">
                                <li>
                                    {item.sizeName}
                                    {item.sizePrice > 0 ? ` ($${item.sizePrice})` : ""}
                                </li>
                            </ul>
                        </li>}
                        {item.optionals.map((optional) => <li key={optional.optionalID} className="items-option">
                            <b>{optional.name}:</b>
                            <ul className="item-optionals">
                                {optional.options.map((option) => <li key={option.id}>
                                    {option.name}
                                    {option.price > 0 ? ` (+$${option.price})` : ""}
                                </li>)}
                            </ul>
                        </li>)}
                        {!!item.comments && <li className="items-comments">
                            <b>{NLS.get("ORDERS_COMMENTS")}</b><br />
                            <p>{item.comments}</p>
                        </li>}
                        {order.isVerifying && <li className="items-reject">
                            <b>{NLS.get("ORDERS_REJECT_ITEM")}</b>
                            <InputField
                                name={String(item.orderItemID)}
                                label="ORDERS_REJECT_MESSAGE"
                                value={data[item.orderItemID]}
                                onChange={this.handleChange}
                            />
                        </li>}
                    </ul>
                </section>}
            </ViewDialog>

            <ConfirmDialog
                open={action.isConfirm}
                icon="check"
                title="ORDERS_CONFIRM_TITLE"
                message="ORDERS_CONFIRM_TEXT"
                content={orderName}
                onSubmit={this.confirmElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isReject}
                icon="close"
                title="ORDERS_REJECT_TITLE"
                message="ORDERS_REJECT_TEXT"
                content={orderName}
                onSubmit={this.rejectElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isComplete}
                icon="check"
                title="ORDERS_COMPLETE_TITLE"
                message="ORDERS_COMPLETE_TEXT"
                content={orderName}
                onSubmit={this.completeElem}
                onClose={this.endAction}
            />
            <ConfirmDialog
                open={action.isCancel}
                icon="close"
                title="ORDERS_CANCEL_TITLE"
                message="ORDERS_CANCEL_TEXT"
                content={orderName}
                onSubmit={this.cancelElem}
                onClose={this.endAction}
            />
        </>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        confirmOrder  : PropTypes.func.isRequired,
        rejectOrder   : PropTypes.func.isRequired,
        completeOrder : PropTypes.func.isRequired,
        servings      : PropTypes.array.isRequired,
        orders        : PropTypes.array.isRequired,
        open          : PropTypes.bool.isRequired,
        onClose       : PropTypes.func.isRequired,
        onUpdate      : PropTypes.func.isRequired,
        servingID     : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            servings : state.dashboard.servings,
            orders   : state.dashboard.orders,
        };
    }
}

export default connect(OrderView.mapStateToProps, {
    confirmOrder, rejectOrder, completeOrder, cancelOrder,
})(OrderView);
