// The Status IDs
const ACTIVE   = 1;
const INACTIVE = 2;
const OPEN     = 31;
const CLOSED   = 32;

const CREATING  = 41;
const VERIFYING = 42;
const REJECTED  = 43;
const CONFIRMED = 44;
const COMPLETED = 45;
const CANCELLED = 46;



// The App Status
export default {
    "values" : [
        // General Status
        {
            "id"      : ACTIVE,
            "name"    : "ACTIVE",
            "message" : "STATUS_ACTIVE",
            "color"   : "green",
        },
        {
            "id"      : INACTIVE,
            "name"    : "INACTIVE",
            "message" : "STATUS_INACTIVE",
            "color"   : "red",
        },
        
        // Ticket Status
        {
            "id"      : OPEN,
            "name"    : "OPEN",
            "message" : "STATUS_OPEN",
            "color"   : "green",
        },
        {
            "id"      : CLOSED,
            "name"    : "CLOSED",
            "message" : "STATUS_CLOSED",
            "color"   : "red",
        },

        // Order Status
        {
            "id"      : CREATING,
            "name"    : "CREATING",
            "message" : "STATUS_CREATING",
            "color"   : "yellow",
        },
        {
            "id"      : VERIFYING,
            "name"    : "VERIFYING",
            "message" : "STATUS_VERIFYING",
            "color"   : "yellow",
        },
        {
            "id"      : REJECTED,
            "name"    : "REJECTED",
            "message" : "STATUS_REJECTED",
            "color"   : "red",
        },
        {
            "id"      : CONFIRMED,
            "name"    : "CONFIRMED",
            "message" : "STATUS_CONFIRMED",
            "color"   : "green",
        },
        {
            "id"      : COMPLETED,
            "name"    : "COMPLETED",
            "message" : "STATUS_COMPLETED",
            "color"   : "green",
        },
        {
            "id"      : CANCELLED,
            "name"    : "CANCELLED",
            "message" : "STATUS_CANCELLED",
            "color"   : "red",
        },
    ],

    // The Status per Type
    "groups" : {
        "GENERAL" : [ ACTIVE, INACTIVE ],
        "TICKET"  : [ OPEN, CLOSED ],
        "ORDER"   : [ CREATING, VERIFYING, REJECTED, CONFIRMED, COMPLETED, CANCELLED ],
    },

    // The Status IDs
    ACTIVE,
    INACTIVE,
    OPEN,
    CLOSED,
    CREATING,
    VERIFYING,
    REJECTED,
    CONFIRMED,
    COMPLETED,
    CANCELLED,
};
