import { setSection }       from "Actions/Core/CoreActions";
import { ActionLog }        from "Utils/API";

// Types
import {
    ACTION_LOG, ACTION_LOG_LOADING, ACTION_LOG_LIST,
} from "Utils/Types";



/**
 * Fetches the Action Log
 * @param {String=} type
 * @param {Number=} elemID
 * @param {Object=} filter
 * @param {Object=} sort
 * @returns {(dispatch: Function) => Void}
 */
export function fetchActionLog(type = "all", elemID = 0, filter = {}, sort = {}) {
    return async (dispatch) => {
        try {
            dispatch({ type : ACTION_LOG_LOADING });
            const params = { ...filter, ...sort };
            let   data   = {};

            switch (type) {
            case "CLIENT":
                params.clientID = elemID;
                data = await ActionLog.filterForClient(params);
                break;
            
            case "MANAGER":
                data = await ActionLog.filterForManager(params);
                break;
            case "PROFILE":
                data = await ActionLog.filterForUser(params);
                break;
            default:
                data = await ActionLog.filter(params);
                break;
            }
            data.filter = filter;
            data.sort   = sort;
            dispatch({ type : ACTION_LOG_LIST, data });
            dispatch(setSection(ACTION_LOG));
        
        } catch (errors) {
            throw errors;
        }
    };
}
