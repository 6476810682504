import React                from "react";
import PropTypes            from "prop-types";
import { connect }          from "react-redux";
import { fetchActionLog }   from "Actions/App/Setup/ActionLogActions";
import { Profile }          from "Utils/API";
import NLS                  from "dashboard/dist/Core/NLS";

// Components
import Main                 from "dashboard/dist/Components/Main";
import Content              from "dashboard/dist/Components/Content";
import Header               from "dashboard/dist/Components/Header";
import Filter               from "dashboard/dist/Components/Filter";
import Table                from "dashboard/dist/Components/Table";
import TableHead            from "dashboard/dist/Components/TableHead";
import TableBody            from "dashboard/dist/Components/TableBody";
import TableRow             from "dashboard/dist/Components/TableRow";
import TableHeader          from "dashboard/dist/Components/TableHeader";
import TableCell            from "dashboard/dist/Components/TableCell";
import TablePaging          from "dashboard/dist/Components/TablePaging";



/**
 * The Action Log List
 */
class ActionLogList extends React.Component {
    /**
     * Load the Data
     * @returns {Void}
     */
    componentDidMount() {
        this.fetch();
    }

    /**
     * Fetch the content
     * @param {Object=} params
     * @returns {Void}
     */
    fetch = (params) => {
        const { type, elemID, data } = this.props;
        this.props.fetchActionLog(type, elemID, data.filter, params || data.sort);
    }

    /**
     * Fetch the content
     * @param {Object} params
     * @returns {Promise}
     */
    filter = async (params) => {
        try {
            const { type, elemID, data } = this.props;
            await this.props.fetchActionLog(type, elemID, params, data.sort);
        } catch (errors) {
            throw errors;
        }
    }
    


    /**
     * Renders the List
     * @param {Object[]} list
     * @returns {Object[]}
     */
    renderContent(list) {
        const result = [];
        let   key    = 0;
        
        for (const elem of list) {
            result.push(<TableRow key={key}>
                <TableCell colSpan="4">
                    <b className="right-space">{NLS.get("ACTIONS_LOG_SESSION")}</b>
                    <i className="right-space">{elem.credentialName}</i>
                    <span className="right-space">{elem.timeDateTime}</span>
                    <span title={elem.userAgent}>{elem.ip}</span>
                </TableCell>
            </TableRow>);
            key++;
            
            for (const action of elem.actions) {
                result.push(<TableRow key={key}>
                    <TableCell message={action.actionName}  />
                    <TableCell message={action.sectionName} />
                    <TableCell message={action.dataText}    />
                    <TableCell message={action.timeDate}    />
                </TableRow>);
                key++;
            }

            result.push(<TableRow key={key}>
                <TableCell colSpan="4">{"\u00A0"}</TableCell>
            </TableRow>);
            key++;
        }
        
        return result;
    }
    
    /**
     * Does the Render
     * @returns {Object}
     */
    render() {
        const { data, type, params, route, withDetails } = this.props;
        const { list, total, sort, loading             } = data;
        
        return <Main withDetails={withDetails}>
            <Header message="ACTIONS_LOG_LIST" icon="actions" route={route} />
            <Content>
                <Filter
                    onFilter={this.filter}
                    hasCredential={type !== "PROFILE"}
                    fetch={Profile.search}
                    params={{ clientID : params.clientID }}
                />
                <Table
                    fetch={this.fetch}
                    sort={sort}
                    none="ACTIONS_LOG_NONE_AVAILABLE"
                    isLoading={loading}
                    hasFilter
                >
                    <TableHead>
                        <TableHeader field="action"  message="ACTIONS_LOG_ACTION"  />
                        <TableHeader field="section" message="ACTIONS_LOG_SECTION" />
                        <TableHeader field="dataID"  message="GENERAL_ID"          />
                        <TableHeader field="time"    message="GENERAL_TIME"        />
                    </TableHead>
                    <TableBody>
                        {this.renderContent(list)}
                    </TableBody>
                    <TablePaging total={total} />
                </Table>
            </Content>
        </Main>;
    }



    /**
     * The Property Types
     * @typedef {Object} propTypes
     */
    static propTypes = {
        fetchActionLog : PropTypes.func.isRequired,
        data           : PropTypes.object.isRequired,
        type           : PropTypes.string.isRequired,
        params         : PropTypes.object.isRequired,
        route          : PropTypes.string.isRequired,
        withDetails    : PropTypes.bool,
        elemID         : PropTypes.number,
    }

    /**
     * Maps the State to the Props
     * @param {Object} state
     * @returns {Object}
     */
    static mapStateToProps(state) {
        return {
            data : state.actionLog,
        };
    }
}

export default connect(ActionLogList.mapStateToProps, {
    fetchActionLog,
})(ActionLogList);
